import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import $ from "jquery";

import FeedbackSuccessModal from "../FeedbackSuccessModal";

import "./style.css";

class RatingsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      description: null,
      error: null,
      FeedbackSuccessModalOpen: false,
    };
  }

  submitRating = () => {
    console.log("I am trying to submit");
    const vm = this;
    if (this.state.rating !== null && this.state.description !== null) {
      let data = {
        score: this.state.rating,
        description: this.state.description,
      };
      console.log(data);
      $.ajax({
        method: "POST",
        dataType: "json",
        traditional: true,
        data: JSON.stringify(data),
        xhrFields: {
          withCredentials: false,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        url: `${process.env.REACT_APP_API}/rentals/${this.props.id}/rate`,
      })
        .then((res) => {
          console.log("res");
          vm.props.toggle("ratings");
          vm.setState({ FeedbackSuccessModalOpen: true });
        })
        .catch((res) => {
          console.log(res);
        });
    } else {
      vm.setState({ error: true });
    }
  };

  handleRate({ rating }) {
    const defaultStyles = [
      { x: 0 },
      { x: 0 },
      { x: 0 },
      { x: 0 },
      { x: 0 },
    ].map((style, i) => {
      if (rating < i + 1) {
        return { x: 1 };
      }
      return style;
    });
    this.setState({
      rating,
      defaultStyles,
    });
  }

  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("ratings")}>
        &times;
      </button>
    );

    return (
      <div>
        <FeedbackSuccessModal
          toggle={this.toggle}
          modalOpen={
            this.state.FeedbackSuccessModalOpen
          }></FeedbackSuccessModal>
        <Modal
          className="about-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("ratings")}
          size="lg">
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B", paddingLeft: 60 }}>
            Rate Equipment
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col style={{ textAlign: "center", fontSize: "6em" }} sm={12}>
                  <Rater
                    total={5}
                    // value={this.state.rating}
                    onRate={this.handleRate.bind(this)}
                    rating={this.state.rating}>
                    {/* {interpolatingStyles.map((style, i) => (
                      <Star
                        style={{ transform: `scale(${style.x})` }}
                        key={i}
                      />
                    ))} */}
                  </Rater>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    type="text"
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                  />
                </Col>
                {this.state.error ? (
                  <span
                    style={{
                      width: "100%",
                      textAlign: "center",
                      display: "block",
                      color: "red",
                      margin: 20,
                    }}>
                    {" "}
                    Please fill out the rating and description{" "}
                  </span>
                ) : null}
                <Button
                  className="loginbutton"
                  size="lg"
                  block
                  onClick={this.submitRating}>
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
RatingsModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string,
};
export default RatingsModal;
