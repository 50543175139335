import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
// import Renting from '../Renting'
import EditProfileModal from "../../Common/Modals/EditProfileModal";
import { getloggedinUser } from "../../App/Api/loggedinuser.js";
import { getUser } from "../../App/Api/singleuser.js";
import $ from "jquery";
import Post from "../../Common/Post";
import Switch from "react-switch";
import "./style.css";
class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      EditProfileModalOpen: false,
      userId: 1,

      profileImage: null,
      userName: null,
      userCity: null,
      authUserId: 1,
      authUserpfImage: null,
      autheruserName: null,
      authuserLocation: null,
      items: [],
      allowsCancelling: false,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      profileImage: null,
      cityId: 0,
      socialMediaUser: false,
      cityName: null,
      sponsorID: null,
      companyName: null,
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
  }
  componentWillMount() {
    this.setState({ userId: this.props.match.params.id });
    if (localStorage.getItem("socialMediaUser")) {
      this.setState({ socialMediaUser: true });
    }
  }
  componentWillReceiveProps(nextProps) {
    //console.log("HERE")
    // if(nextProps.locaton.state === "MyProfile"){
    this.setState({ userId: this.props.match.params.id });
    // }
  }
  //TODO: get a list of all items and set state to them
  componentDidMount() {
    //   getUser(this.state.userId).then(function(res){
    //     console.log("***** otheruser-profpage *****")
    //     console.log(res)
    //   this.setState({userId: res.id});
    //   this.setState({profileImage: res.profileImage});
    //   const fullusername = res.firstName + " " + res.lastName;
    //   this.setState({userName: fullusername});
    //     this.setState({ userCity: res.city});

    //   // //console.log(vm.props.userId);
    //   // //console.log(vm.props.authUserId);
    // });

    const vm = this;

    getloggedinUser().then(function (res) {
      const fullusername = res.firstName + " " + res.lastName;
      vm.setState({
        authUserId: res.id,
        authorName: res.id,
        autheruserName: fullusername,
        authuserLocation: res.city,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        phone: res.phone,
        authUserpfImage: res.profileImage,
        allowsCancelling: res.allowsCancelling,
        sponsorID: res.sponsorID,
        companyName: res.companyName,
      });
      if (res.city) {
        vm.setState({ cityName: res.city.name, cityId: res.city.id });
      }

      //console.log(vm.props.userId);
      //console.log(vm.props.authUserId);
    });
    // const AuthUserId = parseInt(localStorage.getItem('userId'))
    // this.setState({ authUserId: AuthUserId})
    let data = {};

    data.EquipmentType = 0;
    data.perPage = 1000;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: data,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/equipments/user",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        res.data.items.forEach((item) => {
          let obj = {};
          // obj.name = item.name + " " + item.make + " " + item.model;
          obj.name = item.name;
          obj.user = item.user.firstName + " " + item.user.lastName;
          obj.rating = item.score;
          obj.userId = item.user.id;
          obj.id = item.id;
          obj.isActive = item.isActive;
          obj.imageUrl = item.equipmentMedias[0].filePath;
          vm.setState({
            items: vm.state.items.concat(obj),
          });
        });
      })
      .catch(function (res) {
        //console.log('the addon response ')
        //console.log(res)
      });
  }
  toggle(type) {
    if (type == "editprofilemodal") {
      this.setState((prevState) => ({
        EditProfileModalOpen: !prevState.EditProfileModalOpen,
      }));
    }
  }

  handleChange3(allowsCancelling) {
    const vm = this;
    vm.setState(
      {
        allowsCancelling: !this.state.allowsCancelling,
      },
      () => {
        let data = {
          allowsCancelling: vm.state.allowsCancelling,
          firstName: vm.state.firstName,
          lastName: vm.state.lastName,
          email: vm.state.email,
          phone: vm.state.phone,
          cityId: vm.state.cityId,
        };

        $.ajax({
          method: "PUT",
          dataType: "json",
          processData: false,
          traditional: true,
          contentType: "application/json",
          data: JSON.stringify(data),
          xhrFields: {
            withCredentials: true,
          },
          url: process.env.REACT_APP_API + "/users/me",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            Accept: "application/json",
          },
        }).then(function (res) {
          //console.log("was it all good")
          //console.log(res)
        });
      }
    );
  }

  //
  render() {
    return (
      <React.Fragment>
        <EditProfileModal
          toggle={this.toggle}
          modalOpen={this.state.EditProfileModalOpen}
        />
        <div className="other-user-prof-container">
          <div className="container post-info-container main-one">
            <div className="onee" style={{ marginTop: 25 }}>
              <div className="left-side">
                <img
                  className="left-side-pf"
                  src={this.state.authUserpfImage}
                />
              </div>
              <div className="right-side">
                <div className="right-side-one">
                  <h4
                    className="post-title"
                    style={{ textTransform: "capitalize", marginBottom: 0 }}>
                    {this.state.autheruserName}{" "}
                    <span className="user-id" style={{ color: "#A0A0A0" }}>
                      {" "}
                    </span>
                  </h4>
                  {this.state.sponsorID ? (
                    <p
                      className="post-title-username"
                      style={{ marginBottom: "0px" }}>
                      My Sponsor Code: {this.state.sponsorID}{" "}
                    </p>
                  ) : null}
                  {this.state.companyName ? (
                    <p
                      className="post-title-username"
                      style={{ marginBottom: "0px" }}>
                      Company: {this.state.companyName}{" "}
                    </p>
                  ) : null}
                  <p className="post-title-username">{this.state.cityName} </p>
                </div>
              </div>
            </div>
            <div className="twoo" style={{ width: "49%" }}>
              {this.state.socialMediaUser ? (
                <Button
                  disabled
                  onClick={() => this.toggle("editprofilemodal")}
                  size="lg"
                  className="message-user-btn">
                  Profile cannot be edited
                </Button>
              ) : (
                <Button
                  onClick={() => this.toggle("editprofilemodal")}
                  size="lg"
                  className="message-user-btn">
                  Edit Profile
                </Button>
              )}
            </div>
            <div className="cancel-stuff">
              <h5 style={{ paddingRight: "50px" }}>Allow Cancellations</h5>
              <Switch
                onChange={this.handleChange3}
                checked={this.state.allowsCancelling}
                offColor="#E6E6E6"
                onColor="#D0E7B2"
                onHandleColor="#8AC53F"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
              />{" "}
            </div>
          </div>
          <div
            className="myownstuff-container"
            style={{ padding: 10, marginTop: 60 }}>
            {this.state.items.length > 0 ? (
              this.state.items.map((item, index) => {
                return (
                  <div
                    className="posts-in-dash"
                    style={{ marginTop: "20px" }}
                    key={index}>
                    <Post
                      postName={item.name}
                      postUser={item.user}
                      postRating={item.rating}
                      postId={item.id}
                      postUserId={item.userId}
                      postImageUrl={item.imageUrl}
                      postIsActive={item.isActive}
                    />
                  </div>
                );
              })
            ) : (
              <div
                className="no-results-div"
                style={{ textAlign: "center", paddingTop: "150px" }}>
                <span className="no-results-span">
                  You do not have any Equipments or Add ons.{" "}
                </span>{" "}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
Profile.propTypes = {
  userId: PropTypes.number,
  autheruserName: PropTypes.string,
};
export default Profile;
