import React from "react";
import {
  Navbar,
  Button,
  Dropdown,
  DropdownMenu,
  Row,
  Col,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";
import PropTypes from "prop-types";
import { NavLink, Link, withRouter, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "./DatePicker";
import moment from "moment";
import * as qs from "query-string";

import LoginModal from "../Modals/Login";
import SignUpModal from "../Modals/Signup";
import ResetPassModal from "../Modals/ResetPassword";
import TosModal from "../Modals/TosModal";
import PhoneVerificationNumberModal from "../Modals/PhoneVerificationNumberModal";
import PhoneVerificationCodeModal from "../Modals/PhoneVerificationCodeModal";
import NewPostModal from "../Modals/NewPostModal";

import SuccessAccountModal from "../../Common/Modals/SuccessAccountModal";
import SuccessEmailReset from "../Modals/ResetPassword/SuccessEmailReset";

import { getCities, getProvinces } from "../../App/Api/location.js";
import { getFilters } from "../../App/Api/filter.js";
import { getSorts } from "../../App/Api/sort.js";

// import MenuLocationsPopup from './MenuLocationsPopup'
import "./header.css";
import $ from "jquery";
import * as signalR from "@aspnet/signalr";
import ChatWindow from "../Chats/components/ChatWindow";
import incomingMessageSound from "../Chats/assets/sounds/notification.mp3";
import "../Chats/styles";
import FormattedDateTime from "../Chats/FormattedDateTime";
import { BallBeat } from "react-pure-loaders";
import NotificationPopup from "./NotificationPopup";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
      locations: [],
      lat: null,
      long: null,
      cSelected: [],

      filters: [],
      sorts: [],
      startDate: null,
      endDate: null,
      searchKeyWord: "",

      displayMessagePopup: false,
      displayNotificationPopup: false,
      displayMenuOptionsPopup: false,
      displayMenuLocationsPopup: false,
      displayMenuFiltersPopup: false,
      displayMenuSortsPopup: false,
      displayMenuDatePickerPopup: false,
      LoginModalOpen: false,
      SignUpModalOpen: false,
      TosModalOpen: false,
      PhoneVerificationNumberModalOpen: false,
      PhoneVerificationCodeModalOpen: false,
      NewPostModalOpen: false,
      SuccessAccountModalOpen: false,
      SuccessEmailResetOpen: false,
      CheckoutFormOpen: false,
      loading: true,
      fullname: null,
      lastName: null,
      chatHistory: [],
      activeChatRooms: [],
      chatUser: "",
      accessToken: localStorage.getItem("accessToken"),
      lastMessage: "",
      activeChatFilter: "",
      userProfile: {
        userName: "Manish",
        imageUrl:
          "https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png",
      },
      registerData: {},
      isOpen: false,
      chatSelect: null,
      count: 0,
      notifications: [],
      selectedChatId: 0,
      readColor: "#8AC53F",
      //unReadMessageCount: 0,
      unReadMessage: null,
      redirect: false,
    };

    this.showPopup = this.showPopup.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onCheckboxBtnClick = this.onCheckboxBtnClick.bind(this);
    this.setRegisterData = this.setRegisterData.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    this.setRegisterData = this.setRegisterData.bind(this);
    this._onMessageWasSent = this._onMessageWasSent.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    let parse;
    if (window.location?.search) {
      parse = qs.parse(window.location?.search);
      console.log(parse);
      this.setState({
        resetToken: parse.token,
        userName: parse.email,
      });
    } else {
      this.setState({ redirect: true });
    }
    if (parse) {
      sessionStorage.setItem("rental_id", parse?.rental_id);
    }

    const vm = this;
    //TODO: get all notifications related to user, and all messages related to user
    // let city;
    // if (localStorage.getItem("searchCity")) {
    //   city = localStorage.getItem("searchCity");
    //   this.showPosition(null, city);
    // } else {
    //   vm.getLocation();
    //   city = this.props.userLocation;
    // }

    let province;
    if (localStorage.getItem("searchProvince")) {
      province = localStorage.getItem("searchProvince");
      this.showProvince(null, province);
    } else {
      vm.getLocation();
      // province = this.props.userLocation;
    }

    vm.setState({
      // location: city,
      filter: this.props.filterOptions,
      sort: this.props.sortOptions,
    });

    // getCities().then(function (res) {
    //   vm.setState({ locations: res.items });
    // });
    getProvinces().then(function (res) {
      vm.setState({ locations: res.items });
    });
    getFilters().then(function (res) {
      // console.log("**** Filters ****");
      // console.log("**** Filters ****", res);
      vm.setState({ filters: res });
    });
    getSorts().then(function (res) {
      // console.log("**** Sort ****");
      // console.log("**** Sort ****", res);
      vm.setState({ sorts: res });
    });

    if (this.props.authenticated) {
      window.onload = function start() {
        vm.getUnreadNotificationCount();
        // vm.getActiveChatRooms();
        // setInterval(vm.getActiveChatRooms, 5000);
        // setInterval(vm.getUnreadNotificationCount, 5000);
      };

      if (this.props.authenticated) {
        vm.getUnreadNotificationCount();

        vm.getActiveRooms();
      }
    }
  }

  //get Location

  getLocation = () => {
    if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition(this.showPosition);
      navigator.geolocation.getCurrentPosition(this.showProvince);
    } else {
      alert("Geolocation is not supported by this browser.");
      this.setState({
        location: "Alberta",
      });
      localStorage.setItem("searchProvince", 1);
    }
  };

  showPosition = (position = null, id = null) => {
    const vm = this;
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (localStorage.getItem("accessToken")) {
      header.Authorization = "Bearer " + localStorage.getItem("accessToken");
    }
    if (position) {
      let latitude = position.coords.latitude;
      let longitude = position.coords.longitude;

      $.ajax({
        method: "GET",
        dataType: "json",
        traditional: true,
        data: {},
        xhrFields: {
          withCredentials: true,
        },
        url: `${process.env.REACT_APP_API}/cities?Latitude=${latitude}&Longitude=${longitude}`,

        headers: header,
      })
        .then((response) => {
          vm.setState({
            location: response.data.items[0].name,
          });
          localStorage.setItem("searchCity", response.data.items[0].id);
        })
        .catch(function (res) {
          console.log(res);
          vm.setState({
            loading: false,
          });
        });
    } else {
      $.ajax({
        method: "GET",
        dataType: "json",
        traditional: true,
        data: {},
        xhrFields: {
          withCredentials: true,
        },
        url: `${process.env.REACT_APP_API}/cities/${id}`,

        headers: header,
      })
        .then((response) => {
          vm.setState({
            location: response.data.name,
          });
          localStorage.setItem("searchCity", response.data.id);
        })
        .catch(function (res) {
          console.log(res);
          vm.setState({
            loading: false,
          });
        });
    }
  };

  showProvince = (position = null) => {
    const vm = this;
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (position) {
      let latitude = position.coords.latitude;
      let longitude = position.coords.longitude;

      $.ajax({
        method: "GET",
        dataType: "json",
        traditional: true,
        data: {},
        xhrFields: {
          withCredentials: true,
        },
        url: `${process.env.REACT_APP_API}/provinces?Latitude=${latitude}&Longitude=${longitude}`,

        headers: header,
      })
        .then((response) => {
          vm.setState({
            location: response.data.items[0].name,
          });
          localStorage.setItem("searchProvince", response.data.items[0].id);
        })
        .catch(function (res) {
          console.log(res);
          vm.setState({
            loading: false,
          });
        });
    } else {
      localStorage.setItem("searchProvince", 1);
      vm.setState({
        location: "Alberta",
      });
    }
  };

  // get chat history

  getChatHistory(index) {
    const vm = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.nubosh.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke("SubscribeChatRoom", this.state.activeChatRooms[index].roomId)
        .then((response) => {
          const chatMessages = response.data.latestMessages
            .reverse()
            .map((e) => {
              let from = "";
              if (e.author.userId === localStorage.getItem("userId")) {
                from = "me";
              } else {
                from = "sender";
              }
              return {
                from: from,
                message: e.body,
                created: e.createdAt,
              };
            });
          vm.setState({
            chatHistory: chatMessages,
            selectedChatId: vm.state.activeChatRooms[index].roomId,
          });
        });
      connection.on("ReceiveMessageText", (res) => {
        let text = {
          from: "sender",
          message: res.body,
          created: res.createdAt,
        };

        connection
          .invoke("SubscribeChatRoom", this.state.activeChatRooms[index].roomId)
          .then((response) => {
            const chatMessages = response.data.latestMessages
              .reverse()
              .map((e) => {
                let from = "";
                if (e.author.userId === localStorage.getItem("userId")) {
                  from = "me";
                } else {
                  from = "sender";
                }
                return {
                  from: from,
                  message: e.body,
                  created: e.createdAt,
                };
              });
            vm.setState({
              chatHistory: chatMessages,
              selectedChatId: vm.state.activeChatRooms[index].roomId,
            });
          });
      });

      connection
        .invoke("MarkRoomRead", this.state.activeChatRooms[index].roomId)
        .then((response) => {})
        .then(function (res) {
          console.log(res);
          vm.getActiveRooms();
        });
    });
  }

  getActiveRooms = () => {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/chat/rooms/active",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        vm.setState({ loading: false, activeChatRooms: response.data.items });

        var notificationDot = vm.state.activeChatRooms;

        notificationDot.map((chats) => {
          if (chats.hasRead === false) {
            vm.setState({
              unReadMessage: true,
            });
          }
        });
      })
      .catch(function (res) {
        console.log(res);
        vm.setState({
          loading: false,
        });
      });
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.nubosh.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start();
    connection.on("UpdateRoomListEntry", function (updateRoom) {
      var updatelastMessage = vm.state.activeChatRooms;

      updatelastMessage.map((chats) => {
        if (chats.roomId === updateRoom.roomId) {
          chats.lastMessage = updateRoom.lastMessage;
          if (chats.lastMessage !== null) {
            if (
              chats.lastMessage.author.userId !== localStorage.getItem("userId")
            ) {
              chats.hasRead = false;
            }
          }
        }
      });

      vm.setState({
        activeChatRooms: updatelastMessage,
      });

      if (updateRoom.hasUnreadMessages) {
        vm.setState({
          //unReadMessageCount: vm.state.unReadMessageCount + updateRoom.unreadMessageCountDelta,
          unReadMessage: true,
          lastMessage: updateRoom.lastMessage,
        });
      }
    });
  };

  redirectToDashboard = () => {
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
  };
  deleteChatRoom = (index) => {
    const vm = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.nubosh.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke("DeleteRoom", this.state.activeChatRooms[index].roomId)
        .then((response) => {
          vm.setState({ chatSelect: null });
          vm.getActiveRooms();
        });
    });
  };

  showPopup(type) {
    if (type === "notification") {
      this.setState({
        displayNotificationPopup: !this.state.displayNotificationPopup,
      });
    } else if (type === "message") {
      this.setState({ displayMessagePopup: !this.state.displayMessagePopup });
    } else if (type === "menu") {
      this.setState({
        displayMenuOptionsPopup: !this.state.displayMenuOptionsPopup,
      });
    } else if (type === "locations") {
      this.setState({
        displayMenuLocationsPopup: !this.state.displayMenuLocationsPopup,
      });
    } else if (type === "filters") {
      this.setState({
        displayMenuFiltersPopup: !this.state.displayMenuFiltersPopup,
      });
    } else if (type === "sorts") {
      this.setState({
        displayMenuSortsPopup: !this.state.displayMenuSortsPopup,
      });
    } else if (type === "date") {
      this.setState({
        displayMenuDatePickerPopup: !this.state.displayMenuDatePickerPopup,
      });
    }
  }

  toggle(type) {
    if (type === "login") {
      this.setState((prevState) => ({
        LoginModalOpen: !prevState.LoginModalOpen,
      }));
    }
    if (type === "signin") {
      this.setState((prevState) => ({
        SignUpModalOpen: !prevState.SignUpModalOpen,
      }));
    }

    if (type === "resetpass") {
      this.setState((prevState) => ({
        ResetModalOpen: !prevState.ResetModalOpen,
      }));
    }

    if (type === "tosmodal") {
      this.setState((prevState) => ({
        TosModalOpen: !prevState.TosModalOpen,
      }));
    }

    if (type === "newpost") {
      this.setState((prevState) => ({
        NewPostModalOpen: !prevState.NewPostModalOpen,
      }));
    }

    if (type === "verificationPhoneModal") {
      this.setState((prevState) => ({
        PhoneVerificationNumberModalOpen:
          !prevState.PhoneVerificationNumberModalOpen,
      }));
    }

    if (type == "verificationCodeModal") {
      this.setState((prevState) => ({
        PhoneVerificationCodeModalOpen:
          !prevState.PhoneVerificationCodeModalOpen,
      }));
    }

    if (type === "success") {
      this.setState((prevState) => ({
        SuccessAccountModalOPen: !prevState.SuccessAccountModalOPen,
      }));
    }

    if (type === "CheckoutForm") {
      this.setState((prevState) => ({
        CheckoutFormOpen: !prevState.CheckoutFormOpen,
      }));
    }
    if (type === "SuccessEmailReset") {
      this.setState((prevState) => ({
        SuccessEmailResetOpen: !prevState.SuccessEmailResetOpen,
      }));
    }
  }

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
  }

  onCheckboxBtnClick(selected) {
    const index = this.state.cSelected.indexOf(selected);
    if (index < 0) {
      this.state.cSelected.push(selected);
    } else {
      this.state.cSelected.splice(index, 1);
    }
    this.setState({ cSelected: [...this.state.cSelected] });
  }
  setRegisterData(data) {
    this.setState({ registerData: data });
    // console.log(data);
  }

  logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    //this.props.history.push('/dashboard')
    window.location.replace("/");
  };

  addSOrttolocalstorage = () => {
    localStorage.setItem("sortId", this.state.rSelected);
    this.showPopup("sorts");
    this.setState({ redirect: true });
  };

  addFiltertolocalstorage = () => {
    localStorage.setItem("filterId", JSON.stringify(this.state.cSelected));
    this.showPopup("filters");
    this.setState({ redirect: true });
  };

  getDateValues = (start, end) => {
    this.setState(
      {
        startDate: moment.utc(start._d).format(),
        endDate: moment.utc(end._d).format(),
      },
      () => {
        localStorage.setItem("searchStartDate", this.state.startDate);
        localStorage.setItem("searchEndDate", this.state.endDate);
      }
    );
    this.showPopup("date");
    this.setState({ redirect: true });
  };

  setlocation = (item) => {
    // localStorage.setItem("searchCity", item.id);
    localStorage.setItem("searchProvince", item.id);
    this.showPopup("locations");
    this.setState({ redirect: true, location: item.name });
  };

  _onMessageWasSent = (message) => {
    this.setState({
      messageList: [...this.state.messageList, message],
    });
  };

  _sendMessage = (message) => {
    const vm = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.nubosh.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke("SendMessageText", this.state.selectedChatId, message.data.text)
        .catch(function (err) {
          return console.error(err.toString());
        })
        .then(function () {
          vm.getChatHistory(vm.state.chatSelect);
        });
    });
    if (message.data.text.length > 0) {
      let index = this.state.chatSelect;
    }
  };

  playIncomingMessageSound = () => {
    var audio = new Audio(incomingMessageSound);
    audio.play();
  };

  onResetBtnClick = (type) => {
    if (type === "filter") {
      this.setState({ cSelected: [], redirect: true });
      localStorage.setItem("filterId", 0);
    } else if (type === "sort") {
      this.setState({ rSelected: [], redirect: true });
      localStorage.removeItem("sortId");
    } else {
      localStorage.removeItem("sortId");
      localStorage.setItem("filterId", 0);
      localStorage.removeItem("searchStartDate");
      localStorage.removeItem("searchEndDate");
      this.setState({ redirect: true });
      this.setState(
        {
          cSelected: [],
          rSelected: [],
          endDate: null,
          startDate: null,
        },
        () => {
          this.setState({ redirect: true });
        }
      );
    }
  };
  handleClick(index) {
    let vm = this;
    var updatelastMessage = vm.state.activeChatRooms;
    updatelastMessage[index].hasRead = true;
    $.when(this.getChatHistory(index)).done(function () {
      if (vm.props.handleClick !== undefined) {
        vm.props.handleClick();
      } else {
        var updatelastMessage = vm.state.activeChatRooms;
        updatelastMessage[index].hasRead = true;
        vm.setState({
          activeChatRooms: updatelastMessage,
          isOpen: true,
          chatSelect: index,
          unReadMessage: false,
        });
      }
    });
  }

  closeClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  getUnreadNotificationCount = () => {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/notifications/getunread",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      vm.setState({
        count: res.data.totalItems,
      });
    });
  };

  onConfirm = (e, index) => {
    e.stopPropagation();

    confirmAlert({
      title: "Are you sure to delete this conversation?",
      //message: 'Are you sure to do this.',
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteChatRoom(index),
        },
        {
          label: "No",
          //onClick: () => window.location.reload()
        },
      ],
    });
  };

  //TODO: filter popup, Date pop up, location pop up (setstate: search params)

  //TODO: notifications popup, display all notifications relative to user, on click flag notification as read, and bring user to relative notification(if message pop up relative message, if contract changed bring to contract, etc.)

  //TODO: messages popup,Display all Messages, on click pop up realtive message
  render() {
    const isOpen = this.props.hasOwnProperty("isOpen")
      ? this.props.isOpen
      : this.state.isOpen;

    return (
      <div>
        {this.redirectToDashboard}
        <LoginModal
          loginData={this.state.loginData}
          setLoginData={this.setLoginData}
          toggle={this.toggle}
          modalOpen={this.state.LoginModalOpen}
        />
        <SignUpModal
          setRegisterData={this.setRegisterData}
          toggle={this.toggle}
          modalOpen={this.state.SignUpModalOpen}
        />
        <ResetPassModal
          toggle={this.toggle}
          modalOpen={this.state.ResetModalOpen}
        />
        <TosModal
          toggle={this.toggle}
          modalOpen={this.state.TosModalOpen}
          registerData={this.state.registerData}
          setRegisterData={this.setRegisterData}
        />
        <PhoneVerificationNumberModal
          registerData={this.state.registerData}
          setRegisterData={this.setRegisterData}
          toggle={this.toggle}
          modalOpen={this.state.PhoneVerificationNumberModalOpen}
        />
        <PhoneVerificationCodeModal
          registerData={this.state.registerData}
          setRegisterData={this.setRegisterData}
          toggle={this.toggle}
          modalOpen={this.state.PhoneVerificationCodeModalOpen}
        />
        <NewPostModal
          toggle={this.toggle}
          modalOpen={this.state.NewPostModalOpen}
        />
        <SuccessAccountModal
          toggle={this.toggle}
          modalOpen={this.state.SuccessAccountModalOPen}
        />
        <SuccessEmailReset
          toggle={this.toggle}
          modalOpen={this.state.SuccessEmailResetOpen}
        />

        <div className="">
          <Navbar className="navbar my-navbar-fixed p-0" fixed="top">
            {/* Upper Nav */}

            <div
              className="upper-nav d-flex flex-row justify-content-between align-items-center"
              style={{ padding: "1em 3em" }}
            >
              <div className="d-flex flex-row align-items-center">
                {/* LOGO */}
                <NavLink className="mr-4" to="/dashboard">
                  <img src={require("./Images/Logo.png")} alt="Nubosh" />
                </NavLink>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    document.getElementById("TestSubmit").click();
                  }}
                  className="banner-search-bar"
                >
                  <InputGroup className="form-search has-search">
                    <InputGroupAddon addonType="prepend">
                      <div className="form-control-feedback ">
                        <img
                          src={require("../../Common/./Header/Images/icon_search.svg")}
                          alt="search"
                        />
                      </div>
                    </InputGroupAddon>
                    <input
                      onChange={(e) =>
                        this.setState({ searchKeyWord: e.target.value })
                      }
                      style={{ border: "1px solid #efefef", boxShadow: "none" }}
                      type="text"
                      className="form-control mainsearchinput"
                      value={this.state.searchKeyWord}
                      placeholder="Search keyword"
                    />
                    <Link
                      id="TestSubmit"
                      className="search-link"
                      onClick={() => this.setState({ searchKeyWord: "" })}
                      to={{
                        pathname: "/dashboard",
                        state: {
                          searchWord: this.state.searchKeyWord,
                        },
                      }}
                    >
                      <button
                        style={{ height: "100%" }}
                        type="button"
                        className="main-searchbutton2"
                      >
                        Search
                      </button>
                    </Link>
                  </InputGroup>
                </form>
              </div>
              {/* Nav Icons/User */}
              {this.props.authenticated ? (
                // If Authenticated show nav icons for user
                <div
                  style={{ float: "right", borderLeft: "1px solid #D9D9D9" }}
                >
                  <NavLink className="navLink" to="/dashboard">
                    <svg
                      className="nav-icons"
                      xmlns="http://www.w3.org/2000/svg"
                      width="34.211"
                      height="32.288"
                      viewBox="0 0 34.211 32.288"
                    >
                      <g transform="translate(1 0)">
                        <rect
                          className="a"
                          width="32.288"
                          height="32.288"
                          transform="translate(0)"
                        />
                        <path
                          className="b"
                          d="M.565,12.2A1.5,1.5,0,0,0,0,13.337a1.534,1.534,0,0,0,1.582,1.482H4.745V25.192a1.424,1.424,0,0,0,.463,1.048,1.651,1.651,0,0,0,1.125.434h4.74a1.534,1.534,0,0,0,1.582-1.482V19.264h6.9v5.928a1.534,1.534,0,0,0,1.582,1.482h4.745a1.534,1.534,0,0,0,1.582-1.482V14.819h3.164a1.534,1.534,0,0,0,1.582-1.482,1.421,1.421,0,0,0-.606-1.138C26.174,7.913,15.817,0,15.817,0S6.033,7.913.565,12.2Z"
                          transform="translate(0 1.889)"
                        />
                      </g>
                    </svg>
                  </NavLink>
                  <NavLink className="navLink" to="/rentals">
                    <svg
                      className="nav-icons"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26.91"
                      height="27.153"
                      viewBox="0 0 26.91 27.153"
                    >
                      <path
                        className="b"
                        d="M6.618,25.168H1.168A1.175,1.175,0,0,1,0,23.988V20.152a1.178,1.178,0,0,1,.342-.835l7.874-7.953a8.688,8.688,0,0,1,3.405-9.911,8.49,8.49,0,0,1,10.363.709,8.7,8.7,0,0,1,2.052,10.284,8.534,8.534,0,0,1-9.282,4.707l-1.168,1.327a1.163,1.163,0,0,1-.873.4H10.9v1.964A1.176,1.176,0,0,1,9.734,22.02H7.787v1.966a1.176,1.176,0,0,1-1.169,1.182ZM18.688,3.932a2.36,2.36,0,1,0,2.335,2.359A2.348,2.348,0,0,0,18.688,3.932Z"
                        transform="translate(1 0.985)"
                      />
                    </svg>
                  </NavLink>
                  <div
                    className="navLink"
                    style={{ position: "relative", display: "contents" }}
                  >
                    <Dropdown
                      className="displayContents"
                      style={{ position: "relative" }}
                      isOpen={this.state.displayMessagePopup}
                      toggle={() => this.showPopup("message")}
                    >
                      <DropdownToggle
                        onClick={() => this.showPopup("message")}
                        data-toggle="dropdown"
                        aria-expanded={this.state.displayMessagePopup}
                      >
                        <img
                          src={require("./Images/icon_message.svg")}
                          alt="message"
                          onClick={() => this.getActiveRooms()}
                        />
                        {this.state.unReadMessage === true ? (
                          <span
                            style={{ width: "25px", height: "25px" }}
                            className="notification-circle"
                          >
                            !{/* {this.state.unReadMessageCount} */}
                          </span>
                        ) : null}
                      </DropdownToggle>
                      <DropdownMenu
                        style={{ width: "400px" }}
                        className="nav-dropdown "
                      >
                        <p
                          style={{ textAlign: "center" }}
                          className="dropdown-title"
                        >
                          Messages
                        </p>
                        <hr />
                        <div
                          style={{ textAlign: "center" }}
                          className="sweet-loading"
                        >
                          <BallBeat
                            color={"#8AC53F"}
                            loading={this.state.loading}
                          />
                        </div>
                        {this.state.activeChatRooms.map((message, i) => {
                          return (
                            <div key={i}>
                              {/* onClick={this.handleChatPopUp.bind(this)} */}
                              <Row
                                className="notification-row"
                                onClick={() => this.handleClick(i)}
                                //onClick={() => this.showPopup("message")}
                              >
                                <Col sm="2">
                                  <div className="profile-img">
                                    <img
                                      className="img-circle"
                                      src={message.profileImagePath}
                                      alt="user-profile"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  style={{
                                    paddingLeft: "35px",
                                  }}
                                  sm="6"
                                >
                                  <Row
                                    style={
                                      message.hasRead
                                        ? { color: "grey" }
                                        : { color: "#8AC53F" }
                                    }
                                  >
                                    {message.userDisplayName}
                                  </Row>
                                  <Row
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverlow: "ellipsis",
                                    }}
                                  >
                                    {message.lastMessage
                                      ? message.lastMessage.body
                                      : "Thanks for booking!"}
                                  </Row>
                                </Col>
                                <Col className="row-flex" sm="4">
                                  <Row>
                                    <img
                                      style={{ zIndex: 10 }}
                                      onClick={(e) => this.onConfirm(e, i)}
                                      src={require("./Images/trash.svg")}
                                      alt="Trash Can"
                                    />
                                  </Row>
                                  <Row
                                    style={{
                                      color: "#AAAAAA",
                                      textAlign: "center",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <FormattedDateTime
                                      datetime={
                                        message.lastMessage
                                          ? message.lastMessage.createdAt
                                          : null
                                      }
                                      ago
                                    />
                                  </Row>
                                </Col>
                              </Row>
                              <hr />
                            </div>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div style={{ position: "relative", display: "contents" }}>
                    <Dropdown
                      className="displayContents"
                      style={{ position: "relative" }}
                      isOpen={this.state.displayNotificationPopup}
                      toggle={() => this.showPopup("notification")}
                    >
                      <DropdownToggle
                        onClick={() => this.showPopup("notification")}
                        data-toggle="dropdown"
                        aria-expanded={this.state.displayNotificationPopup}
                      >
                        <img
                          src={require("./Images/notification.svg")}
                          alt="notification"
                        />
                        <span
                          style={{ width: "25px", height: "25px" }}
                          className="notification-circle"
                        >
                          {this.state.count}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu
                        style={{ width: "400px" }}
                        className="nav-dropdown"
                        positionFixed={true}
                      >
                        <p
                          style={{ textAlign: "center" }}
                          className="dropdown-title"
                        >
                          Notifications
                        </p>

                        <hr />
                        <NotificationPopup
                          getUnreadNotification={() =>
                            this.getUnreadNotificationCount()
                          }
                        >
                          {this.state.count}
                        </NotificationPopup>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div
                    style={{
                      borderLeft: "1px solid rgb(217, 217, 217)",
                      display: "inline-block",
                    }}
                  >
                    <Dropdown
                      className="displayContents"
                      style={{ position: "relative" }}
                      isOpen={this.state.displayMenuOptionsPopup}
                      toggle={() => this.showPopup("menu")}
                    >
                      <DropdownToggle
                        className="user-profile-dropdown d-flex flex-row justify-content-between"
                        onClick={() => this.showPopup("menu")}
                        data-toggle="dropdown"
                        aria-expanded={this.state.displayMenuOptionsPopup}
                      >
                        <div>
                          <div className="profile-img pr-1">
                            <img
                              className="img-circle"
                              src={
                                localStorage.getItem("profileImage")
                                  ? localStorage.getItem("profileImage")
                                  : this.props.profileImage
                              }
                              alt="user_icon"
                            />
                          </div>
                        </div>
                        <span className="green-txt">
                          {this.props.userName}{" "}
                        </span>
                        <FontAwesomeIcon
                          className="green-txt pl-1"
                          icon={faChevronDown}
                        />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-container">
                        <NavLink
                          onClick={() => this.showPopup("menu")}
                          className="dropdown-option"
                          to={`/myprofile`}
                        >
                          Profile
                        </NavLink>
                        <hr className="hr-dropdown" />
                        <NavLink
                          className="dropdown-option"
                          onClick={() => this.showPopup("menu")}
                          to="/settings"
                        >
                          Settings
                        </NavLink>
                        <hr className="hr-dropdown" />
                        <NavLink
                          to={`/dashboard`}
                          style={{ cursor: "pointer" }}
                          className="dropdown-option"
                          onClick={(e) => this.logout(e)}
                        >
                          Logout
                        </NavLink>
                        {/* <a className="dropdown-option">
                          <GoogleLogout
                            clientId="478345164350-foia8o5v2beq4c3g4cpiaptiot7gqgni.apps.googleusercontent.com"
                            buttonText="Logout"
                            onLogoutSuccess={this.logout}
                          ></GoogleLogout>
                        </a> */}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              ) : (
                <div style={{ float: "right" }} className="guest-buttons">
                  <Button
                    size="lg"
                    onClick={() => this.toggle("login")}
                    style={{ background: "none" }}
                    className="green-txt btn-no-bg login-button"
                  >
                    Login
                  </Button>
                  <Button
                    onClick={() => this.toggle("signin")}
                    className="green-bg signup-button"
                    size="lg"
                  >
                    Sign Up
                  </Button>
                </div>
              )}
            </div>

            {window.location.pathname.includes("dashboard") ||
            window.location.pathname.includes("profile") ||
            window.location.pathname.includes("rentals") ? (
              <div className="" style={{ width: "100%" }}>
                <hr className="hr-nav" />

                <div
                  className="lowerNav d-flex flex-row justify-content-between align-items-center"
                  style={{ padding: "1em 3em" }}
                >
                  <div>
                    {/* <a ><img src={require('./Images/icon_filter.svg')} alt="filter"/></a> */}

                    <div style={{ display: "inline-block" }}>
                      <Dropdown
                        className="displayContents  mx-auto"
                        style={{ position: "relative" }}
                        isOpen={this.state.displayMenuSortsPopup}
                        toggle={() => this.showPopup("sorts")}
                      >
                        <DropdownToggle
                          className="user-profile-dropdown mx-auto"
                          onClick={() => this.showPopup("sorts")}
                          data-toggle="dropdown"
                          aria-expanded={this.state.displayMenuSortsPopup}
                        >
                          <a>
                            <img
                              src={require("./Images/icon_filter.svg")}
                              alt="filter"
                            />
                          </a>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-container location filter pt-0 px-0">
                          <DropdownItem
                            header
                            className="filter-dropd-header  text-align-center d-flex flex-row justify-content-between align-items-center py-0"
                          >
                            <div />
                            <div className="align-self-center ml-5">Sort</div>
                            <Button
                              className="btn-reset text-align-right"
                              onClick={() => this.onResetBtnClick("sort")}
                            >
                              Reset
                            </Button>
                          </DropdownItem>
                          <form>
                            <InputGroup className="form-search my-3">
                              <span style={{ color: "#C8C8C8" }}>
                                How do you want to sort the results
                              </span>
                            </InputGroup>
                          </form>
                          <div>
                            <Row
                              className="filter-checkbox-box d-flex justify-content-between"
                              sm="12"
                            >
                              <Col className="filter-col" sm="12">
                                <div className="d-flex flex-wrap">
                                  {this.state.sorts.map((item, index) => {
                                    return (
                                      <Row
                                        className="filter-row mr-0 ml-0 pr-4"
                                        sm="6"
                                      >
                                        <Col sm="10">{item.name}</Col>
                                        <Col sm="2">
                                          <Button
                                            color="primary"
                                            onClick={() =>
                                              this.onRadioBtnClick(item.id)
                                            }
                                            active={
                                              this.state.rSelected === item.id
                                            }
                                          >
                                            <FontAwesomeIcon
                                              className="text-white"
                                              icon={faCheck}
                                            />
                                          </Button>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </div>

                                <Row className="d-flex flex-row justify-content-end my-5 mx-0 pr-5">
                                  <Link
                                    to={{
                                      pathname: `/dashboard`,
                                      state: { sorts: this.state.rSelected },
                                    }}
                                  >
                                    <Button
                                      className="btn-apply"
                                      onClick={() =>
                                        this.addSOrttolocalstorage()
                                      }
                                    >
                                      Apply
                                    </Button>
                                  </Link>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </div>

                    <div style={{ display: "inline-block" }}>
                      <Dropdown
                        className="displayContents  btn-optin"
                        style={{ position: "relative" }}
                        isOpen={this.state.displayMenuFiltersPopup}
                        toggle={() => this.showPopup("filters")}
                      >
                        <DropdownToggle
                          className="user-profile-dropdown"
                          onClick={() => this.showPopup("filters")}
                          data-toggle="dropdown"
                          aria-expanded={this.state.displayMenuFiltersPopup}
                        >
                          <a className="green-txt">Filter</a>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-container location filter  pt-0 px-0">
                          <DropdownItem
                            header
                            className="filter-dropd-header  text-align-center d-flex flex-row justify-content-between align-items-center py-0"
                          >
                            <div />
                            <div className="align-self-center ml-5">Filter</div>
                            <Button
                              className="btn-reset text-align-right"
                              onClick={() => this.onResetBtnClick("filter")}
                            >
                              Reset
                            </Button>
                          </DropdownItem>
                          <form>
                            <InputGroup className="form-search my-3">
                              <span style={{ color: "#C8C8C8" }}>
                                {" "}
                                Select which categories you would like to search
                                in
                              </span>
                            </InputGroup>
                          </form>
                          <div>
                            <Row
                              className="filter-checkbox-box d-flex justify-content-between"
                              sm="12"
                            >
                              <Col className="filter-col pl-0 pr-4" sm="12">
                                {/* <form > */}
                                <div className="d-flex flex-wrap">
                                  {this.state.filters.map((item, index) => {
                                    return (
                                      <Row
                                        className="filter-row mr-0 ml-0 pr-4"
                                        sm="6"
                                      >
                                        {item.name}
                                        <Button
                                          color="primary"
                                          onClick={() =>
                                            this.onCheckboxBtnClick(item.id)
                                          }
                                          active={this.state.cSelected.includes(
                                            item.id
                                          )}
                                        >
                                          <FontAwesomeIcon
                                            className="text-white"
                                            icon={faCheck}
                                          />
                                        </Button>
                                      </Row>
                                    );
                                  })}
                                </div>
                                {/* </form> */}

                                <Row className="d-flex flex-row justify-content-end my-5 mx-0 pr-5">
                                  <Link
                                    to={{
                                      pathname: `/dashboard`,
                                      state: { filters: this.state.cSelected },
                                    }}
                                  >
                                    <Button
                                      onClick={() =>
                                        this.addFiltertolocalstorage()
                                      }
                                      className="btn-apply"
                                    >
                                      Apply
                                    </Button>
                                  </Link>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </div>

                    <div style={{ display: "inline-block" }}>
                      <Dropdown
                        className="displayContents button-nav date"
                        style={{ position: "relative" }}
                        isOpen={this.state.displayMenuDatePickerPopup}
                        toggle={() => this.showPopup("date")}
                      >
                        <DropdownToggle
                          className="user-profile-dropdown"
                          onClick={() => this.showPopup("date")}
                          data-toggle="dropdown"
                          aria-expanded={this.state.displayMenuDatePickerPopup}
                        >
                          <a className="green-txt">Date</a>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-container location date pt-0 px-0">
                          <DropdownItem
                            header
                            className="filter-dropd-header  text-align-center d-flex flex-row justify-content-center align-items-center py-0"
                          >
                            <div className="align-self-center">Date</div>
                          </DropdownItem>
                          <div>
                            <Row
                              className="filter-checkbox-box d-flex justify-content-between pt-3"
                              sm="12"
                            >
                              <Col sm="12" id="date-picker-container">
                                <DatePicker getValues={this.getDateValues} />
                              </Col>
                            </Row>
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </div>

                    <div style={{ display: "inline-block" }}>
                      <Dropdown
                        className="displayContents"
                        style={{ position: "relative" }}
                        isOpen={this.state.displayMenuLocationsPopup}
                        toggle={() => this.showPopup("locations")}
                      >
                        <DropdownToggle
                          className="user-profile-dropdown"
                          onClick={() => this.showPopup("locations")}
                          data-toggle="dropdown"
                          aria-expanded={this.state.displayMenuLocationsPopup}
                        >
                          <div>
                            <a>
                              <img
                                src={require("./Images/icon_location.svg")}
                                alt="location"
                              />{" "}
                              {this.state.location}
                            </a>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-container location  pt-0 px-0">
                          <DropdownItem header className="dropdown-header">
                            Location
                          </DropdownItem>
                          <form>
                            <InputGroup className="form-search my-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search city"
                                onKeyPress={this.onKeyPress}
                                value={this.state.activeChatFilter}
                                onChange={(e) =>
                                  this.setState({
                                    activeChatFilter: e.target.value,
                                  })
                                }
                              />
                            </InputGroup>
                          </form>
                          {this.state.locations
                            .filter(
                              (e) =>
                                this.state.activeChatFilter === "" ||
                                e.name
                                  .toLocaleLowerCase()
                                  .indexOf(this.state.activeChatFilter) !==
                                  -1 ||
                                e.name.indexOf(this.state.activeChatFilter) !==
                                  -1
                            )
                            .map((item, index) => {
                              return (
                                <NavLink
                                  to={`#`}
                                  className="dropdown-option"
                                  onClick={() => this.setlocation(item)}
                                >
                                  <div className="dropdown-opt-inner">
                                    {item.name}, {item.countryCode}
                                  </div>
                                </NavLink>
                              );
                            })}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <div style={{ display: "inline-block" }}>
                      {localStorage.getItem("sortId") ||
                      localStorage.getItem("filterId") ||
                      (localStorage.getItem("searchStartDate") &&
                        localStorage.getItem("searchEndDate")) ? (
                        <button
                          className="btn-reset-all text-align-right"
                          onClick={() => this.onResetBtnClick("all")}
                        >
                          Reset Filters
                        </button>
                      ) : null}
                    </div>
                  </div>

                  {this.props.authenticated ? (
                    <div style={{ display: "inline-block", float: "right" }}>
                      <Button
                        size="lg"
                        onClick={() => this.toggle("newpost")}
                        className="new-post-button"
                        style={{ marginTop: "-0.5em" }}
                      >
                        New Post
                      </Button>
                      {/* <Link to="/post/create"></Link> */}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </Navbar>
        </div>
        {this.state.chatSelect !== null && (
          <ChatWindow
            // messageList={this.state.chatHistory[this.state.chatSelect].chatHistory}
            messageList={this.state.chatHistory}
            onUserInputSubmit={this._sendMessage.bind(this)}
            userProfile={
              this.state.activeChatRooms[this.state.chatSelect].userDisplayName
            }
            isOpen={isOpen}
            onClose={this.closeClick.bind(this)}
            imageUrl={
              this.state.activeChatRooms[this.state.chatSelect].profileImagePath
            }
          />
        )}

        {/* <NotificationPopup></NotificationPopup> */}
        {this.chatHistory}
        {/* <Stripe></Stripe> */}
      </div>
    );
  }
}

Header.propTypes = {
  authenticated: PropTypes.bool,
  userName: PropTypes.string,
  userLocation: PropTypes.string,
  userId: PropTypes.number,
};

export default Header;
