import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  UncontrolledCarousel,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Video,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye,
  faFlag,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

import $ from "jquery";

import { faStar } from "@fortawesome/free-solid-svg-icons";

import ReportUserModal from "../../../Common/Modals/ReportUserModal";

import "./post-view.css";
import UserRating from "./UserRating";
import ViewPostTabs from "./ViewPostTabs";

class ViewPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderImages: [],

      // Carousel
      activeIndex: 0,
      items: 0,
      ReportUserModalOpen: false,

      postUserId: null,
      postId: null,
      postInfo: null,
      minStartDate: null,
      maxEndDate: null,
      isDeliveryAvailable: null,
      isPickupAvailable: null,
      reviewsCount: [],
      companyName: null,
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.goBack = this.goBack.bind(this);

    this.toggle = this.toggle.bind(this);
  }

  toggle(type) {
    if (type == "reportusermodal") {
      this.setState((prevState) => ({
        ReportUserModalOpen: !prevState.ReportUserModalOpen,
      }));
    }
  }

  Rating = () => {
    let stars = [];
    for (let i = 0; i < this.props.data.score; i++) {
      stars.push(<FontAwesomeIcon icon={faStar} className="checked fa-star" />);
    }
    return stars;
  };

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.sliderImages.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.sliderImages.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  goBack() {
    window.history.back();
  }

  componentWillMount() {
    this.setState({
      postId: this.props.postId,
      postUserId: this.props.postUserId,
    });
  }

  componentDidMount() {
    const vm = this;
    let myImages = this.props.data.equipmentMedias.map((element) => {
      return {
        src: element.filePath,
        type: element.type,
        // altText: 'Slide 1',
        // caption: 'Slide 1'
      };
    });

    vm.props.availability(this.props.data.isActive);
    vm.setState({
      sliderImages: myImages,
      postInfo: this.props.data,
      minStartDate: this.props.data.startDate,
      maxEndDate: this.props.data.endDate,
      isDeliveryAvailable: this.props.data.isDeliveryAvailable,
      isPickupAvailable: this.props.data.isPickupAvailable,
      reviewsCount: this.props.data.ratings,
    });
    // });
  }

  render() {
    const { activeIndex } = this.state;
    return (
      <Col>
        {localStorage.getItem("accessToken") && (
          <ReportUserModal
            equipmentId={this.state.postId}
            toggle={this.toggle}
            modalOpen={this.state.ReportUserModalOpen}
          />
        )}

        <Row>
          <Col sm="7" style={{ position: "relative" }}>
            <div onClick={this.goBack} className="backbutton">
              <FontAwesomeIcon
                icon={faArrowLeft}
                size="lg"
                className="goback-icon"
              />
            </div>
            {localStorage.getItem("accessToken") && (
              <div
                onClick={() => this.toggle("reportusermodal")}
                className="reportbutton"
              >
                <FontAwesomeIcon
                  icon={faFlag}
                  size="lg"
                  className="report-icon"
                />
              </div>
            )}
            <Carousel
              sm="12"
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
              autoPlay={false}
              interval={false}
              ride={1000}
            >
              <CarouselIndicators
                className="view-carousel-indicators mx-auto"
                items={this.state.sliderImages}
                activeIndex={activeIndex}
                onClickHandler={this.goToIndex}
              />
              {this.state.sliderImages.map((item) => {
                if (item.type === 2) {
                  return (
                    <CarouselItem
                      height="500px"
                      onExiting={this.onExiting}
                      onExited={this.onExited}
                      key={item.src}
                    >
                      <video
                        width="100%"
                        controls
                        src={item.src}
                        alt={item.altText}
                        type="video/mp4"
                      />
                    </CarouselItem>
                  );
                } else {
                  return (
                    <CarouselItem
                      onExiting={this.onExiting}
                      onExited={this.onExited}
                      key={item.src}
                    >
                      <img interval={false} src={item.src} alt={item.altText} />
                    </CarouselItem>
                  );
                }
              })}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={this.next}
              />
            </Carousel>
            <Row className="mx-0 p-5">
              <div
                className="col-sm-11"
                style={{ textAlign: "left", paddingRight: 0 }}
              >
                <div className="green-txt">
                  {this.props.data.score !== 0 ? (
                    this.Rating()
                  ) : (
                    <p> No rating yet </p>
                  )}
                </div>
                <div
                  className="card-review-user"
                  style={{ color: "#8AC53F", paddingRight: 8 }}
                >
                  {" "}
                  {this.state.reviewsCount.length} Reviews{" "}
                </div>
              </div>
            </Row>
            <Row className="mx-0 p-5">
              <UserRating
                equipmentId={this.state.postId}
                score={this.props.data.postInfo ? this.props.data.score : null}
              />
            </Row>
          </Col>

          <Col sm="5" style={{ backgroundColor: "#FCFCFC" }}>
            {this.state.postInfo && (
              <ViewPostTabs
                postInfo={this.state.postInfo}
                postId={this.state.postId}
                postUserId={this.state.postUserId}
                minStartDate={this.state.minStartDate}
                maxEndDate={this.state.maxEndDate}
                isDeliveryAvailable={this.state.isDeliveryAvailable}
                isPickupAvailable={this.state.isPickupAvailable}
                isRentingOut={this.props.data.isRentedOut}
                isOpenEndedRental={this.props.data.isOpenEndedRental}
                user={this.props?.user}
                minimumRentalPeriod={this.props?.data?.minimumRentalPeriod}
              />
            )}
          </Col>
        </Row>
      </Col>
    );
  }
}

ViewPost.propTypes = {
  postImageUrl: PropTypes.string,
  postName: PropTypes.string,
  postUser: PropTypes.string,
  postRating: PropTypes.number,
  postId: PropTypes.string,
  postUserId: PropTypes.string,
  interval: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default ViewPost;
