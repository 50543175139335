import React from 'react';
import Header from '../Header'
import Footer from '../Footer'

class MainLayout extends React.Component{

    render(){
        if(this.props.onUpdate){
            this.props.onUpdate()
        }
        return(
            <div>
                <Header  authenticated={this.props.authenticated} bannerDisplay={this.props.bannerDisplay} userName={this.props.userName} userLocation={this.props.userLocation} userId={this.props.userId} profileImage={this.props.profileImage} />
                    <div className="page-content col-sm-12">
                        {this.props.children}
                    </div>
                <Footer />
            </div>
        )
    }
}

export default MainLayout;