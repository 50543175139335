import $ from "jquery";
import jQuery from "jquery";

export function postVerify(incomingData) {
  let data = {
    username: incomingData.email,
    email: incomingData.email,
    firstName: incomingData.firstName,
    lastName: incomingData.lastName,
    phone: incomingData.phone,
    emailVerificationToken: "",
    emailVerificationCode: "",
    defaultTwoFactorProvider: "email",
    sponsorID: incomingData.sponsorID,
    password: incomingData.password,
    passwordConfirmation: incomingData.passwordConfirmation,
  };

  return $.ajax({
    method: "POST",
    dataType: "json",
    data: JSON.stringify(data),
    traditional: true,
    xhrFields: {
      withCredentials: false,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    url: process.env.REACT_APP_API + "/account/register/verify/v2",
  }).then((res) => res.data);
}
