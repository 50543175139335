import React from "react";

import PropTypes from "prop-types";
import ViewPost from "./ViewPost";
import EditPost from "./EditPost";
import { Redirect } from "react-router-dom";
import "./ppstyle.css";
import { activateEquipment } from "../../App/Api/activateEquipment";
import { deactivateEquipment } from "../../App/Api/deactivateEquipment";

import {
  Navbar,
  Button,
  Dropdown,
  DropdownMenu,
  Row,
  Col,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import $ from "jquery";

class PostPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postUserId: null,
      userId: 1,
      postId: null,
      editable: false,
      isActive: false,
      data: null,
    };
  }

  componentWillMount() {
    // this.setState({ postId: this.props.match.params.id });
    // this.setState({
    //   postUserId: this.props.location.state.postUserId,
    //   postId: this.props.location.state.postId
    // });
  }
  deletePost = () => {
    let answer = window.confirm(
      "Are you sure you want to delete this equipment?"
    );
    if (answer == true) {
      $.ajax({
        method: "delete",
        dataType: "json",
        traditional: true,
        xhrFields: {
          withCredentials: true,
        },
        url: `${process.env.REACT_APP_API}/equipments/${this.props.match.params.id}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then(function (res) {
        window.history.back();
      });
    }
  };

  componentDidMount() {
    // this.setState({ postUserId: this.props.location.state.postUserId });
    this.setState({ postId: this.props.match.params.id });
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: `${process.env.REACT_APP_API}/equipments/${this.props.match.params.id}`,

      headers: {
        // Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      let myImages = res.data.equipmentMedias.map((element) => {
        return {
          src: element.filePath,
          type: element.type,
          // altText: 'Slide 1',
          // caption: 'Slide 1'
        };
      });

      vm.setState({
        data: res.data,
        isActive: res.data.isActive,
        postUserId: res.data.user.id,

        // editable: editable
      });
    });
    // this.setState((userId: localStorage.getItem("")))
    // console.log( this.state.postUserId)
  }

  getAvailability = (availability) => {
    this.setState({ isActive: availability });
  };
  // componentDidUpdate(){
  //   window.scrollTo(0,0);
  // }
  changeActiveRental = () => {
    // console.log('ACTIVE / DEACTIVE')
    if (this.state.isActive) {
      deactivateEquipment(this.state.postId).then(function (res) {
        window.location.reload();
      });
    } else {
      activateEquipment(this.state.postId).then(function (res) {
        window.location.reload();
      });
    }
  };

  render() {
    // console.log(this.state.postUserId)

    if (localStorage.getItem("accessToken")) {
      return (
        <React.Fragment>
          {localStorage.getItem("userId") === this.state.postUserId ? (
            <div className="edit-own-post-menu">
              <Row style={{ padding: "20px 25px" }}>
                <Col sm="6" className="buttonrow">
                  {/* <Button className="edit-button" type="button">Returned</Button> */}
                  {this.state.isActive ? (
                    <Button
                      className="out-of-service-button"
                      onClick={() => this.changeActiveRental()}
                      type="button"
                    >
                      Mark As Out Of Service
                    </Button>
                  ) : (
                    <Button
                      className="in-service-button"
                      onClick={() => this.changeActiveRental()}
                      type="button"
                    >
                      Mark As in Service
                    </Button>
                  )}
                </Col>

                <Col sm="6" className="buttonrowtwo">
                  <Button
                    color="primary"
                    style={{ marginRight: 15, backgroundColor: "#E24A4A" }}
                    type="button"
                    className="out-of-service-button"
                    onClick={() => this.deletePost()}
                  >
                    Delete
                  </Button>
                  <Button
                    className="edit-button"
                    type="button"
                    onClick={() =>
                      this.setState({ editable: !this.state.editable })
                    }
                  >
                    {this.state.editable ? "View" : "Edit"} Post
                  </Button>
                </Col>
              </Row>
            </div>
          ) : null}
          {this.state.editable &&
          localStorage.getItem("userId") === this.state.postUserId
            ? this.state.data && (
                <EditPost
                  availability={this.getAvailability}
                  postId={this.state.postId}
                  postUserId={this.state.postUserId}
                  data={this.state.data}
                />
              )
            : this.state.data && (
                <ViewPost
                  availability={this.getAvailability}
                  postId={this.state.postId}
                  postUserId={this.state.postUserId}
                  data={this.state.data}
                  user={this.props?.user}
                />
              )}
        </React.Fragment>
      );
    } else {
      return (
        this.state.data && (
          <ViewPost
            availability={this.getAvailability}
            postId={this.state.postId}
            postUserId={this.state.postUserId}
            data={this.state.data}
          />
          //    <div className="notlogged-in" style={{}}>
          //   <h2>You Must be Logged in to view equipment</h2>
          // </div>
        )
      );
    }
  }
}

PostPage.propTypes = {
  postUserId: PropTypes.number,
};

export default PostPage;
