import $ from 'jquery';

export function getPaymentInfo(){

  return $.ajax({
    method: "GET",
    dataType: 'json',
    traditional: true,
    data: {},
    xhrFields: {
      withCredentials: true
   },
    url: `${process.env.REACT_APP_API}/users/me/paymentinfo`,
    
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
  }).then(res => res.data).catch(res => res)
}