import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { compose, withProps, withStateHandlers } from "recompose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Rating = (rating) => {
  let stars = [];
  if (rating === 0) {
    return <span style={{ color: "rgba(0,0,0,0.4)" }}>Not yet rated</span>;
  } else {
    for (let i = 0; i < rating; i++) {
      stars.push(<FontAwesomeIcon icon={faStar} className="checked fa-star" />);
    }
    return stars;
  }
};

const MapWithPlaces = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDpLI1D7Mc3yq_7sc6l6dkgFPPYpmiLVLM&libraries=places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: "50vh", width: "100%" }} />,
    mapElement: <div style={{ height: "100%" }} />,
  }),
  withStateHandlers(
    (props) => ({
      infoWindows: props.places.map((p) => {
        return { isOpen: false };
      }),
    }),
    {
      onToggleOpen: ({ infoWindows }) => (selectedIndex) => ({
        infoWindows: infoWindows.map((iw, i) => {
          if (iw) {
            iw.isOpen = selectedIndex === i;
          }
          return iw;
        }),
      }),
    }
  ),
  withScriptjs,
  withGoogleMap
)(
  (props) => (
    console.log(props),
    (
      <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={{
          lat: props.places[0].latitude,
          lng: props.places[0].longitude,
        }}
      >
        {props.places &&
          props.places.map((place, i) => {
            let lat = place.latitude;
            let lng = place.longitude;

            return (
              <Marker
                id={place.id}
                key={place.id}
                position={{ lat: lat, lng: lng }}
                title="Click to zoom"
                onMouseOver={props.onToggleOpen.bind(this, i)}
                icon={{
                  path:
                    "M 21.476 51.011 L 6.291 36.071 a 22.188 22.188 0 0 1 -0.344 -0.344 A 20.886 20.886 0 0 1 6.291 6.19 a 21.723 21.723 0 0 1 30.373 0 l 0.14 0.139 l 0.033 0.032 l 0.174 0.173 a 20.883 20.883 0 0 1 -0.347 29.537 L 21.477 51.009 Z m 0 -38.2 a 7.344 7.344 0 1 0 7.346 7.346 a 7.355 7.355 0 0 0 -7.343 -7.349 Z",
                  fillColor: "#8ac53f",
                  fillOpacity: 1.0,
                  strokeWeight: 0,
                  scale: 1.25,
                }}
              >
                {props.infoWindows[i].isOpen && (
                  <Link
                    to={{
                      pathname: `/post/${place.id}`,
                      state: {
                        postUserId: place.markerUserId,
                        postId: place.id,
                      },
                    }}
                  >
                    <InfoWindow onCloseClick={props.onToggleOpen.bind(i)}>
                      <div className="map-hover-popup" style={{ padding: 10 }}>
                        <img
                          width="200px"
                          src={place.clusterImg}
                          alt="item img"
                        />
                        <p
                          className="map-marker-title"
                          style={{
                            textAlign: "left",
                            marginTop: 10,
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                          {place.name}
                        </p>
                        {/* <p style={{textAlign:"left",marginTop:10,fontWeight:"400", marginTop:5, marginBottom:5}}> By <strong>{place.mapFname} {place.mapLname} </strong></p> */}
                        <p
                          style={{
                            textAlign: "left",
                            marginTop: 10,
                            fontWeight: "400",
                            marginTop: 5,
                            marginBottom: 5,
                          }}
                        >
                          {" "}
                          Category: {place.mapMarkerCategory}
                        </p>
                        {Rating(place.markerRating)}
                      </div>
                    </InfoWindow>
                  </Link>
                )}
              </Marker>
            );
          })}
      </GoogleMap>
    )
  )
);
// props.infoWindows[i].isOpen &&
export default MapWithPlaces;
