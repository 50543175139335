import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye,
  faUser,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";

import $ from "jquery";
import "./style.css";

class AboutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutTheApp: "",
    };
  }

  // changetoLogin() {
  //   this.props.toggle('signin');
  //   this.props.toggle('login');
  // }

  // changetoLogin = () => {
  //   this.props.toggle('resetpass');
  //   this.props.toggle('login');
  // }

  componentDidMount() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/settings/aboutapp",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      let finishedTXT = res.data.content.replace(/\n/g, "<br>");
      vm.setState({ aboutTheApp: finishedTXT });
    });
  }

  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("about")}>
        &times;
      </button>
    );
    //  if ( this.state.aboutTheApp == null ) {
    //     alert (
    //      "Comming soon"
    //     )
    //  } else
    return (
      <div>
        <Modal
          className="about-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("about")}
          size="lg"
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B" }}
          >
            About Nubosh
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col sm={12}>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.aboutTheApp }}
                  ></div>
                  {/* <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.</p> */}
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

AboutModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string,
};

export default AboutModal;
