import React from "react";
import { Button, Container } from "reactstrap";
import PropTypes from "prop-types";
import "./footer.css";
import SettingsTosModal from "../../Common/Modals/SettingsTosModal";
import SettingsPpModal from "../../Common/Modals/SettingsPpModal";
import AboutModal from "../../Common/Modals/AboutModal";
class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      AboutModalOpen: false,
      SettingsTosModalOpen: false,
      SettingsPpModalOpen: false
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle(type) {
    if (type == "about") {
      this.setState(prevState => ({
        AboutModalOpen: !prevState.AboutModalOpen
      }));
    }
    if (type == "settingstosmodal") {
      this.setState(prevState => ({
        SettingsTosModalOpen: !prevState.SettingsTosModalOpen
      }));
    }
    if (type == "settingsppmodal") {
      this.setState(prevState => ({
        SettingsPpModalOpen: !prevState.SettingsPpModalOpen
      }));
    }
  }
  render() {
    return (
      <React.Fragment>
        <AboutModal
          toggle={this.toggle}
          modalOpen={this.state.AboutModalOpen}
        ></AboutModal>
        <SettingsTosModal
          toggle={this.toggle}
          modalOpen={this.state.SettingsTosModalOpen}
        ></SettingsTosModal>
        <SettingsPpModal
          toggle={this.toggle}
          modalOpen={this.state.SettingsPpModalOpen}
        ></SettingsPpModal>
        <div className="col-sm-12 footer-bg">
          <div style={{ color: '#fff', opacity: .3, float: 'left', position: 'absolute' }}>Version 1.0</div>
          <div style={{ float: "right", padding: "1em 3em" }}>
            <Button
              onClick={() => this.toggle("about")}
              color="link"
              className="footer-links"
            >
              About
            </Button>
            <Button
              onClick={() => this.toggle("settingsppmodal")}
              color="link"
              className="footer-links"
            >
              Privacy Policy
            </Button>
            <Button
              onClick={() => this.toggle("settingstosmodal")}
              color="link"
              className="footer-links"
            >
              Terms of Service
            </Button>
          </div>

        </div>
      </React.Fragment>
    );
  }
}
Footer.propTypes = {
  authenticated: PropTypes.bool,
  userName: PropTypes.string,
  userLocation: PropTypes.string,
  notifications: PropTypes.object
};
export default Footer;
