import $ from "jquery";

export function postRegister(incomingData) {
  let data = {
    username: incomingData.email,
    email: incomingData.email,
    firstName: incomingData.firstName,
    lastName: incomingData.lastName,
    phone: incomingData.phone,
    emailVerificationToken: incomingData.emailVerificationToken,
    emailVerificationCode: incomingData.emailVerificationCode,
    defaultTwoFactorProvide: "email",
    sponserID: incomingData.sponsorID,
    password: incomingData.password,
    passwordConfirmation: incomingData.passwordConfirmation,
    companyName: incomingData.companyName,
  };
  return $.ajax({
    method: "POST",
    dataType: "json",
    data: JSON.stringify(data),
    traditional: true,
    xhrFields: {
      withCredentials: false,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    url: process.env.REACT_APP_API + "/account/register/v2",
  }).then((res) => res);
}
