import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Row,
  // Col,
  // Form,
  FormGroup,
  // Label,
  Input,
  // FormText
} from "reactstrap";
import PropTypes from "prop-types";
// import $ from "jquery";
import PDFViewer from "mgr-pdf-viewer-react";

// import thepdf from "../../../Common/images/Recipe.pdf";
import "./style.css";

class RentalPdfModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultPdf: "",
      numPages: null,
      pageNumber: 1,
      makePaymentButtonEnabled: this.props.isPaymentButtonDisabled,
    };
  }
  // onDocumentLoadSuccess = ({ numPages }) => {
  //   this.setState({ numPages });
  // };
  componentDidMount() {}

  // goToPrevPage = () =>
  //   this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  // goToNextPage = () =>
  //   this.setState(state => ({ pageNumber: state.pageNumber + 1 }));

  onAgree = () => {
    // this.setState({ makePaymentButtonEnabled: false });
    this.props.toggle("rentalpdf");
    this.props.acceptTerms(false);
  };
  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("rentalpdf")}>
        &times;
      </button>
    );

    let agreement = this.props.agreement;
    return (
      <div>
        <Modal
          className="pdf-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("rentalpdf")}
          size="lg"
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B" }}
          >
            Rental Agreement
          </ModalHeader>
          <ModalBody>
            <p style={{ textAlign: "center" }}>
              You must agree to the Rental Agreement to continue.
            </p>
            {/* <iframe src={agreement} style={{ width: "100%", height: 700 }} /> */}
            <PDFViewer
              document={{
                url: `${agreement}`,
              }}
            />
          </ModalBody>
          <ModalFooter>
            <FormGroup row>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <Input
                  type="text"
                  name="signature"
                  className="signature"
                  disabled
                  style={{
                    fontFamily: "Great Vibes, cursive",
                    fontSize: "24px",
                    border: "0.5px solid #7070704D",
                    paddingLeft: "10px",
                    width: "81%",
                    height: "90%",
                    marginTop: 3,
                  }}
                  value={this.props?.user}
                />
                <Button
                  className="agree-button-modal-pdf blockbutton"
                  onClick={this.onAgree}
                >
                  Agree and Sign{" "}
                </Button>
              </div>
            </FormGroup>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

RentalPdfModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string,
};

export default RentalPdfModal;
