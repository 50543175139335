import React from "react";
import {
  Card,
  Button,
  CardTitle,
  CardImg,
  CardBody,
  Row,
  Col,
  CardText,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import $ from "jquery";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
// import { Map, Marker, GoogleApiWrapper} from "google-maps-react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../Common/Loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faArrowLeft,
  faThermometerThreeQuarters,
} from "@fortawesome/free-solid-svg-icons";
import { getRental } from "../../App/Api/getRental";
import RatingsModal from "../../Common/Modals/RatingsModal";
import ExtendRentalModal from "../../Common/Modals/ExtendRentalModal";

import ReportUserModal from "../../Common/Modals/ReportUserModal";
import Map from "../PostPage/EditPost/GoogleAutocomplete";
import "./rentingpost.css";

import { extendRental } from "../../App/Api/extendRental";
import { cancelRental } from "../../App/Api/cancelRental";
import { activateEquipment } from "../../App/Api/activateEquipment";
import { deactivateEquipment } from "../../App/Api/deactivateEquipment";
import { returnRental } from "../../App/Api/returnRental";
import { rentalsAvailability } from "../../App/Api/rentalsAvailability";

import { extendMoment } from "moment-range";
import originalMoment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import PDF from "../PostPage/ViewPost/ViewPostTabs/PDF";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];

const moment = extendMoment(originalMoment);

class ViewRentingPost extends React.Component {
  constructor(props) {
    super(props);

    // Carousel
    this.state = {
      loading: true,
      type: "",
      postUserId: null,
      postUserName: null,
      rentalId: null,
      activeIndex: 0,
      score: 0,
      ReportUserModalOpen: false,
      startDate: "Thurs, Jan 17, 9:00 AM",
      endDate: "Fri, Jan 25, 5:00 PM",
      dailyRate: 150,
      weeklyRate: 400,
      monthlyRate: 1000,
      equipmentId: null,
      equipmentName: "",
      make: "$",
      model: "SXL",
      year: "2012",
      description:
        "This svu does this and that and this and Deen. This svu does this and that and this and Deen This svu does this and that and this and Deen",
      specifications: ["5 Tonnes", "4 wheel Drive"],
      comments:
        "This is a very long string that will allow for the user to talk more about the equipment they are trying to rent out",
      location: {
        type: "Pickup / Delivery",
        geometry: {
          location: {
            lat: 51.05011,
            lng: -114.08529,
          },
        },
        address: "",
        returnAddress: "",
      },
      pickupDilivery: "Pickup",
      total: 300,
      numOfReviews: 0,
      profilePic: "",
      media: [],
      ratings: [],
      listOfAddOns: [],
      ratingmodal: false,
      isActive: false,
      confirmedAt: null,
      extendRentalAvailabel: false,
      extendEndDate: null,
      extendEndTime: null,
      extendEquipmentModal: false,
      extendable: false,
      endTime: null,
      rated: "",
      completed: "",
      utcStartDate: moment(),
      utcEndDate: null,
      successModal: false,
      cancelRental: false,
      openEndedRentalStatus: 0,
      pdf: "",
      insuranceApprovalDecision: null,
      insuranceApprovalDecisionAt: null,
      insuranceApprovalDecisionBy: null,
      insuranceApprovalDecisionById: null,
      insuranceApprovalStatus: "",
      insuranceApprovalStatusCode: "",
      insurancePDFPath: "",
      fileName: "",
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.goBack = this.goBack.bind(this);
    this.fileInputRef = React.createRef();
  }

  componentWillUpdate(np) {
    const { match } = this.props;

    const previousId = match.params.id;
    const nextId = np.match.params.id;
    if (nextId && previousId !== nextId) {
      window.location.reload();
    }
  }

  componentDidMount() {
    this.getRentalCall();
  }

  getRentalCall = () => {
    const vm = this;
    getRental(this.props.match.params.id)
      .then((res) => {
        if (
          res.comfirmedAt !== null &&
          res.rating === null &&
          res.equipmentOwner.id !== localStorage.getItem("userId")
        ) {
          vm.setState({ ratingmodal: true });
        }

        let deliveryType = "";
        let setstartTime;
        let setendTime;
        if (res.deliveryLocationAddress) {
          setstartTime = res.startTime;
          setendTime = res.endTime;
          deliveryType = "delivery";
        } else {
          deliveryType = "pickup";
          setstartTime = res.equipments[0].startTime;
          setendTime = res.equipments[0].endTime;
        }

        let startTime = setstartTime.split(":");
        let startHour = startTime[0];

        let startMoment = moment
          .utc(res.startDate)
          .set({ hour: 0 })
          .add(startHour, "hours");
        let startDate = startMoment
          .local()
          .format("ddd, MMM  DD, YYYY, hh:mm A");
        let startTimeString = startDate;

        let allowCancel;
        if (
          startMoment
            .subtract(1, "days")
            .startOf("day")
            .isBefore(moment().startOf("day"))
        ) {
          allowCancel = false;
        } else {
          allowCancel = res.refundAllowed;
        }
        let endTime = setendTime.split(":");
        let endHour = endTime[0];
        let endMoment = moment
          .utc(res.endDate)
          .set({ hour: 0 })
          .add(endHour, "hours");
        let endDate = endMoment.local().format("ddd, MMM  DD, YYYY, hh:mm A");
        let endTimeString = endDate;

        let listOfAddOns = [];
        let name;
        let mediaItems = [];
        let type = "";
        let showName = "";

        let otherUserId;
        let profileImage;
        if (localStorage.getItem("userId") == res.equipmentOwner.id) {
          type = "rentingOut";
          showName = res.renter.firstName + " " + res.renter.lastName;
          otherUserId = res.renter.id;
          profileImage = res.renter.profileImage;
        } else if (localStorage.getItem("userId") == res.renter.id) {
          type = "renting";
          showName =
            res.equipmentOwner.firstName + " " + res.equipmentOwner.lastName;
          otherUserId = res.equipmentOwner.id;
          profileImage = res.equipmentOwner.profileImage;
        }

        let location;
        let dailyRate;
        let weeklyRate;
        let monthlyRate;
        let make;
        let model;
        let year;
        let numOfReviews;
        let specifications;
        let description;
        let isActive;
        let equipmentId;
        let comments;

        if (res.equipments.length <= 1) {
          name = res.equipments[0].name;

          res.equipments[0].equipmentMedias.forEach((media, i) => {
            let obj = {
              src: media.filePath,
              altText: "Slide " + i,
              caption: "Slide " + i,
              type: media.type,
            };
            mediaItems.push(obj);
          });

          if (deliveryType == "delivery") {
            location = {
              type: deliveryType,
              geometry: {
                location: {
                  lat: res.equipments[0].deliveryLocationLatitude,
                  lng: res.equipments[0].deliveryLocationLongitude,
                },
              },
              address: res.deliveryLocationAddress,
              returnAddress: res.deliveryLocationAddress,
            };
          } else {
            location = {
              type: deliveryType,
              geometry: {
                location: {
                  lat: res.equipments[0].pickupLocationLatitude,
                  lng: res.equipments[0].pickupLocationLongitude,
                },
              },
              address: res.equipments[0].pickupLocationAddress,
              returnAddress: res.equipments[0].pickupLocationAddress,
            };
          }

          dailyRate = res.equipments[0].dailyRate;
          weeklyRate = res.equipments[0].weeklyRate;
          monthlyRate = res.equipments[0].monthlyRate;
          make = res.equipments[0].make;
          model = res.equipments[0].model;
          year = res.equipments[0].year;
          // ratings = res.equipments[0].ratings;
          numOfReviews = res.equipments[0].ratings.length;
          specifications = res.equipments[0].specifications;
          description = res.equipments[0].description;
          isActive = res.equipments[0].isActive;
          equipmentId = res.equipments[0].id;
          comments = res.equipments[0].comments;
        } else {
          res.equipments.forEach((equipment) => {
            if (equipment.isAddOn) {
              listOfAddOns.push(equipment);
            } else {
              name = equipment.name;

              equipment.equipmentMedias.forEach((media, i) => {
                let obj = {
                  src: media.filePath,
                  altText: "Slide " + i,
                  caption: "Slide " + i,
                  type: media.type,
                };
                mediaItems.push(obj);
              });

              if (deliveryType == "delivery") {
                location = {
                  type: deliveryType,
                  geometry: {
                    location: {
                      lat: equipment.deliveryLocationLatitude,
                      lng: equipment.deliveryLocationLongitude,
                    },
                  },
                  address: equipment.pickupLocationAddress,
                  returnAddress: equipment.deliveryLocationAddress,
                };
              } else {
                location = {
                  type: deliveryType,
                  geometry: {
                    location: {
                      lat: equipment.pickupLocationLatitude,
                      lng: equipment.pickupLocationLongitude,
                    },
                  },
                  address: equipment.pickupLocationAddress,
                  returnAddress: equipment.pickupLocationAddress,
                };
              }

              dailyRate = equipment.dailyRate;
              weeklyRate = equipment.weeklyRate;
              monthlyRate = equipment.monthlyRate;
              make = equipment.make;
              model = equipment.model;
              year = equipment.year;
              // ratings = equipment.ratings;
              numOfReviews = equipment.ratings.length;
              specifications = equipment.specifications;
              description = equipment.description;
              equipmentId = equipment.id;
            }
          });
        }

        let ratings = [];
        let score = 0;
        if (res.rating) {
          let obj = {
            description: res.rating.description,
            firstName: res.rating.ratedBy.firstName,
            lastName: res.rating.ratedBy.lastName,
            profileImage: res.rating.ratedBy.profileImage,
            score: res.rating.score,
          };
          ratings.push(obj);
          score = res.rating.score;
        }

        vm.setState(
          {
            rentalId: this.props.match.params.id,
            startDate: startTimeString,
            endDate: endTimeString,
            endTime: setendTime,
            startTime: setstartTime,
            equipmentEndDate: res.equipments[0].endDate,
            dailyRate: dailyRate,
            weeklyRate: weeklyRate,
            monthlyRate: monthlyRate,
            make: make,
            model: model,
            year: year,
            equipmentName: name,
            description: description,
            specifications: specifications,
            location: location,
            postUserId: otherUserId,
            postUserName: showName,
            loading: false,
            total: res.total,
            numOfReviews: numOfReviews,
            profilePic: profileImage,
            media: mediaItems,
            ratings: ratings,
            isActive: isActive,
            confirmedAt: res.comfirmedAt,
            refundedAt: res.refundedAt,
            comments: comments,
            type: type,
            equipmentId: equipmentId,
            allowCancel: allowCancel,
            listOfAddOns: listOfAddOns,
            score: score,
            utcStartDate: res.startDate,
            utcEndDate: res.endDate,
            openEndedRentalStatus: res.openEndedRentalStatus,
            insuranceApprovalDecision: res?.insuranceApprovalDecision,
            insuranceApprovalStatus: res?.insuranceApprovalStatus,
            insuranceApprovalStatusCode: res?.insuranceApprovalStatusCode,
            insurancePDFPath: res?.insurancePDFPath,
          },
          () => {
            if (type == "renting") {
              rentalsAvailability(vm.state.equipmentId).then(function (res) {
                let nextStartDate = moment(res.rentalDates[0].startDate);

                // let nextEndDate = moment(res.rentalDates[0].endDate);

                // let today = moment();
                // let tomorrow = moment().add(1, "days");

                let currentstartdate = moment(vm.state.startDate);
                // let currentenddate = moment(vm.state.endDate);
                let equipmentEndDate = moment(vm.state.equipmentEndDate);

                let extendable = false;
                let sameNextDate = false;

                if (nextStartDate.isSame(currentstartdate)) {
                  sameNextDate = true;
                }

                // if (sameNextDate) {
                //   if (
                //     equipmentEndDate.isAfter(currentenddate) &&
                //     tomorrow.isBefore(currentenddate) &&
                //     today.isSameOrAfter(currentstartdate)
                //   ) {
                //     extendable = true;
                //   }
                // } else {
                //   if (
                //     nextStartDate.isAfter(currentenddate) &&
                //     tomorrow.isBefore(currentenddate) &&
                //     today.isSameOrAfter(currentstartdate)
                //   ) {
                //     extendable = true;
                //   }
                // }
                vm.setState({ extendable: extendable });
                if (sameNextDate) {
                  vm.setState({ nextStartDate: equipmentEndDate });
                } else if (!sameNextDate) {
                  vm.setState({ nextStartDate: nextStartDate });
                }
              });
            }
          }
        );
      })
      .catch((res) => {
        console.log(res);
        vm.setState({ loading: false });
      });
  };

  handleFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] }, () => {
      this.uploadPdf();
    });
  };
  handleButtonClick = () => {
    this.fileInputRef.current.click();
  };
  handleFileUpload = () => {
    const { selectedFile } = this.state;
    if (selectedFile) {
      this.uploadPdf();
    } else {
      console.log("No file selected.");
    }
  };

  uploadPdf = (e) => {
    let vm = this;
    const fd = new FormData();
    fd.append("file", this.state.selectedFile);
    fd.append("public", true);
    let data = {
      File: this.state.selectedFile,
      Public: true,
    };
    let dataFileName = {
      File: this.state.selectedFile.name,
    };
    console.log("upload PDF", data);
    console.log("upload PDF Name", data.File.name);
    console.log("file name dataFileName", dataFileName);

    $.ajax({
      method: "POST",
      processData: false,
      contentType: false,
      data: fd,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/uploads/uploadpdf",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then(function (res) {
        console.log("file path", res.data.filePath);
        vm.setState({
          insurancePDFPath: res.data.filePath,
          fileName: dataFileName.File,
          uploadedSuccess: "uploaded",
        });
        console.log("state file path", vm.state.insuranceDoc);
        console.log("state file nam", vm.state.fileName);
      })
      .catch(function (res) {
        console.log("file failed");
        vm.setState({
          uploadedSuccess: "Failed to upload",
        });
      });
  };

  handleInsurance = async () => {
    try {
      const data = {
        insurancePDFPath: this.state?.insurancePDFPath,
      };
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API}/rentals/${this.props.match.params.id}/insurance`,
        data,
        {
          headers,
        }
      );
      console.log("Response:", response);
      if (response.status === 200) {
        toast(response?.data.message, { type: "success" });
        this.getRentalCall();
      } else {
        toast("Something went wrong", { type: "error" });
      }
    } catch (error) {
      toast("Something went wrong", { type: "error" });
    }
  };

  toggle = (type) => {
    if (type === "ratings") {
      this.setState({ ratingmodal: false });
    } else if (type === "reportusermodal") {
      this.setState((prevState) => ({
        ReportUserModalOpen: !prevState.ReportUserModalOpen,
      }));
    } else if (type === "payment") {
    } else if (type === "extendRental") {
      this.setState((prevState) => ({
        extendEquipmentModal: !prevState.extendEquipmentModal,
      }));
    } else if (type == "success") {
      this.setState(
        (prevState) => ({
          successModal: !prevState.successModal,
        }),
        () => {
          if (this.state.successModal == false) {
            window.location.reload();
          }
        }
      );
    }
  };

  changeActiveRental = () => {
    // console.log('ACTIVE / DEACTIVE')
    if (this.state.isActive) {
      deactivateEquipment(this.state.equipmentId).then(function (res) {});
    } else {
      activateEquipment(this.state.equipmentId).then(function (res) {});
    }
  };

  chargeExtra = async (amount, reason) => {
    const vm = this;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    let data = {
      amountToCharge: JSON.parse(amount),
      rentalId: vm.state.rentalId,
      stripeToken: null,
      reason: reason,
    };
    console.log(data);
    axios
      .post(`${process.env.REACT_APP_API}/rentals/extracharge`, data, {
        headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === "SUCCESS") {
          Swal.fire(`$${amount} Charged Extra`).then(function () {
            if (vm.state.openEndedRentalStatus === (1 || 2)) {
              vm.openEndedCharge();
            } else {
              vm.returnCall();
            }
          });
        } else {
          Swal.fire({
            text: "Cannot charge extra, Please contact admin!",
            confirmButtonColor: "#3E3F9B",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((res) => {
        console.log(res.response.data.message);
        Swal.fire({
          text: "Cannot charge extra, Please contact admin!",
          confirmButtonColor: "#3E3F9B",
          confirmButtonText: "Ok",
        });
      });
  };

  openEndedCharge = () => {
    const vm = this;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    let data = {
      rentalId: vm.state.rentalId,
      stripeToken: null,
    };
    axios
      .post(`${process.env.REACT_APP_API}/rentals/charge`, data, {
        headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === "SUCCESS") {
          this.returnCall();
        } else {
          Swal.fire({
            text: "Something wrong, Please contact admin!",
            confirmButtonColor: "#3E3F9B",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((res) => console.log(res));
  };

  returnRental = () => {
    const vm = this;
    // console.log('Return')
    const { value: formValues } = Swal.fire({
      title: "Do you want to charge extra?",
      showDenyButton: true,
      html:
        '<input placeholder="Amount" type="number" id="swal-input1" class="swal2-input" min="0"> <br/>' +
        '<select id="swal-input2" class="swal2-input"> <option value="">Please choose a reason</option> <option value="fuel-charge">Fuel charge</option> <option value="repair">Repair</option> <option value="cleaning">Cleaning</option> <option value="buy-out">Buy-out</option></select>',
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
        ];
      },
      showCancelButton: false,
      confirmButtonText: `Yes, Charge extra`,
      denyButtonText: `No extra Charge, Mark Returned`,
      denyButtonColor: "#8ac53f",
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value[0] > 0 && result.value[1]) {
          vm.chargeExtra(result.value[0], result.value[1]);
        } else {
          Swal.fire("", "Amount and Reason required if charging extra!").then(
            function () {
              vm.returnRental();
            }
          );
        }
      } else if (result.isDenied) {
        if (vm.state.openEndedRentalStatus === (1 || 2)) {
          vm.openEndedCharge();
        } else {
          vm.returnCall();
        }
      }
    });
  };

  returnCall = () => {
    const vm = this;

    returnRental(vm.state.rentalId).then(function (res) {
      console.log(res);
      Swal.fire({
        text: "Equipment returned!",
        confirmButtonColor: "#3E3F9B",
        confirmButtonText: "Ok",
      }).then(function () {
        window.location.reload();
      });
    });
  };

  cancelRental = () => {
    // console.log('CANCEL')
    Swal.fire({
      title: "Confirm Cancellation",
      text: "Are you sure you want to cancel this booking? You will receive a full refund of the charged amount!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#8ac53f",
      confirmButtonText: "Yes",
      cancelButtonColor: "#3E3F9B",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        cancelRental(this.state.rentalId).then(function (res) {
          if (res.code == "RENTAL_CANCELED") {
            Swal.fire("Canceled!", "Rental Canceled.", "success");
            window.history.back();
          }
        });
      }
    });
  };

  saveByteArray = (reportName, byte) => {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  createPdf = () => {
    const vm = this;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    };
    vm.setState({ loading: true });
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/rentals/${vm.state.rentalId}/receipt`,
      headers,
      responseType: "arraybuffer",
    })
      .then(function (res) {
        console.log(res);
        if (res.status === 200) {
          let pdf = res.data;
          vm.saveByteArray(
            "Nubosh Receipt - " + moment().format("MMM D YYYY, hh:mm a"),
            pdf
          );
          vm.setState({ loading: false });
        } else {
          alert("Failed to Download, Please contact admin");
          vm.setState({ loading: false });
        }
      })
      .catch(function (err) {
        console.log(err);
        alert("Failed to Download, Please contact admin");
      });
  };

  extendRental = () => {
    // console.log('EXTEND')
    extendRental(this.state.rentalId).then(function (res) {});
  };

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.media.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.media.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  goBack() {
    window.history.back();
  }

  render() {
    let Rating = () => {
      let stars = [];
      if (this.state.score === 0) {
        return <span style={{ color: "rgba(0,0,0,0.4)" }}>Not yet rated</span>;
      } else {
        for (let i = 0; i < this.state.score; i++) {
          stars.push(
            <FontAwesomeIcon icon={faStar} className="checked fa-star" />
          );
        }
        return stars;
      }
    };

    const { activeIndex } = this.state;
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("extendRental")}
      >
        &times;
      </button>
    );
    const slides = this.state.media.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          {item.type == 0 && <img src={item.src} alt={item.altText} />}
          {item.type == 2 && (
            <video
              width="100%"
              controls
              src={item.src}
              alt={item.altText}
              type="video/mp4"
            />
          )}
          {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
        </CarouselItem>
      );
    });

    return (
      <Col>
        <Modal
          className="feedback-modal"
          isOpen={this.state.successModal}
          toggle={() => this.toggle("success")}
          size="lg"
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B", paddingLeft: "50px" }}
          >
            Success
          </ModalHeader>
          <ModalBody>
            <Col style={{ textAlign: "center" }} sm={12}>
              <div>You have extended your rental</div>
            </Col>
          </ModalBody>
        </Modal>

        <RatingsModal
          id={this.props.match.params.id}
          toggle={this.toggle}
          modalOpen={this.state.ratingmodal}
        />

        {this.state.equipmentId && this.state.nextStartDate && (
          <ExtendRentalModal
            toggle={this.toggle}
            modalOpen={this.state.extendEquipmentModal}
            equipmentId={this.state.rentalId}
            before={moment(this.state.endDate).toDate()}
            after={this.state.nextStartDate.toDate()}
            endTime={this.state.endTime}
            startTime={this.state.startTime}
            addonIds={this.state.listOfAddOns}
            deliveryLocation={this.state.deliveryLocationAddress}
            deliveryLocationLatitude={this.state.deliveryLocationLatitude}
            deliveryLocationLongitude={this.state.deliveryLocationLongitude}
          />
        )}
        {this.state.loading && <Loading modalOpen={this.state.loading} />}

        <ReportUserModal
          toggle={this.toggle}
          modalOpen={this.state.ReportUserModalOpen}
        />

        <Row style={{ marginTop: "20px" }}>
          <Col sm="7" style={{ position: "relative" }}>
            <div onClick={this.goBack} className="backbutton">
              <FontAwesomeIcon
                icon={faArrowLeft}
                size="lg"
                className="goback-icon"
              />
            </div>
            <div
              onClick={() => this.toggle("reportusermodal")}
              className="reportbutton"
            >
              <FontAwesomeIcon
                icon={faFlag}
                size="lg"
                className="report-icon"
              />
            </div>
            <Carousel
              sm="12"
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
            >
              <CarouselIndicators
                className="view-carousel-indicators mx-auto"
                items={this.state.media}
                activeIndex={activeIndex}
                onClickHandler={this.goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={this.next}
              />
            </Carousel>

            <Row className="mx-0 p-5">
              <Col sm="12">
                {this.state.ratings.length > 0 ? (
                  this.state.ratings.map((item, i) => {
                    let Rating = () => {
                      let stars = [];
                      for (let i = 0; i < item.score; i++) {
                        stars.push(
                          <FontAwesomeIcon
                            icon={faStar}
                            className="checked fa-star"
                          />
                        );
                      }
                      return stars;
                    };

                    return (
                      <Card sm="12" className="user-review-card py-3 px-0">
                        <CardHeader
                          sm="12"
                          className="card-review-header d-flex flex-row align-items-center"
                        >
                          <div className="card-review-img col-sm-1 d-flex justify-content-center align-items-center">
                            <img
                              width="100%"
                              src={item.profileImage}
                              alt="item img"
                            />
                          </div>
                          <div className="col-sm-11">
                            <div className="green-txt">
                              <Rating />
                            </div>
                            <div className="card-review-user">
                              {item.firstName} {item.lastName}
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody className="text-muted dashboard-card-body">
                          <CardText className="dashboard-card-text">
                            <div>{item.description}</div>
                          </CardText>
                        </CardBody>
                      </Card>
                    );
                  })
                ) : (
                  <Card sm="12" className="user-review-card py-3 px-0">
                    <CardHeader
                      sm="12"
                      className="card-review-header d-flex flex-row align-items-center"
                    >
                      <div className="col-sm-11">
                        <div className="green-txt" />
                        <div className="card-review-user">No Ratings</div>
                      </div>
                    </CardHeader>
                  </Card>
                )}
              </Col>
            </Row>
          </Col>

          <Col sm="5" style={{ backgroundColor: "#FCFCFC" }}>
            <Row>
              <Col sm="12" className="post-info-main-container">
                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container main-one">
                    <div className="left-side">
                      <img
                        className="left-side-pf"
                        src={this.state.profilePic}
                        alt="Profile"
                      />
                    </div>
                    <div className="right-side">
                      <div className="right-side-one">
                        <h4 className="post-title">
                          {this.state.equipmentName}
                        </h4>
                        <p className="post-title-username">
                          {" "}
                          <Link
                            to={{
                              pathname: `/profile/${this.state.postUserId}`,
                              state: {
                                userId: this.state.postUserId,
                                messagable: true,
                              },
                            }}
                          >
                            {" "}
                            {this.state.postUserName}
                          </Link>{" "}
                        </p>
                      </div>

                      <div className="right-side-two ratings-in-info">
                        <div
                          className="col-sm-11"
                          style={{ textAlign: "right", paddingRight: 0 }}
                        >
                          <div className="green-txt">
                            <Rating />
                          </div>
                          <div
                            className="card-review-user"
                            style={{ color: "#8AC53F", paddingRight: 8 }}
                          >
                            {this.state.numOfReviews}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../Common/images/newicons/icon_info.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Description </h4>

                      <div
                        className="post-title-username post-info-block"
                        style={{ padding: "0 20px 10px 0" }}
                      >
                        <div className="rates-container">
                          <p className="rateFreq">Make</p>
                          <p className="ratePrice">{this.state.make}</p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Model</p>
                          <p className="ratePrice">{this.state.model}</p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Year</p>
                          <p className="ratePrice">{this.state.year}</p>
                        </div>
                      </div>

                      <p className="post-title-username">
                        {" "}
                        {this.state.description}{" "}
                      </p>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../Common/images/newicons/icon_specifications.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Specifications </h4>
                      {this.state.specifications}
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        style={{ width: "90%" }}
                        src={require("../../Common/images/newicons/icon_rates.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Rates </h4>
                      <div
                        className="post-title-username post-info-block"
                        style={{ padding: "10px 0 10px" }}
                      >
                        <div className="rates-container">
                          <p className="rateFreq">Daily</p>
                          <p className="ratePrice">
                            ${this.state.dailyRate}/day
                          </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Weekly</p>
                          <p className="ratePrice">
                            ${this.state.weeklyRate}/week
                          </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Monthly</p>
                          <p className="ratePrice">
                            ${this.state?.monthlyRate}/month
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../Common/images/newicons/icon_comments.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Comments </h4>
                      <p className="post-title-username">
                        {this.state.comments}
                      </p>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../Common/images/newicons/icon_calendar.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Rental Date </h4>

                      <div className="rental-date-container">
                        <p className="post-title-username">Start Date </p>
                        <span className="start-date">
                          {this.state.startDate}
                        </span>
                      </div>

                      {this.state.openEndedRentalStatus === 0 ||
                      this.state.confirmedAt !== null ? (
                        <div className="rental-date-container">
                          <p className="post-title-username">End Date </p>
                          <span className="start-date">
                            {this.state.endDate}
                          </span>
                        </div>
                      ) : (
                        <div className="rental-date-container">
                          <p className="post-title-username">Open ended </p>
                        </div>
                      )}
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../Common/images/newicons/icon_location.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">
                        Pickup Or Delivery Location
                      </h4>

                      {/* <SimpleMap></SimpleMap> */}
                      <div
                        className="my-map-container"
                        style={{ position: "relative" }}
                      >
                        {!this.state.loading && (
                          <Map
                            places={[this.state.location]}
                            renting={true}
                            theaddress={this.state.location.address}
                            center={this.state.location.geometry.location}
                          />
                        )}
                      </div>
                      <div className="pickup-or-delivary-container">
                        <span className="post-title">
                          {this.state.pickupDilivery}
                        </span>
                        <div className="pickup-address-container">
                          <span>{this.state.location.address}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../Common/images/newicons/icon_return.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Return </h4>
                      <div
                        className="post-title-username post-info-block"
                        style={{ padding: "10px 0 10px" }}
                      >
                        <div className="rates-container">
                          <p className="rateFreq">Return Address </p>
                          <div className="pickup-address-container">
                            <span>{this.state.location.returnAddress}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                {this.state?.insuranceApprovalStatusCode !==
                  "no_insurance_required" && (
                  <Row style={{ padding: 10 }}>
                    <div className="container post-info-container">
                      <div className="left-side newicons">
                        <svg
                          width="33"
                          height="33"
                          viewBox="0 0 42 54"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.0001 36.0005H24.0001V28.5004H31.5001V22.5004H24.0001V15.0004H18.0001V22.5004H10.5001V28.5004H18.0001V36.0005ZM21.0001 53.885C14.9731 52.0965 9.96637 48.4454 5.97987 42.9319C1.99337 37.4184 0.00012207 31.2079 0.00012207 24.3004V8.0772L21.0001 0.231445L42.0001 8.0772V24.3004C42.0001 31.2079 40.0069 37.4184 36.0204 42.9319C32.0339 48.4454 27.0271 52.0965 21.0001 53.885ZM21.0001 50.7005C26.2001 49.0504 30.5001 45.7505 33.9001 40.8004C37.3001 35.8504 39.0001 30.3504 39.0001 24.3004V10.1254L21.0001 3.4332L3.00012 10.1254V24.3004C3.00012 30.3504 4.70012 35.8504 8.10012 40.8004C11.5001 45.7505 15.8001 49.0504 21.0001 50.7005Z"
                            fill="#4B4B4B"
                          />
                        </svg>
                      </div>
                      <div className="right-side">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <h4 className="post-title">Insurance</h4>
                          <div className="insurance-status">
                            {this.state?.insuranceApprovalStatusCode ===
                              "awaiting_approval" && (
                              <div className="awaiting-approval">
                                {this.state?.insuranceApprovalStatus}
                              </div>
                            )}
                            {this.state?.insuranceApprovalStatusCode ===
                              "awaiting_insurance_file" && (
                              <div className="awaiting-insurance">
                                {this.state?.insuranceApprovalStatus}
                              </div>
                            )}
                            {this.state?.insuranceApprovalStatusCode ===
                              "insurance_rejected" && (
                              <div className="insurance-rejected">
                                {this.state?.insuranceApprovalStatus}
                              </div>
                            )}
                            {this.state?.insuranceApprovalStatusCode ===
                              "insurance_approved" && (
                              <div className="insurance-approved">
                                {this.state?.insuranceApprovalStatus}
                              </div>
                            )}
                          </div>
                        </div>

                        {(this.state?.insuranceApprovalStatusCode ===
                          "insurance_rejected" ||
                          this.state?.insuranceApprovalStatusCode ===
                            "awaiting_insurance_file") &&
                        this.state.type !== "rentingOut" &&
                        !this.state?.fileName ? (
                          <div
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {
                              e.preventDefault();
                              const file = e.dataTransfer.files[0];
                              this.setState({ selectedFile: file }, () => {
                                this.uploadPdf();
                              });
                              if (file && allowedTypes.includes(file.type)) {
                                console.log("File Selected");
                              } else {
                                alert("Please upload JPG, PNG or PDF file.");
                              }
                            }}
                            className="upload-insurance-document"
                          >
                            <svg
                              width="32"
                              height="40"
                              viewBox="0 0 32 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.9167 32.5H17.0834V22.3292L21.6334 26.8792L23.1668 25.3333L16 18.1665L8.83325 25.3333L10.3792 26.8667L14.9167 22.3292V32.5ZM4.33362 39.5C3.33623 39.5 2.50351 39.166 1.83546 38.4979C1.1674 37.8299 0.833374 36.9971 0.833374 35.9998V4.00025C0.833374 3.00286 1.1674 2.17014 1.83546 1.50208C2.50351 0.834028 3.33623 0.5 4.33362 0.5H21.4167L31.1667 10.25V35.9998C31.1667 36.9971 30.8327 37.8299 30.1646 38.4979C29.4966 39.166 28.6638 39.5 27.6665 39.5H4.33362ZM20.3334 11.3333V2.66667H4.33362C3.99996 2.66667 3.69428 2.80551 3.41658 3.08321C3.13889 3.3609 3.00004 3.66658 3.00004 4.00025V35.9998C3.00004 36.3334 3.13889 36.6391 3.41658 36.9168C3.69428 37.1945 3.99996 37.3333 4.33362 37.3333H27.6665C28.0001 37.3333 28.3058 37.1945 28.5835 36.9168C28.8612 36.6391 29 36.3334 29 35.9998V11.3333H20.3334Z"
                                fill="#8AC53F"
                              />
                            </svg>

                            <div className="rateFreq ml-4">
                              <div
                                style={{
                                  fontWeight: 600,
                                  color: "#000",
                                }}
                              >
                                Drag & Drop your files
                              </div>
                              <div
                                style={{
                                  fontSize: 13,
                                  color: "#A9B5C1",
                                }}
                              >
                                JPG, PNG or PDF, smaller than 10mb
                              </div>
                            </div>
                            <div>
                              <input
                                type="file"
                                ref={this.fileInputRef}
                                style={{ display: "none" }}
                                onChange={this.handleFileChange}
                              />
                              <Button
                                onClick={this.handleButtonClick}
                                className="post-page-continue postview-submitbutton"
                              >
                                Browse files
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="rateFreq ml-4">
                                <div
                                  style={{
                                    fontWeight: 600,
                                    color: "#000",
                                  }}
                                >
                                  {this.state?.fileName}
                                </div>
                                {/* <div
                                  style={{
                                    fontSize: 13,
                                    color: "#A9B5C1",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      pdf: this.state?.insurancePDFPath,
                                    })
                                  }
                                >
                                  View
                                </div> */}
                              </div>
                              {this.state?.insurancePDFPath && (
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      pdf: this.state?.insurancePDFPath,
                                    })
                                  }
                                  className="post-page-continue postview-submitbutton"
                                >
                                  View Insurance
                                </Button>
                              )}
                            </div>
                            {this.state?.type !== "rentingOut" && (
                              <Button
                                onClick={() => this.handleInsurance()}
                                className="post-page-continue postview-submitbutton mt-2"
                              >
                                Save
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Row>
                )}

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../Common/images/newicons/icon_price.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Total </h4>
                      <div
                        className="post-title-username post-info-block"
                        style={{ padding: "10px 0 10px" }}
                      >
                        <div className="rates-container total-amout-container">
                          <p
                            className="rateFreq"
                            style={{ fontWeight: 600, color: "#000" }}
                          >
                            Total Amount{" "}
                          </p>
                          <p
                            className="ratePrice"
                            style={{ color: "#8AC53F", fontWeight: 600 }}
                          >
                            ${this.state.total.toFixed(2)}
                          </p>
                        </div>
                        <div className="rates-container">
                          <p style={{ color: "rbg(177,177,177)" }}>
                            You must provide at least 24 hours notice prior to
                            the start of your rental to avoid a penalty. If you
                            choose to cancel within the 24 hours preceding your
                            rental, you will be charged one daily rate of all
                            items requested.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div>
                    <h4>Add Ons</h4>
                  </div>
                  <div className="container post-info-container">
                    <div className="addons-side-right">
                      <div className="addon-cards-container0">
                        <div className="addon-cards-container">
                          {this.state.listOfAddOns &&
                          this.state.listOfAddOns.length > 0 ? (
                            this.state.listOfAddOns.map((addon) => {
                              return (
                                <Card style={{ width: "300px" }}>
                                  <CardImg
                                    top
                                    width="100%"
                                    src={addon.equipmentMedias[0].filePath}
                                    alt="Card image cap"
                                  />
                                  <span className="price-on-addons">
                                    ${addon.dailyRate}/day
                                  </span>
                                  <CardBody>
                                    <CardTitle>
                                      {addon.name} {addon.make} {addon.model}
                                    </CardTitle>
                                  </CardBody>
                                </Card>
                              );
                            })
                          ) : (
                            <div>No Add ons</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10, margin: "90px 0 20px 0" }}>
                  {this.state.type == "renting" ? (
                    <div style={{ width: "100%" }}>
                      {!this.state.confirmedAt &&
                      !this.state.refundedAt &&
                      this.state.isActive ? (
                        <Button
                          onClick={() => this.toggle("extendRental")}
                          block
                          className="post-page-continue postview-submitbutton"
                        >
                          Extend Rental
                        </Button>
                      ) : null}
                      {!this.state.confirmedAt &&
                      !this.state.refundedAt &&
                      this.state.allowCancel ? (
                        <Button
                          onClick={() => this.cancelRental()}
                          block
                          className="post-page-cancel postview-submitbutton"
                        >
                          Cancel
                        </Button>
                      ) : null}
                      {this.state.confirmedAt && (
                        <Button
                          onClick={() => this.createPdf()}
                          block
                          className="post-page-continue postview-submitbutton"
                        >
                          View Receipt
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      {!this.state.confirmedAt && !this.state.refundedAt ? (
                        <Button
                          onClick={() => this.returnRental()}
                          block
                          className="post-page-continue postview-submitbutton"
                        >
                          Returned
                        </Button>
                      ) : null}
                      {this.state.isActive ? (
                        <Button
                          onClick={() => this.changeActiveRental()}
                          block
                          className="post-page-cancel postview-submitbutton"
                        >
                          Out of Service
                        </Button>
                      ) : (
                        <Button
                          onClick={() => this.changeActiveRental()}
                          block
                          className="post-page-continue postview-submitbutton"
                        >
                          Activate
                        </Button>
                      )}
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <PDF
          link={this.state.pdf}
          fileName={this.state?.fileName}
          close={() => this.setState({ pdf: "" })}
          type={this.state?.type}
          id={this.props.match.params.id}
          getRentalCall={this.getRentalCall}
        />
      </Col>
    );
  }
}

ViewRentingPost.propTypes = {
  postImageUrl: PropTypes.string,
  postName: PropTypes.string,
  postUser: PropTypes.string,
  postRating: PropTypes.number,
  postId: PropTypes.number,
  postUserId: PropTypes.number,
};

export default ViewRentingPost;
