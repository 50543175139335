import $ from "jquery";

export function getCategories() {
  return $.ajax({
    method: "GET",
    dataType: "json",
    data: { Page: 0, PerPage: 15 },
    traditional: true,
    xhrFields: {
      withCredentials: true
    },
    url: process.env.REACT_APP_API+"/categories"
  }).then(res => res.data);
}
