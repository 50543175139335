import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  // ModalFooter,
  // Row,
  Col,
  Form,
  FormGroup
  // Label,
  // Input,
  // FormText
} from "reactstrap";
import PropTypes from "prop-types";

import "./style.css";

class SuccessEmailReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  backToLogin = () => {
    this.props.toggle("SuccessEmailReset");
    this.props.toggle("login");
  };
  render() {
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("SuccessEmailReset")}
      >
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="about-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("SuccessEmailReset")}
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B", paddingLeft: "60px" }}
          >
            Nubosh
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col sm={12}>
                  <h3>Password reset email sent! Please check your inbox. </h3>
                </Col>
              </FormGroup>
              <Button
                className="loginbutton"
                onClick={() => this.backToLogin()}
                size="lg"
                block
              >
                Back to Login
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

SuccessEmailReset.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string
};

export default SuccessEmailReset;
