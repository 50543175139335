import React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Col,
    Form,
    FormGroup
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "./style.css";

class FeedbackSuccessModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <Modal className="about-modal" isOpen={this.props.modalOpen}>
                    <ModalHeader toggle={this.toggle} style={{ background: "#3E3F9B" }}>
                        Rating Submitted
          </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Col sm={12}>
                                    <p style={{ padding: "10px 20px", textAlign: "center" }}>
                                        Thank you!
                  </p>
                                </Col>
                                <Link
                                    className="loginbutton btn btn-secondary btn-lg btn-block"
                                    to="/"
                                    size="md"
                                    block
                                >
                                    Continue
                </Link>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

FeedbackSuccessModal.propTypes = {
    modalOpen: PropTypes.bool,
    toggle: PropTypes.func,
    size: PropTypes.string
};

export default FeedbackSuccessModal;
