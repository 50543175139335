import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardHeader,
  CardImg,
  CardBody,
  CardSubtitle,
  CardText,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import $ from "jquery";
import { getPaymentInfo } from "../../../../App/Api/getPaymentInfo";
import PDF from "./PDF";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

// import SimpleMap from '../post-map';
import Map from "../GoogleAutocomplete";

// import ReactCustomGoogleAutocomplete from '../GoogleAutocomplete';

import Switch from "react-switch";

import Timeslider from "../Timeslider";

import RentalPdfModal from "../../../../Common/Modals/RentalPdfModal";
import PaymentSuccessModal from "../../../../Common/Modals/PaymentSuccessModal";

import RentalHistory from "./RentalHistory";
import "react-input-range/lib/css/index.css";

import "../GoogleAutocomplete/map.css";
import "./style.css";
import _ from "lodash";

import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
toast.configure();

export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      checked: false,
      checked2: false,
      RentalPdfModalOpen: false,
      PaymentSuccessModalOpen: false,
      userDetails: {},
      itemDetails: [],
      addOnDetails: [],
      itemRating: 4,
      startDate: null,
      startTime: "00:00",
      endDate: null,
      endTime: "00:00",
      startDateAddOn: null,
      endDateAddOn: null,
      totalRentalAmount: 0,
      rentalAmount: 0,
      rentalDeliveryAmount: 0,
      rentalGst: 0,
      itemDetailsId: null,
      isOpenEndedRental: false,
      deliveryLocationAddress: null,
      deliveryLocationLatitude: 0,
      deliveryLocationLongitude: 0,
      postId: null,
      postUserId: null,
      addOnIds: [],
      rentalDates: [],
      addOnTemp: [],
      dateErrors: false,
      disabledDateError: false,
      agreement: null,
      isPaymentButtonDisabled: true,
      isTermsButtonDisabled: true,
      paymentResponse: "",
      calculateTotalError: false,
      emptyAddressError: false,
      reviewsCount: [],
      messagable: false,
      showEndDate: true,
      paymentInfo: {},
      userEmail: "",
      minimumRentalPeriod: 0,
      selectedFile: null,
      insuranceDoc: "",
      pdf: "",
      fileName: "",
      insurancePDFPath: "",
      months: 0,
      weeks: 0,
      days: 0,
      rentalSelectionText: "",
    };

    this.fileInputRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.modaltoggle = this.modaltoggle.bind(this);
  }

  handleFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] }, () => {
      this.uploadPdf();
    });
  };
  handleButtonClick = () => {
    this.fileInputRef.current.click();
  };
  handleFileUpload = () => {
    const { selectedFile } = this.state;
    if (selectedFile) {
      this.uploadPdf();
    } else {
      console.log("No file selected.");
    }
  };

  deleteFile = () => {
    this.setState({ fileName: "" });
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
    }
  };

  uploadPdf = (e) => {
    let vm = this;
    const fd = new FormData();
    fd.append("file", this.state.selectedFile);
    fd.append("public", true);
    let data = {
      File: this.state.selectedFile,
      Public: true,
    };
    let dataFileName = {
      File: this.state.selectedFile.name,
    };
    console.log("upload PDF", data);
    console.log("upload PDF Name", data.File.name);
    console.log("file name dataFileName", dataFileName);

    $.ajax({
      method: "POST",
      processData: false,
      contentType: false,
      data: fd,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/uploads/uploadpdf",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then(function (res) {
        console.log("file path", res.data.filePath);
        vm.setState({
          insurancePDFPath: res.data.filePath,
          fileName: dataFileName.File,
          uploadedSuccess: "uploaded",
        });
        console.log("state file path", vm.state.insuranceDoc);
        console.log("state file nam", vm.state.fileName);
      })
      .catch(function (res) {
        console.log("file failed");
        vm.setState({
          uploadedSuccess: "Failed to upload",
        });
      });
  };

  handleChange(checked) {
    let vm = this;
    if (checked === "1") {
      this.setState({ checked: !this.state.checked });
      this.setState({ checked2: false });
      this.setState(
        {
          startTime: this.props.postInfo.startTime,
          endTime: this.props.postInfo.endTime,
          deliveryLocationAddress: null,
        },
        () => {
          console.log(vm.state.startDate, vm.state.endDate);
          if (
            vm.state.startDate !== null &&
            (vm.state.endDate !== null || vm.state.isOpenEndedRental)
          ) {
            console.log("CALLLL INSIDE CHANGE CHECK");
            vm.calculateTotal();
          } else {
            console.log("NOCALL");
          }
        }
      );
    } else if (checked === "2") {
      this.setState({ checked2: !this.state.checked2 });
      this.setState({ checked: false });
    }
  }

  handleChange2(checked) {
    this.setState({ checked });
  }

  handleChange4 = (isOpenEndedRental) => {
    this.setState({ isOpenEndedRental: !this.state.isOpenEndedRental }, () => {
      if (this.state.isOpenEndedRental) {
        this.setState({ showEndDate: false, endDate: null });
        if (
          this.state.startDate &&
          (this.state.checked || this.state.checked2)
        ) {
          this.calculateTotal();
        }
      } else {
        this.setState({ showEndDate: true });
      }
    });
  };

  modaltoggle(type) {
    if (type == "rentalpdf") {
      this.setState((prevState) => ({
        RentalPdfModalOpen: !prevState.RentalPdfModalOpen,
      }));
    }
    if (type == "paymentSuccess") {
      this.setState((prevState) => ({
        PaymentSuccessModalOpen: !prevState.PaymentSuccessModalOpen,
      }));
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  // Rating() {
  //   let stars = [];
  //   for (let i = 0; i < this.state.itemDetails.score; i++) {
  //     //for(let i = 0; i < this.state.itemRating; i++){
  //     stars.push(<FontAwesomeIcon icon={faStar} className="checked fa-star" />);
  //   }
  //   return stars;
  // }

  componentWillMount() {
    this.setState({
      postId: this.props.postId,
      postUserId: this.props.postUserId,
      companyName: null,
    });
  }

  componentDidMount() {
    const vm = this;

    if (this.props.postInfo) {
      let addOnObj = this.props.postInfo.addOns.map((addOnElement) => {
        vm.getAddOnsDates(addOnElement);
      });

      let startTime = this.props.postInfo.startTime.split(":");
      let startHour = startTime[0];

      let startDate = moment
        .utc(this.props.postInfo.startDate)
        .add(startHour, "hours")
        .local()
        .format("ddd, MMM  DD, YYYY, hh:mm A");
      let startTimeString = startDate;

      let endTime = this.props.postInfo.endTime.split(":");
      let endHour = endTime[0];
      let endDate = moment
        .utc(this.props.postInfo.endDate)
        .add(endHour, "hours")
        .local()
        .format("ddd, MMM  DD, YYYY, hh:mm A");
      let endTimeString = endDate;

      this.setState(
        {
          reviewsCount: this.props.postInfo.ratings,
          userDetails: this.props.postInfo.user,
          itemDetails: this.props.postInfo,
          startTime: this.props.postInfo.startTime,
          endTime: this.props.postInfo.endTime,
          companyName: this.props.postInfo.user.companyName,
          // isOpenEndedRental: this.props.isOpenEndedRental,
          // endDate: endDate,
          // startDate: startDate,
          messagable: this.props.postInfo.isRentedOut,
          startTimeString: startTimeString,
          endTimeString: endTimeString,
          //addOnDetails: addOnObj,
          itemDetailsId: this.props.postInfo.id,
          agreement: this.props.postInfo.user.agreement.filePath,
          minimumRentalPeriod: this.props.minimumRentalPeriod,
        },
        () => {
          localStorage.getItem("accessToken") && vm.getRentalDates();
        }
      );
      getPaymentInfo().then((res) => {
        vm.setState({ paymentInfo: res });
      });
    }
  }

  getRentalDates = () => {
    const vm = this;
    let product = {
      equipmentId: vm.state.itemDetailsId,
    };
    $.ajax({
      method: "POST",
      dataType: "json",
      traditional: true,
      contentType: "application/json",
      data: JSON.stringify(product),
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/rentals/availability",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then((res) => {
        let allDates = [];
        var today = moment();
        res.data.rentalDates.map((element) => {
          let obj = {
            after: moment(element.startDate).subtract("day", 1).toDate(),
            before: moment(element.endDate).add("day", 1).toDate(),
          };
          allDates.push(obj);
        });
        vm.setState((prevState) => ({
          rentalDates: allDates,
        }));
      })
      .catch(function (res) {
        console.log("get Rental Dates Error");
        console.log(res);
      });
  };

  process(data) {
    console.log(data);
  }

  getAddOnsDates = (addon) => {
    const vm = this;
    let product = {
      equipmentId: addon.id,
    };
    console.log(product);

    $.ajax({
      method: "POST",
      dataType: "json",
      processData: false,
      traditional: true,
      contentType: "application/json",
      data: JSON.stringify(product),
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/rentals/availability",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
      success: function (res) {
        vm.process(res);
      },
    }).then((res) => {
      let obj = {
        addOnObjImg: addon.equipmentMedias[0].filePath,
        addOnObjName: addon.name,
        addOnObjDaily: addon.dailyRate,
        addOnObjId: addon.id,
        addOnObjDate: res.data.rentalDates,
        selected: false,
        addOnminStartDate: addon.startDate,
        addOnmaxEndDate: addon.endDate,
      };
      console.log(obj);

      obj.addOnObjDate.forEach((element) => {
        vm.setState({
          startDateAddOn: element.startDate,
          endDateAddOn: element.endDate,
        });
      });

      vm.setState((prevState) => ({
        addOnDetails: [...prevState.addOnDetails, obj],
      }));
    });
  };

  ChangeDate = (e) => {
    console.log("da date");
    console.log(this.state.startDate);
    let startdate_utc = moment.utc(e._d).set("hour", 0).format();
    console.log(startdate_utc);
    this.setState(
      {
        startDate: startdate_utc,
      },
      () => {
        this.CheckDateLogic();
      }
    );
  };

  callbackFunction = (value, type) => {
    console.log(value, type);
    if (type == "startTime") {
      this.setState({ startTime: value });
    } else if (type == "endTime") {
      this.setState({ endTime: value });
    }
    // this.setState({ minTimeValue: childMin, maxTimeValue: childMax })
  };

  ChangeTime = (e) => {
    console.log("da time");
    console.log(e._d);
    let starttime_utc = moment.utc(e._d).format("HH:mm");
    console.log(starttime_utc);

    this.setState({
      startTime: String(starttime_utc),
    });
  };

  handleStartDayChange = (selectedDay, modifiers, dayPickerInput) => {
    const vm = this;
    const input = dayPickerInput.getInput();
    vm.setState(
      {
        startDate: selectedDay,
        addOnIds: [],
      },
      () => {
        vm.CheckDateLogic();
        if (
          vm.state.endDate !== null &&
          (vm.state.checked || vm.state.checked2) &&
          vm.state.dateErrors === false &&
          vm.state.disabledDateError === false
        ) {
          console.log("CALLLL");
          vm.calculateTotal();
        } else {
          console.log("NOCALL");
        }
      }
    );
  };

  handleEndDayChange = (selectedDay, modifiers, dayPickerInput) => {
    const vm = this;
    //const input = dayPickerInput.getInput();
    vm.setState(
      {
        endDate: selectedDay,
        addOnIds: [],
      },
      () => {
        vm.CheckDateLogic();
        console.log();
        if (
          vm.state.startDate !== null &&
          (vm.state.checked || vm.state.checked2) &&
          vm.state.dateErrors === false &&
          vm.state.disabledDateError === false
        ) {
          console.log("CALLLL");
          vm.calculateTotal();
        } else {
          console.log("NOCALL");
        }
      }
    );
  };

  getDates = (startDate, endDate) => {
    const dates = [];

    // Strip hours minutes seconds etc.
    let currentDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );

    while (currentDate <= endDate) {
      dates.push(currentDate);

      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1 // Will increase month if over range
      );
    }
    return dates;
  };

  findIfMatch = (haystack, arr) => {
    var i = 0; //array index
    var j = 0; //array index
    while (j < arr.length && i < haystack.length) {
      let cur_cal = Date.parse(haystack[i]);
      let cur_match = Date.parse(arr[j]);
      if (cur_cal > cur_match) {
        j++;
      } else if (cur_cal < cur_match) {
        i++;
      } else {
        console.log("TRUE");
        this.setState({
          disabledDateError: true,
          isTermsButtonDisabled: true,
          isPaymentButtonDisabled: true,
        });
        return true;
      }
    }
    console.log("FALSE");
    this.setState({ disabledDateError: false });
    return false;
  };

  CheckDateLogic() {
    const vm = this;
    let bannedDates = this.state.rentalDates;
    bannedDates.push(
      {
        after: moment(this.props.maxEndDate).toDate(),
        before: moment(this.props.minStartDate).toDate(),
      },
      { before: new Date() }
    );
    let availableDates = [];
    bannedDates.map((element) => {
      let dates = vm.getDates(
        moment(element.after).add("day", 1).toDate(),
        moment(element.before).subtract("day", 1).toDate()
      );
      dates.map((d) => {
        availableDates.push(d);
      });
    });

    if (this.state.endDate && this.state.startDate) {
      let start = new Date(this.state.startDate);
      let end = new Date(this.state.endDate);

      let b = vm.getDates(start, end);

      this.findIfMatch(availableDates, b);

      if (start <= end) {
        console.log("LOGIC TRUE");
        this.setState({ dateErrors: false, calculateTotalError: false });
      } else {
        // end date is earlier then start date
        this.setState({ dateErrors: true });
      }

      this.state.addOnDetails.map((addon, i) => {
        console.log("THIS IS THE START ", addon);

        if (addon.selected && addon.addOnObjDate.length > 0) {
          if (
            this.state.startDate <= addon.addOnObjDate[0].startDateAddOn ||
            this.state.endDate >= addon.addOnObjDate[0].endDateAddOn ||
            moment(addon.addOnminStartDate) <=
              moment(this.state.startDate).toDate() ||
            moment(addon.addOnmaxEndDate) >= moment(this.state.endDate).toDate()
          ) {
            this.addAddon(addon.id, i);
            // addon.selected = !addon.selected;
            this.setState({
              addOnIds: [],
            });
            console.log(addon, "THE FINISHED OBJ");
          }
        }
      });
    }
  }

  ChangeTimeEnd = (e) => {
    console.log("da time");
    console.log(e._d);
    let endtime_utc = moment.utc(e._d).format("HH:mm");
    console.log(endtime_utc);

    this.setState({
      endTime: String(endtime_utc),
    });
  };

  getlatlong = (latLng) => {
    this.setState({
      pickupLocationLatitude: latLng.lat,
      pickupLocationLongitude: latLng.lng,
    });
  };

  getlatlongDeliver = (latLng) => {
    this.setState(
      {
        deliveryLocationLatitude: latLng.lat,
        deliveryLocationLongitude: latLng.lng,
      },
      () => {
        if (
          this.state.startDate !== null &&
          (this.state.checked || this.state.checked2) &&
          this.state.dateErrors === false &&
          this.state.disabledDateError === false
        )
          this.calculateTotal();
      }
    );
  };

  theaddressDeliver = (add) => {
    const vm = this;
    vm.setState({
      deliveryLocationAddress: add,
    });
  };

  treatAsUTC = (date) => {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
  };

  daysBetween = (startDate, endDate) => {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (
      (this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) /
        millisecondsPerDay +
      1
    );
  };

  addAddon = (elementID, index) => {
    console.log(elementID);
    console.log("addondetails", this.state.addOnDetails);
    let addons = this.state.addOnDetails;
    let selected = this.state.addOnIds;

    addons[index].selected = !addons[index].selected;
    if (this.state.addOnIds.length > 0) {
      console.log(selected.includes(elementID));
      if (this.state.addOnIds.includes(elementID)) {
        selected = selected.filter((ele) => ele !== elementID);
      } else {
        selected.push(elementID);
      }
    } else {
      selected.push(elementID);
    }
    console.log("Checking select", selected);
    this.setState({ addOnIds: selected });
  };

  updateRentalSelectionText = (months, weeks, days) => {
    let rentalSelectionText = "";
    if (months > 0) {
      rentalSelectionText += `${months} month${months === 1 ? "" : "s"}`;
    }
    if (weeks > 0) {
      if (rentalSelectionText) {
        rentalSelectionText += months > 0 ? ", " : "";
      }
      rentalSelectionText += `${weeks} week${weeks === 1 ? "" : "s"}`;
    }
    if (days > 0) {
      if (rentalSelectionText) {
        rentalSelectionText += months > 0 || weeks > 0 ? ", and " : "";
      }
      rentalSelectionText += `${days} day${days === 1 ? "" : "s"}`;
    }
    if (!rentalSelectionText) {
      rentalSelectionText = "No rental period specified";
    }
    this.setState({ rentalSelectionText, months, weeks, days });
  };

  calculateTotal = () => {
    const vm = this;

    let add = this.state.deliveryLocationAddress;
    if (add === null && this.state.checked2) {
      vm.setState({
        emptyAddressError: true,
      });
    } else {
      vm.setState({
        emptyAddressError: false,
      });
    }

    let startDate = moment.utc(vm.state.startDate).set("hour", 0).format();
    let endDate = moment.utc(vm.state.endDate).set("hour", 20).format();
    console.log(startDate, endDate);

    let myData = {
      equipmentId: vm.state.itemDetailsId,
      addOnIds: vm.state.addOnIds,
      isOpenEndedRental: vm.state.isOpenEndedRental,
      startDate: startDate,
      endDate: endDate,
      startTime: vm.state.startTime,
      endTime: vm.state.endTime,
      deliveryLocationAddress: add,
      deliveryLocationLatitude: this.state.deliveryLocationLatitude,
      deliveryLocationLongitude: this.state.deliveryLocationLongitude,
    };
    if (vm.state.isOpenEndedRental) {
      delete myData.endDate;
      delete myData.endTime;
    }

    if (
      this.state.dateErrors ||
      this.state.disabledDateError ||
      this.state.calculateTotalError
    ) {
      vm.setState({
        calculateTotalError: true,
      });
    } else {
      vm.setState({
        calculateTotalError: false,
      });
      $.ajax({
        method: "POST",
        dataType: "json",

        traditional: true,
        data: JSON.stringify(myData),
        xhrFields: {
          withCredentials: true,
        },

        url: process.env.REACT_APP_API + "/rentals/calculate",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(function (res) {
          console.log("********* POST TEST **********");
          console.log(res.data);
          vm.setState({
            totalRentalAmount: res.data.total.toFixed(2),
            rentalAmount: res.data.rentalAmount.toFixed(2),
            rentalGst: res.data.gst.toFixed(2),
            rentalDeliveryAmount: res.data.deliveryAmount.toFixed(2),
            isTermsButtonDisabled: false,
          });
          vm.updateRentalSelectionText(
            res?.data?.months,
            res?.data?.weeks,
            res?.data?.days
          );
        })
        .catch(function (res) {
          console.log(res);
        });
    }
  };

  handleToken = (token) => {
    const vm = this;
    let utcStartTime = vm.state.startTime;
    let utcEndTime = vm.state.endTime;
    let startHour;
    let endHour;

    if (typeof vm.state.startTime == "string") {
      let startTime = vm.state.startTime.split(":");
      startHour = startTime[0];
      let endTime = vm.state.endTime.split(":");
      endHour = endTime[0];
    } else {
      startHour = vm.state.startTime;
      endHour = vm.state.endTime;
    }

    let startDate = moment(this.state.startDate)
      .set("hour", 0)
      // .add(startHour, "hours")
      .format();
    let endDate = moment(this.state.endDate)
      .set("hour", 20)
      // .add(endHour, "hours")
      .format();

    let finishedStartDate = startDate;
    let finishedEndDate = endDate;

    let product = {
      // stripeToken: token.id,
      equipmentId: this.state.itemDetailsId,
      addOnIds: this.state.addOnIds,
      startDate: finishedStartDate,
      startTime: utcStartTime,
      endDate: finishedEndDate,
      endTime: utcEndTime,
      isOpenEndedRental: this.state.isOpenEndedRental,
      deliveryLocationAddress: this.state.deliveryLocationAddress,
      deliveryLocationLatitude: this.state.deliveryLocationLatitude,
      deliveryLocationLongitude: this.state.deliveryLocationLongitude,
    };

    if (
      this.props.postInfo?.isInsuranceRequired &&
      this.state.insurancePDFPath
    ) {
      product.insurancePDFPath = this.state.insurancePDFPath;
    }

    if (this.state.isOpenEndedRental) {
      delete product.endDate;
      delete product.endTime;
    }
    console.log(product);

    $.ajax({
      method: "POST",
      dataType: "json",
      processData: false,
      traditional: true,
      contentType: "application/json",
      data: JSON.stringify(product),
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/rentals/purchase",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then(function (res) {
        console.log(res);
        vm.setState({
          paymentResponse: res.code,
        });
        if (vm.state.paymentResponse === "SUCCESS") {
          vm.setState({
            PaymentSuccessModalOpen: true,
            isPaymentButtonDisabled: true,
          });
        } else {
          alert("Payment Unsuccessful");
        }
      })
      .catch(function (res) {
        console.log(res);
        alert("Payment Unsuccessful");
      });
  };

  savePaymentInfo = (token) => {
    const vm = this;

    let product = {
      stripeToken: token.id,
    };

    $.ajax({
      method: "PUT",
      dataType: "json",
      processData: false,
      traditional: true,
      contentType: "application/json",
      data: JSON.stringify(product),
      xhrFields: {
        withCredentials: true,
      },
      url: `${process.env.REACT_APP_API}/users/me/paymentinfo`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then(function (res) {
        if (res?.code === "SUCCESS") {
          vm.setState({
            paymentInfo: res?.data,
          });
        } else {
          alert("Unable to save, please try again.");
        }
      })
      .catch(function (res) {
        console.log(res);
        alert("Payment Unsuccessful");
      });
  };

  acceptTerms = (accept) => {
    this.setState({ isPaymentButtonDisabled: accept });
  };

  addPaymentMethod = (token) => {
    if (this.state.paymentInfo?.cardLastFour) {
      this.modaltoggle("rentalpdf");
    } else {
      this.savePaymentInfo(token);
    }
  };

  render() {
    const { selectedDay, isDisabled, isEmpty } = this.state;

    let bannedDates = this.state.rentalDates;
    bannedDates.push(
      {
        after: moment(this.props.maxEndDate).toDate(),
        before: moment(this.props.minStartDate).toDate(),
      },
      { before: new Date() }
    );

    return (
      <div>
        <p>
          {isEmpty && "Please type or pick a day"}
          {!isEmpty && !selectedDay}
          {selectedDay && isDisabled && "This day is disabled"}
          {selectedDay &&
            !isDisabled &&
            `You chose ${selectedDay.toLocaleDateString()}`}
        </p>
        <RentalPdfModal
          acceptTerms={this.acceptTerms}
          agreement={this.state.agreement}
          toggle={this.modaltoggle}
          modalOpen={this.state.RentalPdfModalOpen}
          user={this.props?.user}
        />

        <PaymentSuccessModal
          toggle={this.modaltoggle}
          modalOpen={this.state.PaymentSuccessModalOpen}
        />
        {this.props.postInfo.user.id == localStorage.getItem("userId") ? (
          <Nav tabs className="single-post-navs">
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                {" "}
                INFORMATION{" "}
              </NavLink>
            </NavItem>
            {this.state.postUserId == localStorage.getItem("userId") ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  RENTAL HISTORY
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
        ) : null}
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12" className="post-info-main-container">
                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container main-one">
                    <div className="left-side">
                      <img
                        className="left-side-pf"
                        src={this.state.userDetails.profileImage}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <div className="right-side-one">
                        <h2 className="post-title">
                          {" "}
                          {this.state.itemDetails.name}
                        </h2>
                        {this.state.companyName ? (
                          <p
                            className="post-title-username"
                            style={{ marginBottom: "0px" }}
                          >
                            Company: {this.state.companyName}{" "}
                          </p>
                        ) : null}
                        {localStorage.getItem("accessToken") && (
                          <p className="post-title-username">
                            {" "}
                            <Link
                              to={
                                this.props.postUserId ==
                                localStorage.getItem("userId")
                                  ? {
                                      pathname: `/myprofile`,
                                      state: { userId: this.props.postUserId },
                                    }
                                  : {
                                      pathname: `/profile/${this.props.postUserId}`,
                                      state: {
                                        userId: this.props.postUserId,
                                        messagable: this.state.messagable,
                                      },
                                    }
                              }
                            >
                              {" "}
                              {this.state.userDetails.firstName}{" "}
                              {this.state.userDetails.lastName}
                            </Link>
                          </p>
                        )}
                        {this.state.itemDetails.allowsCancelling == true ? (
                          <p style={{ color: "#B1B1B1" }}>
                            {" "}
                            Allows Cancellations{" "}
                          </p>
                        ) : (
                          <p style={{ color: "#B1B1B1" }}>
                            Does Not Allow Cancellations{" "}
                          </p>
                        )}
                        {/* {this.state.itemDetails.allowsCancelling == true && ( */}
                        <div style={{ color: "#B1B1B1" }}>
                          Phone Number:{" "}
                          <span
                            style={{ fontWeight: "bold", color: "#8ac53f" }}
                          >
                            {this?.state?.itemDetails?.user?.phone}
                          </span>
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </Row>

                {/* <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side">
                      <img className="left-side-icon" src={require('../../../../Common/images/icon_info.svg')} alt="search" />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Description  </h4>
                      <div className="post-title-username post-info-block" style={{ padding: '0 20px 10px 0' }}>
                        <div className="rates-container">
                          <p className="rateFreq">Make</p>
                          <p className="ratePrice"> {this.state.itemDetails.make} </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Model</p>
                          <p className="ratePrice">  {this.state.itemDetails.model}  </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Year</p>
                          <p className="ratePrice"> {this.state.itemDetails.year} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row> */}

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../../../Common/images/newicons/icon_info.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Description </h4>

                      <div
                        className="post-title-username post-info-block"
                        style={{ padding: "0 20px 10px 0" }}
                      >
                        <div className="rates-container">
                          <p className="rateFreq">Make</p>
                          <p className="ratePrice">
                            {" "}
                            {this.state.itemDetails.make}{" "}
                          </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Model</p>
                          <p className="ratePrice">
                            {" "}
                            {this.state.itemDetails.model}{" "}
                          </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Year</p>
                          <p className="ratePrice">
                            {" "}
                            {this.state.itemDetails.year}{" "}
                          </p>
                        </div>
                      </div>

                      <p className="post-title-username">
                        {" "}
                        {this.state.itemDetails.description}{" "}
                      </p>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../../../Common/images/newicons/icon_specifications.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Specifications </h4>
                      <p className="post-title-username no-margin">
                        Weight {this.state.itemDetails.weight} tonnes{" "}
                      </p>
                      <p className="post-title-username no-margin">
                        Length {this.state.itemDetails.length} m
                      </p>
                      <p className="post-title-username no-margin">
                        {" "}
                        {this.state.itemDetails.axles} wheel drive
                      </p>
                      {/* <p className="post-title-username no-margin"> { this.state.itemDetails.type } </p> */}
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon "
                        src={require("../../../../Common/images/newicons/icon_rates.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Rates </h4>
                      <div
                        className="post-title-username post-info-block"
                        style={{ padding: "10px 0 10px" }}
                      >
                        <div className="rates-container">
                          <p className="rateFreq">Daily</p>
                          <p className="ratePrice">
                            ${this.state.itemDetails.dailyRate}/day{" "}
                          </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Weekly</p>
                          <p className="ratePrice">
                            ${this.state.itemDetails.weeklyRate}/week{" "}
                          </p>
                        </div>
                        {this.state?.itemDetails?.monthlyRate > 0 && (
                          <div className="rates-container">
                            <p className="rateFreq">Monthly</p>
                            <p className="ratePrice">
                              ${this.state?.itemDetails?.monthlyRate}/month{" "}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../../../Common/images/newicons/icon_comments.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Comments </h4>
                      <p className="post-title-username">
                        {this.state.itemDetails.comments
                          ? this.state.itemDetails.comments
                          : "No comments"}{" "}
                      </p>
                    </div>
                  </div>
                </Row>
                {localStorage.getItem("accessToken") && (
                  <>
                    <Row style={{ padding: 10 }}>
                      <div className="container post-info-container">
                        <div className="left-side newicons">
                          <img
                            className="left-side-icon"
                            src={require("../../../../Common/images/newicons/icon_calendar.svg")}
                            alt="search"
                          />
                        </div>
                        <div className="right-side">
                          <h4 className="post-title">Rental Date </h4>
                          <p style={{ color: "grey" }}>
                            {" "}
                            Rental available from {
                              this.state.startTimeString
                            } - {this.state.endTimeString}
                          </p>
                          {this.state?.minimumRentalPeriod > 0 && (
                            <p
                              style={{
                                color:
                                  this.state?.minimumRentalPeriod >
                                  this.state.months
                                    ? "red"
                                    : "grey",
                              }}
                            >
                              {" "}
                              Minimum Rental Period{": "}
                              {this.state?.minimumRentalPeriod} months
                            </p>
                          )}
                          <FormGroup row>
                            <Col sm={6}>
                              <Label for="viewItemStartTime">
                                <span style={{ color: "grey" }}>
                                  Start Date
                                </span>{" "}
                              </Label>
                            </Col>
                            <Col sm={6} className="timedatepicker-cont">
                              <DayPickerInput
                                value={selectedDay}
                                name="viewItemStartTime"
                                onDayChange={this.handleStartDayChange}
                                placeholder="      Please select date."
                                dayPickerProps={{
                                  startDate: selectedDay,
                                  disabledDays: bannedDates,
                                }}
                              />
                              {/* <Datetime
                            inputProps={{ placeholder: "Please select date." }}
                            type="date"
                            timeFormat={false}
                            input={true}
                            isValidDate={this.valid}
                            name="viewItemStartTime"
                            onChange={this.ChangeDate}
                          /> */}
                              {/* <Datetime inputProps={{ placeholder: "Please select Time." }} type="time" dateFormat={false}  onChange={this.ChangeTime} /> */}
                            </Col>
                          </FormGroup>
                          {this.state.showEndDate && (
                            <FormGroup row>
                              <Col sm={6}>
                                <Label for="viewItemEndTime">
                                  <span style={{ color: "grey" }}>
                                    End Date
                                  </span>
                                </Label>
                              </Col>
                              <Col sm={6} className="timedatepicker-cont">
                                <DayPickerInput
                                  value={selectedDay}
                                  name="viewItemEndTime"
                                  onDayChange={this.handleEndDayChange}
                                  placeholder="      Please select date."
                                  dayPickerProps={{
                                    endDate: selectedDay,
                                    disabledDays: bannedDates,
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          )}
                          <div
                            style={{
                              fontWeight: "500",
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          >
                            Renting for: {this.state.rentalSelectionText}
                          </div>
                          {this.props.isOpenEndedRental && (
                            <FormGroup row>
                              <Col sm={12}>
                                <Col sm={7} className="pl-0">
                                  {/* <h4 className="post-title">Open ended rental </h4> */}
                                  <Label for="viewItemEndTime">
                                    <span style={{ color: "grey" }}>
                                      Open ended rental
                                    </span>
                                  </Label>
                                </Col>
                                <Col
                                  sm={5}
                                  className="d-flex flex-row justify-content-between"
                                >
                                  <Label for=""> </Label>
                                  <label>
                                    <Switch
                                      onChange={this.handleChange4}
                                      checked={this.state.isOpenEndedRental}
                                      offColor="#E6E6E6"
                                      onColor="#D0E7B2"
                                      onHandleColor="#8AC53F"
                                      handleDiameter={30}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                    />
                                  </label>
                                </Col>
                              </Col>
                            </FormGroup>
                          )}
                          {this.state.dateErrors ? (
                            <p style={{ color: "red", padding: "15px 0" }}>
                              This must be a minimum of 1 day and End date
                              cannot be before the Start date
                            </p>
                          ) : null}
                          {this.state.disabledDateError ? (
                            <p style={{ color: "red", padding: "15px 0" }}>
                              Selected dates contains disabled dates
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Row>

                    <Row style={{ padding: 10 }}>
                      <div className="container post-info-container">
                        <div className="left-side newicons">
                          <img
                            className="left-side-icon"
                            src={require("../../../../Common/images/newicons/icon_location.svg")}
                            alt="search"
                          />
                        </div>
                        <div className="right-side">
                          <h4 className="post-title">
                            Select Pickup Or Delivery{" "}
                          </h4>

                          {this.props.isPickupAvailable ? (
                            <div className="pickup-or-delivary-container">
                              <div className="pickup-text">
                                <p className="post-title-username">
                                  I will Pickup item{" "}
                                </p>
                              </div>

                              <div className="pickup-address-container">
                                <span>
                                  Pickup address is hidden until payment is
                                  made.*
                                </span>
                              </div>
                              <div className="pickup-switch">
                                <label>
                                  <Switch
                                    checked={this.state.checked}
                                    onChange={() => this.handleChange("1")}
                                    offColor="#E6E6E6"
                                    onColor="#D0E7B2"
                                    onHandleColor="#8AC53F"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch"
                                  />
                                </label>
                              </div>
                            </div>
                          ) : (
                            <span className="post-title-username">
                              {" "}
                              Pick up not available{" "}
                            </span>
                          )}

                          {this.props.isDeliveryAvailable === true ? (
                            <div className="pickup-or-delivary-container">
                              <div className="delivery-text">
                                <p className="post-title-username">
                                  Deliver item to me
                                </p>
                              </div>
                              <div className="delivery-address-container">
                                <FormGroup row>
                                  <Label
                                    for="delivery-address"
                                    sm={2}
                                    size="lg"
                                    hidden
                                  >
                                    Deliver here
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="delivery-switch">
                                <label>
                                  <Switch
                                    checked={this.state.checked2}
                                    onChange={() => this.handleChange("2")}
                                    offColor="#E6E6E6"
                                    onColor="#D0E7B2"
                                    onHandleColor="#8AC53F"
                                    handleDiameter={30}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={20}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch2"
                                  />
                                </label>
                              </div>
                            </div>
                          ) : (
                            <span className="post-title-username">
                              {" "}
                              Delivery not available{" "}
                            </span>
                          )}
                        </div>
                      </div>
                    </Row>

                    {localStorage.getItem("userId") ===
                      this.state.postUserId && (
                      <Row style={{ padding: 10 }}>
                        <div className="container post-info-container">
                          <div className="left-side newicons">
                            <svg
                              width="33"
                              height="33"
                              viewBox="0 0 42 54"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.0001 36.0005H24.0001V28.5004H31.5001V22.5004H24.0001V15.0004H18.0001V22.5004H10.5001V28.5004H18.0001V36.0005ZM21.0001 53.885C14.9731 52.0965 9.96637 48.4454 5.97987 42.9319C1.99337 37.4184 0.00012207 31.2079 0.00012207 24.3004V8.0772L21.0001 0.231445L42.0001 8.0772V24.3004C42.0001 31.2079 40.0069 37.4184 36.0204 42.9319C32.0339 48.4454 27.0271 52.0965 21.0001 53.885ZM21.0001 50.7005C26.2001 49.0504 30.5001 45.7505 33.9001 40.8004C37.3001 35.8504 39.0001 30.3504 39.0001 24.3004V10.1254L21.0001 3.4332L3.00012 10.1254V24.3004C3.00012 30.3504 4.70012 35.8504 8.10012 40.8004C11.5001 45.7505 15.8001 49.0504 21.0001 50.7005Z"
                                fill="#4B4B4B"
                              />
                            </svg>
                          </div>
                          <div className="right-side">
                            <h4 className="post-title">Insurance</h4>
                            <div
                              style={{
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              {this.props.postInfo?.isInsuranceRequired
                                ? "Required"
                                : "Not Required"}
                            </div>
                          </div>
                        </div>
                      </Row>
                    )}

                    {this.props.postInfo?.isInsuranceRequired &&
                      localStorage.getItem("userId") !==
                        this.state.postUserId && (
                        <Row style={{ padding: 10 }}>
                          <div className="container post-info-container">
                            <div className="left-side newicons">
                              <svg
                                width="33"
                                height="33"
                                viewBox="0 0 42 54"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.0001 36.0005H24.0001V28.5004H31.5001V22.5004H24.0001V15.0004H18.0001V22.5004H10.5001V28.5004H18.0001V36.0005ZM21.0001 53.885C14.9731 52.0965 9.96637 48.4454 5.97987 42.9319C1.99337 37.4184 0.00012207 31.2079 0.00012207 24.3004V8.0772L21.0001 0.231445L42.0001 8.0772V24.3004C42.0001 31.2079 40.0069 37.4184 36.0204 42.9319C32.0339 48.4454 27.0271 52.0965 21.0001 53.885ZM21.0001 50.7005C26.2001 49.0504 30.5001 45.7505 33.9001 40.8004C37.3001 35.8504 39.0001 30.3504 39.0001 24.3004V10.1254L21.0001 3.4332L3.00012 10.1254V24.3004C3.00012 30.3504 4.70012 35.8504 8.10012 40.8004C11.5001 45.7505 15.8001 49.0504 21.0001 50.7005Z"
                                  fill="#4B4B4B"
                                />
                              </svg>
                            </div>
                            <div className="right-side">
                              <h4 className="post-title">Insurance</h4>

                              {(this.props.isInsuranceRequired ||
                                !this.state.insuranceDoc) &&
                              !this.state.fileName ? (
                                <div
                                  onDragOver={(e) => e.preventDefault()}
                                  onDrop={(e) => {
                                    e.preventDefault();
                                    const file = e.dataTransfer.files[0];
                                    this.setState(
                                      { selectedFile: file },
                                      () => {
                                        this.uploadPdf();
                                      }
                                    );
                                    if (
                                      file &&
                                      allowedTypes.includes(file.type)
                                    ) {
                                      console.log("File Selected");
                                    } else {
                                      alert(
                                        "Please upload JPG, PNG or PDF file."
                                      );
                                    }
                                  }}
                                  className="upload-insurance-document"
                                >
                                  <svg
                                    width="32"
                                    height="40"
                                    viewBox="0 0 32 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.9167 32.5H17.0834V22.3292L21.6334 26.8792L23.1668 25.3333L16 18.1665L8.83325 25.3333L10.3792 26.8667L14.9167 22.3292V32.5ZM4.33362 39.5C3.33623 39.5 2.50351 39.166 1.83546 38.4979C1.1674 37.8299 0.833374 36.9971 0.833374 35.9998V4.00025C0.833374 3.00286 1.1674 2.17014 1.83546 1.50208C2.50351 0.834028 3.33623 0.5 4.33362 0.5H21.4167L31.1667 10.25V35.9998C31.1667 36.9971 30.8327 37.8299 30.1646 38.4979C29.4966 39.166 28.6638 39.5 27.6665 39.5H4.33362ZM20.3334 11.3333V2.66667H4.33362C3.99996 2.66667 3.69428 2.80551 3.41658 3.08321C3.13889 3.3609 3.00004 3.66658 3.00004 4.00025V35.9998C3.00004 36.3334 3.13889 36.6391 3.41658 36.9168C3.69428 37.1945 3.99996 37.3333 4.33362 37.3333H27.6665C28.0001 37.3333 28.3058 37.1945 28.5835 36.9168C28.8612 36.6391 29 36.3334 29 35.9998V11.3333H20.3334Z"
                                      fill="#8AC53F"
                                    />
                                  </svg>

                                  <div className="rateFreq ml-4">
                                    <div
                                      style={{
                                        fontWeight: 600,
                                        color: "#000",
                                      }}
                                    >
                                      Drag & Drop your files
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 13,
                                        color: "#A9B5C1",
                                      }}
                                    >
                                      JPG, PNG or PDF, smaller than 10mb
                                    </div>
                                  </div>
                                  <div>
                                    <input
                                      type="file"
                                      ref={this.fileInputRef}
                                      style={{ display: "none" }}
                                      onChange={this.handleFileChange}
                                    />
                                    <Button
                                      onClick={this.handleButtonClick}
                                      className="post-page-continue postview-submitbutton"
                                    >
                                      Browse files
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="rateFreq ml-4">
                                    <div
                                      style={{
                                        fontWeight: 600,
                                        color: "#000",
                                      }}
                                    >
                                      {this.state?.fileName}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 13,
                                        color: "#A9B5C1",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          pdf: this.state?.insurancePDFPath,
                                        })
                                      }
                                    >
                                      View
                                    </div>
                                  </div>
                                  <Button
                                    onClick={this.deleteFile}
                                    className="post-page-continue postview-submitbutton"
                                  >
                                    Delete
                                  </Button>
                                </div>
                              )}

                              {/* <div className="pickup-or-delivary-container">
                              <div className="pickup-switch">
                                <label>
                                  <Button
                                    // disabled={this.state.isTermsButtonDisabled}
                                    block
                                    className="post-page-continue postview-submitbutton"
                                  >
                                    Save changes
                                  </Button>
                                </label>
                              </div>
                            </div> */}
                            </div>
                          </div>
                        </Row>
                      )}

                    {this.state.checked2 ? (
                      <Row style={{ padding: 10 }}>
                        <div className="container post-info-container">
                          <div className="left-side" />
                          <div className="right-side">
                            <div className="my-map-container">
                              <Map
                                theaddress={this.theaddressDeliver}
                                getlatlong={this.getlatlongDeliver}
                                center={{ lat: 51.05011, lng: -114.08529 }}
                                calculateTotal={this.calculateTotal}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="container post-info-container">
                          <div className="left-side newicons">
                            <img
                              className="left-side-icon"
                              src={require("../../../../Common/images/newicons/icon_clock.svg")}
                              alt="search"
                            />
                          </div>
                          <div
                            className="right-side"
                            style={{ marginBottom: 40 }}
                          >
                            <h4 className="post-title">
                              Delivery Times{" "}
                              <span
                                style={{
                                  color: "rgba(0,0,0,0.3)",
                                  fontSize: 16,
                                }}
                              >
                                ( This will be an approximate time. )
                              </span>{" "}
                            </h4>

                            <p
                              className="post-title-username"
                              style={{ marginTop: 35, marginBottom: 25 }}
                            >
                              Start Time
                            </p>

                            <Timeslider
                              parentCallback={this.callbackFunction}
                              type="startTime"
                              postInfo={this.state.itemDetails}
                              startDate={this.state.startTimeString}
                              endDate={this.state.endTimeString}
                            />

                            <p
                              className="post-title-username"
                              style={{ marginTop: 60, marginBottom: 30 }}
                            >
                              End Time
                            </p>

                            <Timeslider
                              parentCallback={this.callbackFunction}
                              type="endTime"
                              postInfo={this.state.itemDetails}
                              startDate={this.state.startTimeString}
                              endDate={this.state.endTimeString}
                            />
                          </div>
                        </div>

                        <div className="container post-info-container">
                          <div className="left-side newicons">
                            <img
                              className="left-side-icon"
                              src={require("../../../../Common/images/newicons/icon_rates.svg")}
                              alt="search"
                            />
                          </div>
                          <div className="right-side">
                            <h4 className="post-title">Delivery Rates </h4>
                            <div
                              className="post-title-username post-info-block"
                              style={{ padding: "10px 0 10px" }}
                            >
                              <div className="rates-container">
                                <p className="rateFreq">
                                  Flat rate (First 10 km)
                                </p>
                                <p className="ratePrice">
                                  {this.state.itemDetails.deliveryRateFirst10Km}
                                </p>
                              </div>
                              <div className="rates-container">
                                <p className="rateFreq">Extra Km</p>
                                <p className="ratePrice">
                                  {
                                    this.state.itemDetails
                                      .deliveryRateExtraPerKm
                                  }
                                </p>
                              </div>
                              {/* <div className="rates-container">
                            <p className="rateFreq">Taxes</p>
                            <p className="ratePrice">$100</p>
                          </div> */}
                            </div>
                          </div>
                        </div>
                      </Row>
                    ) : null}

                    <Row style={{ padding: 10 }}>
                      <div className="container post-info-container">
                        <div className="left-side newicons">
                          <img
                            className="left-side-icon"
                            src={require("../../../../Common/images/newicons/icon_price.svg")}
                            alt="search"
                          />
                        </div>
                        <div className="right-side">
                          <h4 className="post-title">Total </h4>{" "}
                          {/* <Button type="button" onClick={this.calculateTotal}>
                        {" "}
                        Calculate Total
                      </Button> */}
                          {this.state.calculateTotalError ? (
                            <div style={{ color: "red", padding: "15px 0" }}>
                              Please select required fields and fix errors
                            </div>
                          ) : null}
                          {this.state.emptyAddressError ? (
                            <div style={{ color: "red", padding: "15px 0" }}>
                              Please enter an address
                            </div>
                          ) : null}
                          <div
                            className="post-title-username post-info-block"
                            style={{ padding: "10px 0 10px" }}
                          >
                            <div className="rates-container">
                              <p className="rateFreq">Rental Amount </p>
                              <p className="ratePrice">
                                {this.state.rentalAmount}
                              </p>
                            </div>

                            <div className="rates-container">
                              <p className="rateFreq">Delivery Charges </p>
                              <p className="ratePrice">
                                {this.state.rentalDeliveryAmount}
                              </p>
                            </div>

                            <div className="rates-container">
                              <p className="rateFreq">Taxes </p>
                              <p className="ratePrice">
                                {this.state.rentalGst}
                              </p>
                            </div>

                            <div className="rates-container total-amout-container">
                              <p
                                className="rateFreq"
                                style={{ fontWeight: 600, color: "#000" }}
                              >
                                Total Amount{" "}
                              </p>
                              <p
                                className="ratePrice"
                                style={{ color: "#8AC53F", fontWeight: 600 }}
                              >
                                {this.state.totalRentalAmount}
                              </p>
                            </div>
                            <div className="rates-container">
                              {this.state.itemDetails.allowsCancelling ==
                              true ? (
                                <p style={{ color: "#B1B1B1" }}>
                                  {" "}
                                  You must provide at least 24 hours notice
                                  prior to the start of your rental to avoid a
                                  penalty. If you choose to cancel within the 24
                                  hours preceding your rental, you will be
                                  charged one daily rate of all items requested.{" "}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>

                    {this.state.addOnDetails.length !== 0 ? (
                      <Row style={{ padding: 10 }}>
                        <div>
                          <h4>Add Ons</h4>
                        </div>
                        <div className="container post-info-container">
                          <div className="addons-side-right">
                            <div className="addon-cards-container0">
                              <div className="addon-cards-container">
                                {this.state.addOnDetails.map(
                                  (addOnElement, i) => {
                                    return (
                                      <Card
                                        style={{ minWidth: "300px" }}
                                        key={i}
                                      >
                                        <div className="add-on-img">
                                          {/* <CardHeader> */}

                                          <Link
                                            to={{
                                              pathname: `/post/${addOnElement.addOnObjId}`,
                                              state: {
                                                postUserId:
                                                  this.state.postUserId,
                                                postId: addOnElement.addOnObjId,
                                              },
                                            }}
                                            target="_blank"
                                          >
                                            <CardImg
                                              top
                                              width="100%"
                                              src={addOnElement.addOnObjImg}
                                              alt="Card image cap"
                                            />
                                          </Link>
                                          <span className="price-on-addons">
                                            ${addOnElement.addOnObjDaily}/day
                                          </span>
                                          {/* </CardHeader> */}
                                        </div>
                                        <CardBody>
                                          <CardTitle>
                                            {addOnElement.addOnObjName}
                                          </CardTitle>
                                          {/* <Button block className="add-ons-button">Add On</Button> */}

                                          <Button
                                            disabled={
                                              this.state.startDate <=
                                                this.state.startDateAddOn ||
                                              this.state.endDateAddOn >=
                                                this.state.endDate ||
                                              moment(
                                                addOnElement.addOnminStartDate
                                              ) >=
                                                moment(
                                                  this.state.startDate
                                                ).toDate() ||
                                              moment(
                                                addOnElement.addOnmaxEndDate
                                              ) <=
                                                moment(
                                                  this.state.endDate
                                                ).toDate() ||
                                              this.state.startDate === null ||
                                              this.state.endDate === null ||
                                              (!this.state.checked &&
                                                !this.state.checked2)
                                            }
                                            style={
                                              addOnElement.selected
                                                ? { backgroundColor: "#3E3F9B" }
                                                : { backgroundColor: "#8AC53F" }
                                            }
                                            type="button"
                                            onClick={() => {
                                              $.when(
                                                this.addAddon(
                                                  addOnElement.addOnObjId,
                                                  i
                                                )
                                              ).then(() => {
                                                this.calculateTotal();
                                              });
                                            }}
                                            block
                                            className="add-ons-button-edit-post"
                                          >
                                            {" "}
                                            {addOnElement.selected
                                              ? "Remove this Add on "
                                              : "Attach Add On to Equipment "}
                                          </Button>
                                        </CardBody>
                                      </Card>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    ) : null}

                    <Row style={{ padding: 10, margin: "90px 0 20px 0" }}>
                      {this.state.isPaymentButtonDisabled &&
                      this.state?.paymentInfo?.cardLastFour ? (
                        <Button
                          onClick={() => this.addPaymentMethod()}
                          disabled={
                            this.state.isTermsButtonDisabled ||
                            this.state?.minimumRentalPeriod > this.state.months
                          }
                          block
                          className="post-page-continue postview-submitbutton"
                        >
                          Continue{" "}
                        </Button>
                      ) : null}
                      {this.state.isPaymentButtonDisabled &&
                      !this.state?.paymentInfo?.cardLastFour ? (
                        <StripeCheckout
                          stripeKey={process.env.REACT_APP_STRIPE}
                          token={this.addPaymentMethod}
                          panelLabel="Save"
                          // closed={this.addPaymentMethod}
                          description="Please enter card details."
                          name="Payment method required"
                        >
                          <Button
                            disabled={this.state.isTermsButtonDisabled}
                            block
                            className="post-page-continue postview-submitbutton"
                          >
                            Continue{" "}
                          </Button>
                        </StripeCheckout>
                      ) : null}
                    </Row>
                  </>
                )}
                {!this.state.isPaymentButtonDisabled ? (
                  // <StripeCheckout
                  //   stripeKey={process.env.REACT_APP_STRIPE}
                  //   token={this.handleToken}
                  //   className="btn btn-success"
                  //   name="Make Payment">
                  <Button
                    onClick={() => this.handleToken()}
                    disabled={this.state.isPaymentButtonDisabled}
                    block
                    className="post-page-continue postview-submitbutton"
                  >
                    Make Payment
                  </Button>
                ) : // </StripeCheckout>
                null}
              </Col>
            </Row>
          </TabPane>

          {this.state.postUserId == localStorage.getItem("userId") ? (
            <TabPane tabId="2">
              <Row>
                <RentalHistory equipmentId={this.state.postId} />
              </Row>
            </TabPane>
          ) : null}
        </TabContent>
        <PDF
          link={this.state.pdf}
          fileName={this.state?.fileName}
          close={() => this.setState({ pdf: "" })}
        />
      </div>
    );
  }
}

Example.propTypes = {
  postImageUrl: PropTypes.string,
  postName: PropTypes.string,
  postUser: PropTypes.string,
  postRating: PropTypes.number,
  postId: PropTypes.string,
  postUserId: PropTypes.string,
};
