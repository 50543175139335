import React from "react";
import "./renting.css";
import classnames from "classnames";

// import {getEquipments} from '../../App/Api/equipment.js'

import { getRentals } from "../../App/Api/rentals.js";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";

import RentingPost from "../../Common/RentingPost";
import { get } from "http";
import { NavLink, Link, withRouter, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCircle } from "@fortawesome/free-solid-svg-icons";
import { extendMoment } from "moment-range";
import originalMoment from "moment";
import {
  Navbar,
  Button,
  Dropdown,
  DropdownMenu,
  Row,
  Col,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
const moment = extendMoment(originalMoment);

class Renting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      type: localStorage.getItem("activeTab")
        ? JSON.parse(localStorage.getItem("activeTab"))
        : 1,
      Page: 0,
      PerPage: 2000,
      RentalType: 1,
      city: null,
      items: [],
      cSelected: [],
      NO_DATA: false,
    };
  }

  changeRentingStatus = (type, filter) => {
    const vm = this;
    this.setState(
      { type: type, items: [], loading: true, NO_DATA: false },
      () => {
        getRentals(vm.state.type, vm.state.Page, vm.state.PerPage).then(
          function (res) {
            console.log(res);

            if (res.items.length <= 0) {
              vm.setState({
                NO_DATA: true,
                loading: false,
              });
            }
            let data = [];
            if (filter === "startDate") {
              data = res.items.sort(function (a, b) {
                return new Date(b.startDate) - new Date(a.startDate);
              });
            } else if (filter === "endDate") {
              data = res.items.sort(function (a, b) {
                return new Date(b.endDate) - new Date(a.endDate);
              });
            } else if (filter === "upcoming") {
              data = res.items
                .sort(function (a, b) {
                  return new Date(b.startDate) - new Date(a.startDate);
                })
                .filter(function (item) {
                  let startTime = item.startTime.split(":");
                  let startHour = startTime[0];
                  let startMoment = moment
                    .utc(item.startDate)
                    .set({ hour: 0 })
                    .add("hours", startHour);
                  let startDate = startMoment
                    .local()
                    .format("ddd, MMM  DD, YYYY, hh:mm A");
                  let startTimeString = startDate;

                  const lessThanOneHourAgo = () =>
                    moment(startDate).isBefore(moment().add(24, "hours")) &&
                    moment(startDate).isAfter(moment()) &&
                    item.comfirmedAt === null;

                  console.log(lessThanOneHourAgo());
                  return lessThanOneHourAgo();
                });
            } else if (filter === "inProgress") {
              data = res.items
                .sort(function (a, b) {
                  return new Date(b.startDate) - new Date(a.startDate);
                })
                .filter(function (item) {
                  // let startTime = item.startTime.split(":");
                  // let startHour = startTime[0];
                  // let startMoment = moment
                  //   .utc(item.startDate)
                  //   .set({ hour: 0 })
                  //   .add("hours", startHour);
                  // let startDate = startMoment
                  //   .local()
                  //   .format("ddd, MMM  DD, YYYY, hh:mm A");
                  // let startTimeString = startDate;

                  // let endTime = item.endTime.split(":");
                  // let endHour = endTime[0];
                  // let endMoment = moment
                  //   .utc(item.endDate)
                  //   .set({ hour: 0 })
                  //   .add(endHour, "hours");
                  // let endDate = endMoment
                  //   .local()
                  //   .format("ddd, MMM  DD, YYYY, hh:mm A");
                  // let endTimeString = endDate;

                  const filterInProgress =
                    !item.comfirmedAt && !item.refundedAt;
                  // (moment().isBefore(moment(endDate)) &&
                  //   moment().isAfter(moment(startDate))) ||
                  // (!item.comfirmedAt && !item.refundedAt);

                  return filterInProgress;
                });
            } else if (filter === "canceled") {
              data = res.items.filter((item) => item.refundedAt);
            } else {
              data = res.items;
            }
            if (data.length === 0) {
              vm.setState({ loading: false });
            }
            console.log(data);

            data.forEach((item) => {
              let startTime = item.startTime.split(":");
              let startHour = startTime[0];
              let startMoment = moment
                .utc(item.startDate)
                .set({ hour: 0 })
                .add("hours", startHour);
              let startDate = startMoment
                .local()
                .format("ddd, MMM  DD, YYYY, hh:mm A");
              let startTimeString = startDate;

              let endTime = item.endTime.split(":");
              let endHour = endTime[0];
              let endMoment = moment
                .utc(item.endDate)
                .set({ hour: 0 })
                .add(endHour, "hours");
              let endDate = endMoment
                .local()
                .format("ddd, MMM  DD, YYYY, hh:mm A");
              let endTimeString = endDate;

              let obj = {};
              obj.name = item.equipments[0].name;
              if (vm.state.type == 1) {
                obj.user =
                  item.equipmentOwner.firstName +
                  " " +
                  item.equipmentOwner.lastName;
                obj.userProfileImageUrl = item.equipmentOwner.profileImage;
              } else {
                obj.user = item.renter.firstName + " " + item.renter.lastName;
                obj.userProfileImageUrl = item.renter.profileImage;
              }
              let location;
              let type;
              if (item.deliveryLocationAddress) {
                location = item.deliveryLocationAddress;
                type = "Delivery";
              } else {
                location = item.equipments[0].pickupLocationAddress;
                type = "Pick-up";
              }

              obj.pickup = location;
              obj.type = type;
              obj.total = item.total.toFixed(2);
              obj.startDate = startTimeString;
              obj.endDate = endTimeString;
              obj.userId = item.equipmentOwner.id;
              obj.confirmedAt = item.comfirmedAt;
              obj.refundedAt = item.refundedAt;
              obj.id = item.id;
              obj.imageUrl = item.equipments[0].equipmentMedias[0].filePath;
              obj.openEndedRentalStatus = item.openEndedRentalStatus;
              obj.insuranceApprovalStatus = item?.insuranceApprovalStatus;
              obj.insuranceApprovalStatusCode =
                item?.insuranceApprovalStatusCode;
              vm.setState({
                items: vm.state.items.concat(obj),
                loading: false,
              });
            });
          }
        );
      }
    );
  };

  componentDidMount() {
    const vm = this;
    getRentals(vm.state.type, vm.state.Page, vm.state.PerPage)
      .then(function (res) {
        console.log(res);
        if (res.items.length <= 0) {
          vm.setState({
            NO_DATA: true,
            loading: false,
          });
        }
        res.items.forEach((item) => {
          let startTime = item.startTime.split(":");
          let startHour = startTime[0];
          let startMoment = moment
            .utc(item.startDate)
            .set({ hour: 0 })
            .add("hours", startHour);
          let startDate = startMoment
            .local()
            .format("ddd, MMM  DD, YYYY, hh:mm A");

          let startTimeString = startDate;
          let endTime = item.endTime.split(":");
          let endHour = endTime[0];
          let endMoment = moment
            .utc(item.endDate)
            .set({ hour: 0 })
            .add(endHour, "hours");
          let endDate = endMoment.local().format("ddd, MMM  DD, YYYY, hh:mm A");
          let endTimeString = endDate;
          let obj = {};
          obj.name = item.equipments[0].name;
          if (vm.state.type == 1) {
            obj.user =
              item.equipmentOwner.firstName +
              " " +
              item.equipmentOwner.lastName;
            obj.userProfileImageUrl = item.equipmentOwner.profileImage;
          } else {
            obj.user = item.renter.firstName + " " + item.renter.lastName;
            obj.userProfileImageUrl = item.renter.profileImage;
          }

          let location;
          let type;
          if (item.deliveryLocationAddress) {
            location = item.deliveryLocationAddress;
            type = "Delivery";
          } else {
            location = item.equipments[0].pickupLocationAddress;
            type = "Pick-up";
          }

          obj.pickup = location;
          obj.type = type;
          obj.total = item.total.toFixed(2);
          obj.startDate = startTimeString;
          obj.endDate = endTimeString;
          obj.userId = item.equipmentOwner.id;
          obj.confirmedAt = item.comfirmedAt;
          obj.refundedAt = item.refundedAt;
          obj.id = item.id;
          obj.imageUrl = item.equipments[0].equipmentMedias[0].filePath;
          obj.openEndedRentalStatus = item.openEndedRentalStatus;
          obj.insuranceApprovalStatus = item?.insuranceApprovalStatus;
          obj.insuranceApprovalStatusCode = item?.insuranceApprovalStatusCode;

          // console.log(obj)
          vm.setState({
            items: vm.state.items.concat(obj),
            loading: false,
          });
        });
      })
      .catch(function (res) {
        // console.log('isse', res)
      });
  }

  showPopup = (type) => {
    if (type === "notification") {
      this.setState({
        displayNotificationPopup: !this.state.displayNotificationPopup,
      });
    } else if (type === "message") {
      this.setState({ displayMessagePopup: !this.state.displayMessagePopup });
    } else if (type === "menu") {
      this.setState({
        displayMenuOptionsPopup: !this.state.displayMenuOptionsPopup,
      });
    } else if (type === "locations") {
      this.setState({
        displayMenuLocationsPopup: !this.state.displayMenuLocationsPopup,
      });
    } else if (type === "filters") {
      this.setState({
        displayMenuFiltersPopup: !this.state.displayMenuFiltersPopup,
      });
    } else if (type === "sorts") {
      this.setState({
        displayMenuSortsPopup: !this.state.displayMenuSortsPopup,
      });
    } else if (type === "date") {
      this.setState({
        displayMenuDatePickerPopup: !this.state.displayMenuDatePickerPopup,
      });
    }
  };

  onResetBtnClick = (type) => {
    // if (type === "filter") {
    //   this.setState({ cSelected: [], redirect: true });
    //   localStorage.removeItem("filterId");
    // } else if (type === "sort") {
    //   this.setState({ rSelected: [], redirect: true });
    //   localStorage.removeItem("sortId");
    // } else {
    //   localStorage.removeItem("sortId");
    //   localStorage.removeItem("filterId");
    //   localStorage.removeItem("searchStartDate");
    //   localStorage.removeItem("searchEndDate");
    //   this.setState({ redirect: true });
    //   this.setState(
    //     {
    //       cSelected: [],
    //       rSelected: [],
    //       endDate: null,
    //       startDate: null,
    //     },
    //     () => {
    //       this.setState({ redirect: true });
    //     }
    //   );
    // }
    this.setState({ rSelected: [], cSelected: [] });
    this.changeRentingStatus(2);
  };

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected }, () => {
      if (rSelected === "startDate") {
        this.changeRentingStatus(2, rSelected);
      } else if (rSelected === "endDate") {
        this.changeRentingStatus(2, "endDate");
      } else if (rSelected === "upcoming") {
        this.changeRentingStatus(2, rSelected);
      } else if (rSelected === "inProgress") {
        this.changeRentingStatus(2, "inProgress");
      } else if (rSelected === "canceled") {
        this.changeRentingStatus(2, "canceled");
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="renting-nav">
          <span
            className={classnames({ active: this.state.type === 1 })}
            onClick={() => {
              this.changeRentingStatus(1);
              localStorage.setItem("activeTab", 1);
            }}
          >
            Renting
          </span>
          <span
            onClick={() => {
              this.changeRentingStatus(2);
              localStorage.setItem("activeTab", 2);
            }}
            className={classnames({ active: this.state.type === 2 })}
          >
            Renting out
          </span>
          {this.state.type !== 1 && (
            <div>
              <div style={{ display: "inline-block" }}>
                <Dropdown
                  className="displayContents  mx-auto"
                  style={{ position: "relative" }}
                  isOpen={this.state.displayMenuSortsPopup}
                  toggle={() => this.showPopup("sorts")}
                >
                  <DropdownToggle
                    className="user-profile-dropdown mx-auto"
                    onClick={() => this.showPopup("sorts")}
                    data-toggle="dropdown"
                    aria-expanded={this.state.displayMenuSortsPopup}
                  >
                    <a>
                      <img
                        src={require("../../Common/Header/Images/icon_filter.svg")}
                        alt="filter"
                      />
                    </a>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-container location filter pt-0 px-0">
                    <DropdownItem
                      header
                      className="filter-dropd-header  text-align-center d-flex flex-row justify-content-between align-items-center py-0"
                    >
                      <div />
                      <div className="align-self-center ml-5">
                        <img
                          src={require("../../Common/Header/Images/icon_filter.svg")}
                          alt="filter"
                        />
                      </div>
                      <Button
                        className="btn-reset text-align-right"
                        onClick={() => this.onResetBtnClick("sort")}
                      >
                        Reset
                      </Button>
                    </DropdownItem>
                    <div>
                      <strong style={{ margin: 14 }}>Sort</strong>
                      <Row
                        className="filter-checkbox-box d-flex justify-content-between"
                        sm="12"
                      >
                        <Col className="filter-col" sm="12">
                          <div className="d-flex flex-wrap">
                            <Row className="filter-row mr-0 ml-0 pr-4" sm="6">
                              <Col sm="10">Start Date</Col>
                              <Col sm="2">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.onRadioBtnClick("startDate")
                                  }
                                  active={this.state.rSelected === "startDate"}
                                >
                                  <FontAwesomeIcon
                                    className="text-white"
                                    icon={faCircle}
                                  />
                                </Button>
                              </Col>
                            </Row>
                            <Row className="filter-row mr-0 ml-0 pr-4" sm="6">
                              <Col sm="10">End Date</Col>
                              <Col sm="2">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.onRadioBtnClick("endDate")
                                  }
                                  active={this.state.rSelected === "endDate"}
                                >
                                  <FontAwesomeIcon
                                    className="text-white"
                                    icon={faCircle}
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <strong style={{ margin: 14 }}>Filter</strong>
                      <Row
                        className="filter-checkbox-box d-flex justify-content-between"
                        sm="12"
                      >
                        <Col className="filter-col" sm="12">
                          <div className="d-flex flex-wrap">
                            <Row className="filter-row mr-0 ml-0 pr-4" sm="6">
                              <Col sm="10"> In Progress</Col>
                              <Col sm="2">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.onRadioBtnClick("inProgress")
                                  }
                                  active={this.state.rSelected === "inProgress"}
                                >
                                  <FontAwesomeIcon
                                    className="text-white"
                                    icon={faCircle}
                                  />
                                </Button>
                              </Col>
                            </Row>
                            <Row className="filter-row mr-0 ml-0 pr-4" sm="6">
                              <Col sm="10">Upcoming</Col>
                              <Col sm="2">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.onRadioBtnClick("upcoming")
                                  }
                                  active={this.state.rSelected === "upcoming"}
                                >
                                  <FontAwesomeIcon
                                    className="text-white"
                                    icon={faCircle}
                                  />
                                </Button>
                              </Col>
                            </Row>
                            <Row className="filter-row mr-0 ml-0 pr-4" sm="6">
                              <Col sm="10">Canceled</Col>
                              <Col sm="2">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.onRadioBtnClick("canceled")
                                  }
                                  active={this.state.rSelected === "canceled"}
                                >
                                  <FontAwesomeIcon
                                    className="text-white"
                                    icon={faCircle}
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          )}
        </div>

        {this.state.type === 1 ? (
          <div className="renting-list-content">
            {this.state.loading && (
              <div className="no-results-span">LOADING ...</div>
            )}
            {this.state.NO_DATA && (
              <div className="no-results-span">
                You are not renting any equipment.{" "}
              </div>
            )}
            {this.state.items.map((item, index) => {
              return (
                <div className="posts-in-dash" key={index}>
                  <RentingPost
                    RentingpostName={item.name}
                    RentingpostUser={item.user}
                    RentingpostUserProfileImageUrl={item.userProfileImageUrl}
                    RentingpostRating={item.rating}
                    RentingpostId={item.id}
                    RentingpostUserId={item.userId}
                    RentingpostImageUrl={item.imageUrl}
                    total={item.total}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    startTime={item.startTime}
                    endTime={item.endTime}
                    pickUp={item.pickup}
                    confirmedAt={item.confirmedAt}
                    refundedAt={item.refundedAt}
                    type={item.type}
                    openEndedRentalStatus={item.openEndedRentalStatus}
                    insuranceApprovalStatus={item?.insuranceApprovalStatus}
                    insuranceApprovalStatusCode={
                      item?.insuranceApprovalStatusCode
                    }
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="rentingout-list-content">
            {this.state.loading && (
              <div className="no-results-span">LOADING ...</div>
            )}
            {this.state.NO_DATA && (
              <div className="no-results-span">
                You are not renting out any equipment.{" "}
              </div>
            )}
            {this.state.items.map((item, index) => {
              return (
                <div className="posts-in-dash" key={index}>
                  <RentingPost
                    RentingpostName={item.name}
                    RentingpostUser={item.user}
                    RentingpostUserProfileImageUrl={item.userProfileImageUrl}
                    RentingpostRating={item.rating}
                    RentingpostId={item.id}
                    RentingpostUserId={item.userId}
                    RentingpostImageUrl={item.imageUrl}
                    total={item.total}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    startTime={item.startTime}
                    endTime={item.endTime}
                    pickUp={item.pickup}
                    confirmedAt={item.confirmedAt}
                    refundedAt={item.refundedAt}
                    type={item.type}
                    openEndedRentalStatus={item.openEndedRentalStatus}
                    insuranceApprovalStatus={item?.insuranceApprovalStatus}
                    insuranceApprovalStatusCode={
                      item?.insuranceApprovalStatusCode
                    }
                  />
                </div>
              );
            })}
            {this.state.items.length === 0 && (
              <div className="no-results-span">No Results</div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

Renting.propTypes = {};
export default Renting;
