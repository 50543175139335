import $ from "jquery";

export function getEquipments(
  page,
  perPage,
  searchKeyWord,
  sortOption,
  filterOption,
  province,
  StartDate,
  EndDate
) {
  let data = {};
  if (page != null) {
    data.Page = page;
  }
  if (perPage != null) {
    data.PerPage = perPage;
  }
  if (searchKeyWord != null) {
    data.SearchKeyWord = searchKeyWord;
  }
  if (sortOption != null) {
    data.SortOptionId = sortOption;
  }
  if (filterOption != null) {
    data.FilterOptionIds = filterOption;
  }
  if (province != null) {
    data.provinceIds = province;
  }
  if (StartDate != null) {
    data.StartDate = StartDate;
  }

  if (EndDate != null) {
    data.EndDate = EndDate;
  }
  let header = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (localStorage.getItem("accessToken")) {
    header.Authorization = "Bearer " + localStorage.getItem("accessToken");
  }
  return $.ajax({
    method: "GET",
    dataType: "json",
    data: data,
    traditional: true,
    xhrFields: {
      withCredentials: true,
    },
    url: process.env.REACT_APP_API + "/equipments",
    headers: header,
  }).then((res) => res.data);
}
