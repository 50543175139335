import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  // ModalFooter,
  // Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  // FormText
} from "reactstrap";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
// import {
//   faEnvelope,
//   faLock,
//   faEyeSlash,
//   faEye,
//   faUser,
//   faIdBadge
// } from "@fortawesome/free-solid-svg-icons";

import $ from "jquery";
import "./style.css";

class ReportUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportCategories: [],
      reportDescription: "",
      reportCategoryId: "",
      btnDisabled: true,
    };
  }

  // changetoLogin() {
  //   this.props.toggle('signin');
  //   this.props.toggle('login');
  // }

  // changetoLogin = () => {
  //   this.props.toggle('resetpass');
  //   this.props.toggle('login');
  // }

  componentDidMount() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/reports/categories",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      vm.setState({ reportCategories: res.data.items });
    });
  }

  getCategoryId(e) {
    this.setState({
      reportCategoryId: e.target.value,
    });
    console.log("report categoryId state", this.state.reportCategoryId);
  }

  sendReport = () => {
    // const vm = this;
    let myReport = {
      reportCategoryId: this.state.reportCategoryId,
      description: this.state.reportDescription,
    };
    if (this.state.reportCategoryId && this.state.reportDescription) {
      $.ajax({
        method: "POST",
        dataType: "json",
        traditional: true,
        data: JSON.stringify(myReport),
        xhrFields: {
          withCredentials: true,
        },
        url: `${process.env.REACT_APP_API}/equipments/${this.props.equipmentId}/report`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then(function (res) {
        Swal.fire("Reported!", "User reported.", "success").then(function () {
          window.location.reload();
        });
      });
    } else {
      Swal.fire("", "Reason and Message required!", "info");
    }
  };

  render() {
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("reportusermodal")}>
        &times;
      </button>
    );

    return (
      <div>
        <Modal
          className="feedback-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("reportusermodal")}
          size="lg">
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B" }}>
            Report User
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="reportreason" sm={2} size="lg" hidden>
                  Email
                </Label>
                <Col sm={12}>
                  <select
                    id="reportreason-select"
                    onClick={(e) => this.getCategoryId(e)}>
                    <option value="">Select reason for report</option>
                    {this.state.reportCategories.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="feedback-message" sm={2} size="lg" hidden>
                  feedback
                </Label>
                <Col sm={12}>
                  <Input
                    onChange={(e) =>
                      this.setState({ reportDescription: e.target.value })
                    }
                    className="email-input-modal"
                    type="text"
                    name="feedback-message"
                    id="feedback-message"
                    placeholder="Enter Message"
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <Button
                style={{ marginTop: 50 }}
                onClick={this.sendReport}
                className="loginbutton"
                size="lg"
                block>
                Send Report
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ReportUserModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string,
};

export default ReportUserModal;
