import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

//import NumberFormat from 'react-number-format';

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-brands-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
// import { postRegister } from '../../../App/Api/register/register.js'
import { postRegister } from "../../../App/Api/register/register.js";
import { postVerify } from "../../../App/Api/register/verify.js";

import "./style.css";

class PhoneVerificationCodeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
  }

  // changetoLogin() {
  //   this.props.toggle('signin');
  //   this.props.toggle('login');
  // }

  changeToSignUpSuccess = () => {
    const vm = this;
    //if signup all good with no errors then redirect to homepage and change to authenticaed=true
    let data = Object.assign({}, this.props.registerData, {
      emailVerificationCode: this.state.code,
    });
    postRegister(data)
      .then(function (res) {
        // console.log(res);
        if (res.code === "SUCCESS") {
          vm.props.toggle("verificationCodeModal");
          vm.props.toggle("success");

          setTimeout(() => {
            vm.props.toggle("success");
            vm.props.toggle("login");
          }, 5000);
        }
      })
      .catch((err) => Swal.fire("", "Invalid Code", ""));
  };

  resendCode = () => {
    postVerify(this.props.registerData).then(function (res) {
      // console.log(res)
      this.props.registerData.emailVerificationToken =
        res.data.emailVerificationToken;
      this.props.setRegisterData(this.props.registerData);
    });
  };

  render() {
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("verificationCodeModal")}>
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="tos-modal phone-verification-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("tosmodal")}>
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B", paddingLeft: "50px" }}>
            Verification
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col sm={12}>
                  <p>
                    Please enter the code you received through your email. If
                    you haven't received it in a few minutes, hit the Re-send
                    link
                  </p>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="emailVerificationCode" sm={2} size="lg" hidden>
                  Enter email verification Code
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faLock}
                    size="lg"
                    className="email-icon-modal"
                  />{" "}
                  <Input
                    onChange={(e) => this.setState({ code: e.target.value })}
                    type="text"
                    name="emailVerificationCode"
                    id="emailVerificationCode"
                    placeholder="Enter Code"
                    maxLength={6}
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <Button
                className="loginbutton"
                onClick={this.changeToSignUpSuccess}
                size="lg"
                block>
                Submit
              </Button>
            </Form>
          </ModalBody>
          <ModalFooter style={{ padding: 30 }}>
            <Row style={{ width: "90%" }}>
              <Col
                onClick={() => this.resendCode()}
                xs="6"
                style={{ textAlign: "center" }}>
                <a className="forgot-pass-login">Re-Send Code</a>
              </Col>
              <Col xs="6" style={{ textAlign: "center" }}>
                <a
                  className="forgot-pass-login"
                  onClick={() => {
                    this.props.toggle("verificationCodeModal");
                    this.props.toggle("verificationPhoneModal");
                  }}>
                  Back
                </a>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

PhoneVerificationCodeModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default PhoneVerificationCodeModal;
