import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const PDF = ({
  link,
  close,
  fileName = "Insurance Document",
  type,
  id,
  getRentalCall,
}) => {
  const handleInsurance = async (decision) => {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      };
      const data = {
        insuranceApprovalDecision: decision,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API}/rentals/${id}/approval`,
        data,
        {
          headers,
        }
      );
      console.log("🚀 ~ handleInsurance ~ response:", response);
      console.log("Response:", response.data);
      if (response.status === 200) {
        close();
        toast(response?.data.message, { type: "success" });

        getRentalCall();
      } else {
        toast("Something went wrong", { type: "error" });
      }
    } catch (error) {
      toast("Something went wrong", { type: "error" });
    }
  };

  return (
    <Modal isOpen={!!link} toggle={close} centered size="lg">
      <ModalHeader toggle={close}>{fileName}</ModalHeader>
      <ModalBody>
        <embed
          src={link}
          width="100%"
          id="displayFile"
          type="application/pdf"
          style={{ borderStyle: "solid", height: "70vh" }}
        />
        {type === "rentingOut" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Button
              onClick={() => handleInsurance(false)}
              className="post-page-cancel postview-submitbutton"
            >
              Decline
            </Button>

            <Button
              onClick={() => handleInsurance(true)}
              className="post-page-continue postview-submitbutton"
            >
              Approve
            </Button>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default PDF;
