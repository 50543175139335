import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import $ from "jquery";
import { Link } from "react-router-dom";
import FormattedDateTime from "../../Chats/FormattedDateTime";
import { BallBeat } from "react-pure-loaders";

class NotificationPopup extends React.Component {
  constructor() {
    super();
    this.state = {
      items: [],
      count: 0,
      loading: true,
    };
  }
  componentDidMount() {
    this.allNotifications();
  }

  allNotifications() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url:
        process.env.REACT_APP_API +
        "/notifications/getall?page=0&perPage=10000",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      let sortedItem = res.data.items.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      vm.setState({
        loading: false,
        items: sortedItem.sort(function (a, b) {
          return (a.readAt != null) - (b.readAt != null) || b.readAt - a.readAt;
        }),
        count: res.data.totalItems,
      });
    });
  }

  markRead = (id) => {
    let vm = this;
    $.ajax({
      method: "POST",
      processData: false,
      contentType: "application/json",
      xhrFields: {
        withCredentials: true,
      },

      url: `${process.env.REACT_APP_API}/notifications/${id}/markread`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    }).then(function (res) {
      vm.allNotifications();
      vm.props.getUnreadNotification();
    });
  };

  render() {
    return (
      <div>
        <div style={{ textAlign: "center" }} className="sweet-loading">
          <BallBeat color={"#8AC53F"} loading={this.state.loading} />{" "}
        </div>
        {/* {this.sendData} */}
        {this.state.items.map((notification, i) => {
          let img;
          if (notification.icon == null) {
            img =
              "https://s3.ca-central-1.amazonaws.com/vog-project-common/profile.png";
          } else {
            img = notification.icon;
          }
          return (
            <Link
              className="notificationLink"
              onClick={() => this.markRead(notification.id)}
              to={notification.url}
              key={i}>
              <div className="noti-container">
                <Row className="notification-row">
                  <Col sm="2">
                    <div className="profile-img">
                      <img
                        className="img-circle"
                        src={img}
                        alt="user-profile"
                      />
                    </div>
                  </Col>
                  <Col className="col-flex " sm="6">
                    <Row
                      className="pr-2"
                      style={
                        notification.readAt !== null
                          ? { color: "grey" }
                          : { color: "#8AC53F" }
                      }>
                      {notification.message}
                    </Row>
                  </Col>
                  <Col sm="2" className="read-date">
                    <Row
                      style={{
                        color: "#AAAAAA",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}>
                      <FormattedDateTime
                        datetime={notification.createdAt}
                        ago
                      />
                    </Row>
                  </Col>
                </Row>
                <hr />
              </div>
            </Link>
          );
        })}
      </div>
    );
  }
}

NotificationPopup.propTypes = {
  authenticated: PropTypes.bool,
  userName: PropTypes.string,
  userLocation: PropTypes.string,
  notifications: PropTypes.object,
  positionFixed: PropTypes.bool,
};

export default NotificationPopup;
