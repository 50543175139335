import $ from "jquery";

export function rentalsAvailability(id) {
  return $.ajax({
    method: "POST",
    dataType: "json",
    traditional: true,
    data: JSON.stringify({ equipmentId: id }),
    xhrFields: {
      withCredentials: true
    },
    url: `${process.env.REACT_APP_API}/rentals/availability`,

    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(res => res.data)
    .catch(res => res);
}
