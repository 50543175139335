import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";

//import NumberFormat from 'react-number-format';

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { postVerify } from "../../../App/Api/register/verify.js";

import "./style.css";

class PhoneVerificationNumberModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailVerificationToken: null,
      phone: "",
    };
  }

  // changetoLogin() {
  //   this.props.toggle('signin');
  //   this.props.toggle('login');
  // }

  changeToVerificationCode = () => {
    let data = Object.assign({}, this.props.registerData, {
      phone: this.state.phone,
    });
    // console.log(data);
    let vm = this;
    postVerify(data).then(function (res) {
      // console.log(res)
      data = Object.assign({}, data, {
        emailVerificationToken: res?.emailVerificationToken,
      });
      vm.props.setRegisterData(data);
      vm.props.toggle("verificationPhoneModal");
      vm.props.toggle("verificationCodeModal");
    });
  };

  changeToSigninAgain = () => {
    this.props.toggle("verificationPhoneModal");
    this.props.toggle("signin");
  };

  render() {
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("verificationPhoneModal")}>
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="tos-modal phone-verification-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("tosmodal")}>
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B", paddingLeft: "40px" }}>
            Account Verification
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col sm={12}>
                  <p>
                    Enter your phone number, you will receive a text message
                    with a unique code that you can use to verify you{"  "}
                  </p>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="phoneverification" sm={2} size="lg" hidden>
                  Enter phone number
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="lg"
                    className="email-icon-modal"
                  />{" "}
                  <Input
                    onChange={(e) => this.setState({ phone: e.target.value })}
                    type="tel"
                    name="phoneverification"
                    id="phoneverification"
                    placeholder="Enter Phone Number, Format: 4031234567"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <Button
                className="loginbutton"
                onClick={this.changeToVerificationCode}
                size="lg"
                block>
                Submit
              </Button>
            </Form>
          </ModalBody>
          <ModalFooter>
            <FormGroup row>
              <a
                className="forgot-pass-login"
                onClick={this.changeToSigninAgain}>
                Back
              </a>
            </FormGroup>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

PhoneVerificationNumberModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default PhoneVerificationNumberModal;
