import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  // FormText
} from "reactstrap";
import PropTypes from "prop-types";
import $ from "jquery";

import ReactTooltip from "react-tooltip";

import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye,
  faUser,
  faBuilding,
  faPhone,
  // faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";

class SignUpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      password: "",
      confirmedpassword: "",
      value: "1",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sponsorID: "",
      companyName: "",
      passErrorMsg: "",
      confirmErrorMsg: "",
      id: "",
      // firstName: "",
      // lastName: "",
      profileImage: "",
      userName: "",
      errorCode: "",
      emailExistsError: false,
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.confirmedpassword = this.confirmedpassword.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value }, () => {
      if (this.state.password !== this.state.confirmedpassword) {
        this.setState({ confirmErrorMsg: "The 2 Passwords dont match" });
      } else {
        this.setState({ confirmErrorMsg: "" });
      }
    });
  }

  confirmedpassword(e) {
    // console.log(e.target.value);
    this.setState({ confirmedpassword: e.target.value }, () => {
      if (this.state.password !== this.state.confirmedpassword) {
        this.setState({ confirmErrorMsg: "The 2 Passwords dont match" });
      } else {
        this.setState({ confirmErrorMsg: "" });
      }
    });
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }
  }

  verifyEmail = (em, callback) => {
    const vm = this;
    let data = {
      email: em,
    };
    $.ajax({
      method: "POST",
      dataType: "json",
      data: JSON.stringify(data),
      traditional: true,
      xhrFields: {
        withCredentials: false,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API + "/account/register/verifyemail",
    })
      .then((response) => {
        this.setState({ emailExistsError: false }, () => {
          callback();
        });
      })
      .catch((response) => {
        console.log(response);
        if (response.responseJSON.code === "VALIDATION_ERROR") {
          vm.setState(
            {
              emailExistsError: true,
            },
            () => {
              callback();
            }
          );
        }
      });
  };

  changetoLogin = () => {
    this.props.toggle("signin");
    this.props.toggle("login");
  };

  changeToSignupProcess = () => {
    const vm = this;
    const regEx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/;
    if (!this.state.phone.match("[0-9]{10}")) {
      vm.setState({
        passErrorMsg: "Please provide valid phone number",
      });
      return;
    }
    if (
      regEx.test(this.state.password) &&
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.email !== "" &&
      this.state.phone !== "" &&
      this.state.password === this.state.confirmedpassword
    ) {
      this.verifyEmail(this.state.email, () => {
        if (!this.state.emailExistsError) {
          let data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            sponsorID: this.state.sponsorID,
            companyName: this.state.companyName,
            email: this.state.email,
            phone: this.state.phone,
            passwordConfirmation: this.state.confirmedpassword,
          };

          this.props.setRegisterData(data);

          this.props.toggle("signin");
          this.props.toggle("tosmodal");
        }
      });
    } else if (!regEx.test(this.state.password)) {
      console.log("FAIL");
      vm.setState({
        passErrorMsg:
          "Password needs: 1 uppercase letter,1 lowercase letter, 1 special character, 1 digit and minimum 8 characters",
      });
    } else {
      vm.setState({
        passErrorMsg:
          "You need to fill all the info except for the optional one",
      });
    }
  };

  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("signin")}>
        &times;
      </button>
    );

    const responseFacebook = (response) => {
      console.log("thefb res", response);
      const vm = this;

      let data = {};
      data.accessToken = response.accessToken;

      $.ajax({
        method: "POST",
        dataType: "json",
        data: JSON.stringify(data),
        traditional: true,
        xhrFields: {
          withCredentials: false,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API + "/account/login/facebook",
      })
        .then(function (res) {
          console.log(res);

          if (res.code === "INVALID_CREDENTIALS") {
            console.log("wrong info");
            vm.setState({ errorCode: res.message });
          } else if (res.code === "SUCCESS") {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("expiaryDate", res.data.expires);
            localStorage.setItem("userId", res.data.user.id);
            localStorage.setItem("firstName", res.data.user.firstName);
            localStorage.setItem("lastName", res.data.user.lastName);
            localStorage.setItem("userName", res.data.user.userName);
            localStorage.setItem("profileImage", res.data.user.profileImage);
            localStorage.setItem("socialMediaUser", true);

            console.log(res.data.expires);
            window.location.reload();
          }
        })
        .catch(function (res) {
          console.log("the gogole login issue ");
          console.log(res);
        });
    };

    const responseGoogle = (response) => {
      // console.log(response);
      const vm = this;

      let data = {};
      data.accessToken = response.tokenId;

      $.ajax({
        method: "POST",
        dataType: "json",
        data: JSON.stringify(data),
        traditional: true,
        xhrFields: {
          withCredentials: false,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API + "/account/login/google",
      })
        .then(function (res) {
          // console.log(res);

          if (res.code === "INVALID_CREDENTIALS") {
            // console.log('wrong info');
            vm.setState({ errorCode: res.message });
          } else if (res.code === "SUCCESS") {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("expiaryDate", res.data.expires);
            localStorage.setItem("userId", res.data.user.id);
            localStorage.setItem("firstName", res.data.user.firstName);
            localStorage.setItem("lastName", res.data.user.lastName);
            localStorage.setItem("userName", res.data.user.userName);
            localStorage.setItem("profileImage", res.data.user.profileImage);
            localStorage.setItem("socialMediaUser", true);

            // console.log(res.data.expires)
            window.location.reload();
          }
        })
        .catch(function (res) {
          // console.log('the gogole login issue ')
          // console.log(res)
        });
    };

    return (
      <div>
        <Modal
          className="signup-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("signin")}>
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B", paddingLeft: "60px" }}>
            Sign Up
          </ModalHeader>
          <ModalBody>
            <span
              style={{
                width: "100%",
                textAlign: "center",
                display: "block",
                color: "red",
                marginBottom: 20,
              }}>
              {this.state.errorCode}{" "}
            </span>
            <span
              style={{
                width: "100%",
                textAlign: "center",
                display: "block",
                color: "red",
                marginBottom: 20,
              }}>
              {this.state.passErrorMsg}{" "}
            </span>
            <span
              style={{
                width: "100%",
                textAlign: "center",
                display: "block",
                color: "red",
                marginBottom: 20,
              }}>
              {this.state.confirmErrorMsg}{" "}
            </span>
            {this.state.emailExistsError ? (
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "block",
                  color: "red",
                  marginBottom: 20,
                }}>
                Email already exists, Please select a different email.
              </span>
            ) : null}

            <Form>
              <FormGroup row>
                <Label for="firstname" sm={2} size="lg" hidden>
                  First Name
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faUser}
                    size="lg"
                    className="email-icon-modal"
                  />{" "}
                  <Input
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    className="email-input-modal"
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder="First Name"
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="lastname" sm={2} size="lg" hidden>
                  Last Name
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faUser}
                    size="lg"
                    className="email-icon-modal"
                  />{" "}
                  <Input
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    className="email-input-modal"
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Last Name"
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="sponsorID" sm={2} size="lg" hidden>
                  Sponsor Id
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faBuilding}
                    size="lg"
                    className="email-icon-modal"
                  />{" "}
                  <Input
                    className="email-input-modal"
                    type="text"
                    name="sponsorID"
                    onChange={(e) =>
                      this.setState({ sponsorID: e.target.value })
                    }
                    id="sponsorID"
                    placeholder="Sponsor Id (Optional)"
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="sponsor" sm={2} size="lg" hidden>
                  Company Name
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faBuilding}
                    size="lg"
                    className="email-icon-modal"
                  />{" "}
                  <Input
                    className="email-input-modal"
                    type="text"
                    name="sponsor"
                    onChange={(e) =>
                      this.setState({ companyName: e.target.value })
                    }
                    id="sponsor"
                    placeholder="Company Name (Optional)"
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="emailsignup" sm={2} size="lg" hidden>
                  Email
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="lg"
                    className="email-icon-modal"
                  />{" "}
                  <Input
                    onChange={(e) => this.setState({ email: e.target.value })}
                    className="email-input-modal"
                    type="email"
                    name="emailsignup"
                    id="emailsignup"
                    placeholder="Email Address"
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="phoneverification" sm={2} size="lg" hidden>
                  Enter phone number
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="lg"
                    className="email-icon-modal"
                  />{" "}
                  <Input
                    onChange={(e) => this.setState({ phone: e.target.value })}
                    className="phone-input-modal"
                    type="text"
                    name="phoneverification"
                    id="phoneverification"
                    placeholder="Enter Phone Number, Format: 4031234567"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    bsSize="lg"
                    maxLength={10}
                  />
                </Col>
              </FormGroup>

              {/* <FormGroup row>
          <Label for="emailsignup" sm={2} size="lg" hidden>City</Label>
          <Col sm={12}>

           <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" className="email-icon-modal"/> 
           <Input type="select" value={this.state.value} onChange={(e)=>{this.setState({value: e.target.value})}} style={{width:'100%', marginLeft: 'none'}} className="city-select">
          <option value='1' disabled>Your Location</option>
          {
              ['Calgary','Edmonton'].map((i,j)=>{
                  return <option key={i} value={i}>{i}</option>
              })
          }
       </Input>
          </Col>
        </FormGroup> */}

              <ReactTooltip type="success" />

              <FormGroup
                row
                data-tip="Needs to be at least  1 uppercase letter,1 lowercase letter, 1 symbol, 1 digit and between 7-10 characters">
                <Label for="createpassword" sm={2} size="lg" hidden>
                  Create Password
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faLock}
                    size="lg"
                    className="pass-icon-modal"
                  />{" "}
                  <Input
                    className="password-input"
                    type={this.state.hidden ? "password" : "text"}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    name="createpassword"
                    id="createpassword"
                    placeholder="Create Password (minimum of 7 characters) "
                    bsSize="lg"
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hidden ? faEyeSlash : faEye}
                    onClick={this.toggleShow}
                    size="lg"
                    className="eye-icon-modal"
                  />
                </Col>
              </FormGroup>

              <FormGroup
                row
                data-tip="Needs to be at least  1 uppercase letter,1 lowercase letter, 1 symbol, 1 digit and between 7-10 characters">
                <Label for="createpassword-confirm" sm={2} size="lg" hidden>
                  Confirm Password
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faLock}
                    size="lg"
                    className="pass-icon-modal"
                  />{" "}
                  <Input
                    className="password-input"
                    type={this.state.hidden ? "password" : "text"}
                    value={this.state.confirmedpassword}
                    onChange={this.confirmedpassword}
                    name="createpassword-confirm"
                    id="createpassword-confirm"
                    placeholder="Confirm Password (minimum of 7 characters) "
                    bsSize="lg"
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hidden ? faEyeSlash : faEye}
                    onClick={this.toggleShow}
                    size="lg"
                    className="eye-icon-modal"
                  />
                </Col>
              </FormGroup>

              <Button
                className="loginbutton"
                onClick={this.changeToSignupProcess}
                size="lg"
                block>
                Sign Up
              </Button>

              {/* <FormGroup> */}
              {/* <div className="social-logins-login"> */}
              {/* <GoogleLogin
                    clientId="478345164350-foia8o5v2beq4c3g4cpiaptiot7gqgni.apps.googleusercontent.com"
                    render={renderProps => (
                      <Button
                        outline
                        className="login-with-google"
                        color="primary"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faGoogle} /> Login with Google
                      </Button>
                    )}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  /> */}

              {/* <Button outline  className="login-with-google" color="primary" onClick={() =>this.props.toggle('signin')}>  <FontAwesomeIcon icon={faGoogle} /> Sign Up with Google</Button> */}

              {/* <FacebookLogin
                    appId="2141954626095776"
                    callback={responseFacebook}
                    render={renderProps => (
                      <Button
                        className="login-with-fb"
                        color="primary"
                        onClick={renderProps.onClick}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faFacebookF} /> Login with
                        Facebook
                      </Button>
                    )}
                  /> */}

              {/* <Button className="login-with-fb"  color="primary" onClick={renderProps.onClick}> <FontAwesomeIcon icon={faFacebookF} />  Sign Up with Facebook</Button> */}
              {/* </div> */}
              {/* </FormGroup> */}
            </Form>
          </ModalBody>
          <ModalFooter>
            <FormGroup row>
              <p style={{ marginRight: 10 }}>Already have an account? </p>
              <a className="forgot-pass-login" onClick={this.changetoLogin}>
                Login
              </a>
            </FormGroup>
            {/* <Button color="primary" onClick={this.props.toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button> */}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

SignUpModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default SignUpModal;
