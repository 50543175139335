import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input
  // FormText
} from "reactstrap";
import PropTypes from "prop-types";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import $ from "jquery";

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import { postLogin } from "../../../App/Api/login.js";

import "./style.css";

class LoginModal extends React.Component {
  _isMounted = false; //set is mounted to false
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      password: "",
      username: "",
      clientId: "",
      errorCode: "",
      // clientId: "",
      userId: null
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    this._isMounted = true; // when component is mounted set to true
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }
  }

  componentWillUnmount() {
    this._isMounted = false; // set mounted to false so async call doesnt try to set state
  }

  changetoLogin = () => {
    this.props.toggle("login");
    this.props.toggle("signin");
  };

  changetoReset = () => {
    this.props.toggle("login");
    this.props.toggle("resetpass");
  };

  loginUser = () => {
    const vm = this;
    postLogin(this.state.username, this.state.password).then(function(res) {
      // console.log(res);

      if (res.code === "INVALID_CREDENTIALS") {
        // console.log('wrong info');
        vm.setState({ errorCode: res.message });
      } else if (res.code === "SUCCESS") {
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        localStorage.setItem("expiaryDate", res.data.expires);
        localStorage.setItem("userId", res.data.user.id);

        // console.log(res.data.expires)
        window.location.reload();
      }
    });
    // let data = {
    //   username: this.state.username,
    //   password: this.state.password
    // };
  };

  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("login")}>
        &times;
      </button>
    );

    const responseGoogle = response => {
      // console.log(response);
      const vm = this;

      let data = {};
      data.accessToken = response.tokenId;

      $.ajax({
        method: "POST",
        dataType: "json",
        data: JSON.stringify(data),
        traditional: true,
        xhrFields: {
          withCredentials: false
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        url:
          process.env.REACT_APP_API+"/account/login/google"
      })
        .then(function(res) {
          // console.log(res);

          if (res.code === "INVALID_CREDENTIALS") {
            // console.log('wrong info');
            vm.setState({ errorCode: res.message });
          } else if (res.code === "SUCCESS") {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("expiaryDate", res.data.expires);
            localStorage.setItem("userId", res.data.user.id);
            localStorage.setItem("firstName", res.data.user.firstName);
            localStorage.setItem("lastName", res.data.user.lastName);
            localStorage.setItem("userName", res.data.user.userName);
            localStorage.setItem("profileImage", res.data.user.profileImage);
            localStorage.setItem("socialMediaUser", true);

            // console.log(res.data.expires)
            window.location.reload();
          }
        })
        .catch(function(res) {
          // console.log('the gogole login issue ')
          // console.log(res)
        });
    };
    const responseFacebook = response => {
      console.log("thefb res", response);
      const vm = this;

      let data = {};
      data.accessToken = response.accessToken;

      $.ajax({
        method: "POST",
        dataType: "json",
        data: JSON.stringify(data),
        traditional: true,
        xhrFields: {
          withCredentials: false
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        url:
          process.env.REACT_APP_API+"/account/login/facebook"
      })
        .then(function(res) {
          console.log(res);

          if (res.code === "INVALID_CREDENTIALS") {
            console.log("wrong info");
            vm.setState({ errorCode: res.message });
          } else if (res.code === "SUCCESS") {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("expiaryDate", res.data.expires);
            localStorage.setItem("userId", res.data.user.id);
            localStorage.setItem("firstName", res.data.user.firstName);
            localStorage.setItem("lastName", res.data.user.lastName);
            localStorage.setItem("userName", res.data.user.userName);
            localStorage.setItem("profileImage", res.data.user.profileImage);
            localStorage.setItem("socialMediaUser", true);

            console.log(res.data.expires);
            window.location.reload();
          }
        })
        .catch(function(res) {
          console.log("the gogole login issue ");
          console.log(res);
        });
    };
    return (
      <div>
        <Modal
          className="login-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("login")}
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B", paddingLeft: "50px" }}
          >
            Login
          </ModalHeader>
          <ModalBody>
            <span
              style={{
                width: "100%",
                textAlign: "center",
                display: "block",
                color: "red",
                marginBottom: 20
              }}
            >
              {this.state.errorCode}{" "}
            </span>
            <Form>
              <FormGroup row>
                <Label for="exampleEmail" sm={2} size="lg" hidden>
                  Email
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="lg"
                    className="email-icon-modal"
                  />{" "}
                  <Input
                    onChange={e => this.setState({ username: e.target.value })}
                    className="email-input-modal"
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="Enter Email"
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="examplePassword" sm={2} size="lg" hidden>
                  Password
                </Label>
                <Col sm={12}>
                  <FontAwesomeIcon
                    icon={faLock}
                    size="lg"
                    className="pass-icon-modal"
                  />{" "}
                  <Input
                    onChange={e => this.setState({ password: e.target.value })}
                    onChange={this.handleChange}
                    className="password-input"
                    type={this.state.hidden ? "password" : "text"}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    name="password"
                    id="examplePassword"
                    placeholder="Enter Password "
                    bsSize="lg"
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hidden ? faEyeSlash : faEye}
                    onClick={this.toggleShow}
                    size="lg"
                    className="eye-icon-modal"
                  />
                </Col>
              </FormGroup>

              <Button
                className="loginbutton"
                onClick={this.loginUser}
                size="lg"
                block
              >
                Login
              </Button>

              <FormGroup row>
                <a className="forgot-pass-login" onClick={this.changetoReset}>
                  Forgot your password?
                </a>
              </FormGroup>

              {/* <FormGroup> */}
                {/* <div className="social-logins-login"> */}
                  {/* <GoogleLogin
                    clientId="478345164350-foia8o5v2beq4c3g4cpiaptiot7gqgni.apps.googleusercontent.com"
                    render={renderProps => (
                      <Button
                        outline
                        className="login-with-google"
                        color="primary"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faGoogle} /> Login with Google
                      </Button>
                    )}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  /> */}

                  {/* <Button outline  className="login-with-google" color="primary" onClick={() =>this.props.toggle('signin')}>  <FontAwesomeIcon icon={faGoogle} /> Sign Up with Google</Button> */}

                  {/* <FacebookLogin
                    appId="2141954626095776"
                    callback={responseFacebook}
                    render={renderProps => (
                      <Button
                        className="login-with-fb"
                        color="primary"
                        onClick={renderProps.onClick}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faFacebookF} /> Login with
                        Facebook
                      </Button>
                    )}
                  /> */}
                {/* </div> */}
              {/* </FormGroup> */}
            </Form>
          </ModalBody>
          <ModalFooter>
            <FormGroup row>
              <p>Don't have an account? </p>
              <span>&nbsp;&nbsp;</span>
              <a className="forgot-pass-login" onClick={this.changetoLogin}>
                {" "}
                Sign Up Now!
              </a>
            </FormGroup>
            {/* <Button color="primary" onClick={this.props.toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button> */}
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
}

LoginModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func
};

export default LoginModal;
