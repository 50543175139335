import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "./post.css";
class Post extends React.Component {
  getMeta = (url) => {
    var img = new Image();
    // img.addEventListener("load", function(){
    //     // alert( this.naturalWidth +' '+ this.naturalHeight );
    // });
    img.src = url;
  };

  render() {
    let Rating = () => {
      let stars = [];
      if (this.props.postRating === 0) {
        return <span style={{ color: "rgba(0,0,0,0.4)" }}>Not yet rated</span>;
      } else {
        for (let i = 0; i < this.props.postRating; i++) {
          stars.push(
            <FontAwesomeIcon icon={faStar} className="checked fa-star" />
          );
        }
        return stars;
      }
    };

    return (
      <Link
        to={{
          pathname: `/post/${this.props.postId}`,
          state: {
            postUserId: this.props.postUserId,
            postId: this.props.postId,
          },
        }}>
        <Col sm="12" md="6" lg="3">
          <Card sm="12" className="dashboard-card">
            <CardHeader className="card-img-header p-0">
              <div className="card-img">
                <img
                  width="100%"
                  src={this.props.postImageUrl}
                  alt="item img"
                />
                {(window.location.pathname === "/myprofile" ||
                  window.location.pathname === "/myprofile/") && (
                  <>
                    {this.props.postIsActive ? (
                      <span
                        class="badge bg-success"
                        style={{ position: "absolute", top: 10, right: 10 }}>
                        Active
                      </span>
                    ) : (
                      <span
                        style={{ position: "absolute", top: 10, right: 10 }}
                        class="badge bg-warning text-dark">
                        Out of Service
                      </span>
                    )}
                  </>
                )}
              </div>
            </CardHeader>
            <CardBody
              className="text-muted dashboard-card-body"
              style={{ height: "136px" }}>
              <CardTitle className="dashboard-card-title">
                {this.props.postName}
              </CardTitle>
              <CardText className="dashboard-card-text">
                <div
                  className="dash-username"
                  style={{ textTransform: "capitalize", fontWeight: "500" }}>
                  {this.props.postUser}
                  {/* {this.props.postUserId}  */}
                </div>
                <div className="green-txt">
                  <Rating />
                </div>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Link>
    );
  }
}

Post.propTypes = {
  postImageUrl: PropTypes.string,
  postName: PropTypes.string,
  postUser: PropTypes.string,
  postRating: PropTypes.number,
  postId: PropTypes.string,
  postUserId: PropTypes.string,
};

export default Post;
