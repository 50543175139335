import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";

//import NumberFormat from 'react-number-format';

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";

import "./style.css";

class SettingTosModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyPolicy: "",
    };
  }

  componentDidMount() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/settings/privacypolicy",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      let finishedTXT = res.data.content.replace(/\n/g, "<br>");
      vm.setState({ privacyPolicy: finishedTXT });
    });
  }

  // changetoLogin() {
  //   this.props.toggle('signin');
  //   this.props.toggle('login');
  // }

  // changeToVerificationPhone = () => {
  //   this.props.toggle('tosmodal');
  //   this.props.toggle('verificationPhoneModal');
  // }

  render() {
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("settingsppmodal")}
      >
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="tos-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("settingsppmodal")}
          size="lg"
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B" }}
          >
            Privacy Policy
          </ModalHeader>
          <ModalBody style={{ maxHeight: 600, overflowY: "auto" }}>
            <Form>
              <FormGroup style={{ padding: 15 }} row>
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.privacyPolicy }}
                ></div>

                {/* <p >Before things get too heated, let us jump in and say that both sides make valid points. Using real content during design can distract designers and design review teams alike
            away from the design, and insisting on always using publication-ready content can be a real drag on the design process. On the other hand, if you use poorly formatted filler text you may get a
             completely false sense of how your design will interact with real content. We propose a compromise: Only use filler text that has been edited for length and format to match the characteristics of real content 
             as closely as possible, and use real content where possible, and where it doesn’t create too much distraction. Relax and do whatever fits with your design process. Don’t set a lot of restrictive hard-and-fast rules.
              Use filler text where it helps your design process, but use real content if you’ve got it, as long as it doesn’t distract and slow down your design process. Design is an evolutionary process, and filler text is just
               one tool in your progress-pushing arsenal. Use it where it makes sense to use it, and pull it once the natural process indicates that it’s time to roll out a descendant built with real content.C/O.</p>

               <p >Before things get too heated, let us jump in and say that both sides make valid points. Using real content during design can distract designers and design review teams alike
            away from the design, and insisting on always using publication-ready content can be a real drag on the design process. On the other hand, if you use poorly formatted filler text you may get a
             completely false sense of how your design will interact with real content. We propose a compromise: Only use filler text that has been edited for length and format to match the characteristics of real content 
             as closely as possible, and use real content where possible, and where it doesn’t create too much distraction. Relax and do whatever fits with your design process. Don’t set a lot of restrictive hard-and-fast rules.
              Use filler text where it helps your design process, but use real content if you’ve got it, as long as it doesn’t distract and slow down your design process. Design is an evolutionary process, and filler text is just
               one tool in your progress-pushing arsenal. Use it where it makes sense to use it, and pull it once the natural process indicates that it’s time to roll out a descendant built with real content.C/O.</p> */}
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

SettingTosModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default SettingTosModal;
