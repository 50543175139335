import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye,
  faUser,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";

class EditProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      confirmErrorMsg: "",
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
      firstName: "",
      lastName: "",
      email: "",
      cityCode: 0,
      file: "",
      imagePreviewUrl: "",
      uploadedImage: "",
      phone: "",
      initialImage: "",
      isUploadButtonDisabled: false,
      passErrorMsg: "",
      sponsorID: "",
      companyName: "",
      passwordChangeSuccess: "",
      currentPasswordError: "",
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }

    this.getExistingInfo();
  }

  changetoLogin = () => {
    this.props.toggle("signin");
    this.props.toggle("login");
  };

  changeToSignupProcess = () => {
    this.props.toggle("signin");
    this.props.toggle("tosmodal");
  };

  getExistingInfo() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/users/me",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        let cityId;
        if (res.data.city != null) {
          cityId = res.data.city.id;
        } else {
          cityId = 0;
        }
        vm.setState({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          cityCode: cityId,
          phone: res.data.phone,
          initialImage: res.data.profileImage,
          sponsorID: res.data.sponsorID,
          companyName: res.data.companyName,
        });
      })
      .catch(function (res) {
        console.log(res);
      });
  }

  handleUpload = () => {
    let vm = this;
    $.when(vm.uploadImages()).done(function () { });
  };

  uploadImages = () => {
    const fd = new FormData();
    fd.append("file", this.state.file);
    fd.append("public", true);
    let data = {
      File: this.state.file,
      Public: true,
    };

    const vm = this;
    $.ajax({
      method: "POST",
      //dataType: 'json',
      processData: false,
      //traditional: true,
      contentType: false,
      data: fd,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/uploads/uploadimage",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    }).then(function (res) {
      vm.setState({
        uploadedImage: res.data.filePath,
        imagePreviewUrl: "",
      });
      vm.onSaveChanges();
    });
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    if (file.size < 6000000) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select image size less than 10 mb!");
    }
  }

  handleChange = ({ target }) => {
    let name = target.name;
    this.setState({ [name]: target.value });
  };
  ////////////////////////////////////////////////////////////////////////////////
  saveChanges = () => {
    let data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      profileImage: this.state.uploadedImage,
      cityId: parseInt(this.state.cityCode),
      sponsorID: this.state.sponsorID,
      companyName: this.state.companyName,
    };

    const vm = this;
    $.ajax({
      method: "PUT",
      dataType: "json",
      processData: false,
      traditional: true,
      contentType: "application/json",
      data: JSON.stringify(data),
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/users/me",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    }).then(function () {
      // vm.props.toggle("editprofilemodal");
      window.location.reload();
    });
  };

  changePassword = () => {
    console.log("******** before password change data ************");
    const vm = this;
    let data = {
      currentPassword: this.state.currentPassword,
      password: this.state.password,
      passwordConfirmation: this.state.passwordConfirmation,
    };
    $.ajax({
      method: "POST",
      dataType: "json",
      traditional: true,
      contentType: "application/json",
      data: JSON.stringify(data),
      xhrFields: {
        withCredentials: false,
      },
      url: process.env.REACT_APP_API + "/account/changepassword",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then(function (res) {
        console.log("******** get password change data ************");
        console.log(res.code);

        vm.setState({
          passwordChangeSuccess: res.code,
        });
        if (res.code === "SUCCESS") {
          vm.props.toggle("editprofilemodal");
        }
      })
      .catch(function (res) {
        console.log("ERROR");
        console.log(res);
        if (res.responseJSON.code === "VALIDATION_ERROR") {
          vm.setState({
            currentPasswordError: "Incorrect current Password",
          });
        }
      });
  };

  handlePasswordChange(e) {
    this.setState({ password: e.target.value }, () => {
      if (this.state.password !== this.state.passwordConfirmation) {
        this.setState({ confirmErrorMsg: "The 2 Passwords dont match" });
      } else {
        this.setState({ confirmErrorMsg: "" });
      }
    });
  }

  handleConfirmPassword(e) {
    // console.log(e.target.value);
    this.setState({ passwordConfirmation: e.target.value }, () => {
      if (this.state.password !== this.state.passwordConfirmation) {
        this.setState({ confirmErrorMsg: "The 2 Passwords dont match" });
      } else {
        this.setState({ confirmErrorMsg: "" });
      }
    });
  }

  regexCheck = () => {
    const vm = this;
    const regEx =
      /(?=^.{7,15}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/;
    if (regEx.test(this.state.password)) {
      console.log("True");
    } else {
      console.log("FAIL");
      vm.setState({
        passErrorMsg:
          "Password needs: 1 uppercase letter,1 lowercase letter, 1 special character, 1 digit and between 7-10 characters",
      });
    }
  };

  onSaveChanges = () => {
    if (this.state.imagePreviewUrl.length > 0) {
      this.uploadImages();
    } else {
      if (this.state.password === "") {
        console.log("Password didtn change");
        this.saveChanges();
        this.props.toggle("editprofilemodal");
      } else {
        this.regexCheck();
        this.changePassword();
        this.saveChanges();

        //this.props.toggle("editprofilemodal");
      }
    }
  };

  passwordChangeConfirm = () => {
    if (this.state.passwordChangeSuccess === "SUCCESS") {
    }
  };

  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (
        <img className="profile-pic-container edit-pf" src={imagePreviewUrl} />
      );
    } else {
      if (this.state.initialImage) {
        $imagePreview = (
          <img
            className="profile-pic-container edit-pf"
            src={this.state.initialImage}
          />
        );
      } else {
        $imagePreview = (
          <img
            className="profile-pic-container edit-pf"
            src="https://s3.ca-central-1.amazonaws.com/vog-project-common/profile.png"
          />
        );
      }
    }
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("editprofilemodal")}>
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="edit-profile-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("editprofilemodal")}>
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B" }}>
            Edit Profile
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <div className="profile-pic-container">
                  {/* <img className="edit-pf" src={$imagePreviewUrl} /> */}
                  <div className="edit-pf">{$imagePreview}</div>
                  <input
                    ref={(fileInput) => (this.fileInput = fileInput)}
                    style={{ display: "none" }}
                    className="upload"
                    type="file"
                    accept="image/*"
                    onChange={(e) => this._handleImageChange(e)}
                  />
                  <p
                    className="change-photo-text"
                    style={{
                      fontWeight: 500,
                      marginTop: 10,
                    }}>
                    <button
                      type="button"
                      onClick={() => this.fileInput.click()}
                      style={{
                        backgroundColor: "#8AC53F",
                        color: "white",
                        border: "none",
                        padding: "10px",
                      }}>
                      Change Image
                    </button>
                    <br />
                    {/* <button
                      style={{
                        backgroundColor: "#8AC53F",
                        color: "white",
                        border: "none",
                        marginTop: "10px",
                        padding: "10px"
                      }}
                      type="button"
                      onClick={this.uploadImages}
                    >
                      Upload Image
                    </button> */}
                  </p>
                </div>
              </FormGroup>

              <FormGroup row>
                <Label for="firstname" sm={12} size="lg">
                  First Name:
                </Label>

                <Col sm={12}>
                  <Input
                    className="email-input-modal"
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="lastname" sm={12} size="lg">
                  Last Name:
                </Label>
                <Col sm={12}>
                  <Input
                    className="email-input-modal"
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="email" sm={12} size="lg">
                  Email:
                </Label>
                <Col sm={12}>
                  <Input
                    className="email-input-modal"
                    type="email"
                    value={this.state.email}
                    name="email"
                    id="email"
                    onChange={this.handleChange}
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="phone" sm={12} size="lg">
                  Phone:
                </Label>
                <Col sm={12}>
                  <Input
                    className="email-input-modal"
                    type="text"
                    name="phone"
                    id="phone"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="city" sm={12} size="lg">
                  City
                </Label>
                <Col sm={12}>
                  {/* <Input
                    type="select"
                    value={this.state.cityCode}
                    onChange={this.handleChange}
                    name="city"
                    id="city"
                    // onClick={e => this.selectChangeid(e)}
                  >
                    <option value="1">Calgary</option>
                    <option value="2">Edmonton</option>
                  </Input> */}
                  <select
                    defaultValue={this.state.cityCode}
                    onChange={this.handleChange}
                    className="form-control"
                    name="cityCode"
                    id="cityCode"
                    style={{
                      fontSize: "1.25rem",
                    }}>
                    <option hidden value="0">
                      {" "}
                      Select a location
                    </option>
                    <option value="1"> Calgary</option>
                    <option value="2"> Edmonton</option>
                  </select>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="lastname" sm={12} size="lg">
                  My Sponsor Code:
                </Label>
                <Col sm={12}>
                  <Input
                    className="email-input-modal"
                    type="text"
                    name="sponsorID"
                    id="sponsorID"
                    value={this.state.sponsorID}
                    onChange={this.handleChange}
                    bsSize="lg"
                    disabled
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="lastname" sm={12} size="lg">
                  Company Name:
                </Label>
                <Col sm={12}>
                  <Input
                    className="email-input-modal"
                    type="text"
                    name="companyName"
                    id="companyName"
                    value={this.state.companyName}
                    onChange={this.handleChange}
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>
              <div
                style={{
                  fontWeight: "700",
                }}>
                (Required only if changing password)
              </div>
              <ReactTooltip type="success" />
              <FormGroup row>
                <Label for="currentPassword" sm={12} size="lg">
                  Current Password
                </Label>
                <Col sm={12}>
                  <Input
                    className="password-input"
                    type={this.state.hidden ? "password" : "text"}
                    value={this.state.currentPassword}
                    onChange={this.handleChange}
                    name="currentPassword"
                    id="currentPassword"
                    placeholder="Type current password"
                    bsSize="lg"
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hidden ? faEyeSlash : faEye}
                    onClick={this.toggleShow}
                    size="lg"
                    className="eye-icon-modal"
                  />
                </Col>
              </FormGroup>

              <FormGroup
                row
                data-tip="Needs to be at least  1 uppercase letter,1 lowercase letter, 1 symbol, 1 digit and between 7-10 characters">
                <Label for="createpassword" sm={12} size="lg">
                  Change Password to:
                </Label>
                <Col sm={12}>
                  <Input
                    className="password-input"
                    type={this.state.hidden ? "password" : "text"}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    name="password"
                    id="password"
                    placeholder="New Password (minimum of 7 characters)"
                    bsSize="lg"
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hidden ? faEyeSlash : faEye}
                    onClick={this.toggleShow}
                    size="lg"
                    className="eye-icon-modal"
                  />
                </Col>
              </FormGroup>

              <FormGroup
                row
                data-tip="Needs to be at least  1 uppercase letter,1 lowercase letter, 1 symbol, 1 digit and between 7-10 characters">
                <Label for="createpassword-confirm" sm={12} size="lg">
                  Confirm new Password:
                </Label>
                <Col sm={12}>
                  <Input
                    className="password-input"
                    type={this.state.hidden ? "password" : "text"}
                    value={this.state.passwordConfirmation}
                    onChange={this.handleConfirmPassword}
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    placeholder="Confirm Password (minimum of 7 characters) "
                    bsSize="lg"
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hidden ? faEyeSlash : faEye}
                    onClick={this.toggleShow}
                    size="lg"
                    className="eye-icon-modal"
                  />
                </Col>
              </FormGroup>
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "block",
                  color: "red",
                  marginBottom: 20,
                }}>
                {this.state.confirmErrorMsg}{" "}
              </span>
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "block",
                  color: "red",
                  marginBottom: 20,
                }}>
                {this.state.passErrorMsg}{" "}
              </span>
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "block",
                  color: "red",
                  marginBottom: 20,
                }}>
                {this.state.currentPasswordError}{" "}
              </span>
              <Col sm={12} className="editprofile-buttons">
                <Button
                  className="cancel-btn cancel-editp "
                  onClick={() => this.props.toggle("editprofilemodal")}
                  size="lg">
                  Cancel
                </Button>
                <Button
                  className="save-btn"
                  onClick={this.onSaveChanges}
                  size="lg">
                  Save Changes
                </Button>
              </Col>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

EditProfileModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default EditProfileModal;
